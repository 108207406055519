import { Action } from '@ngrx/store';
import { employee } from './employee';

export enum EmployeeActionTypes {
  LoadEmployees = '[Employee] Load Employees',
  LoadEmployeesSuccess = '[Employee] Load Employees Success',
  LoadEmployeesFailure = '[Employee] Load Employees Failure',
  DeactivateEmployee='[Employee] Deactivate Employee',
  DeleteEmployee='[Employee] delete Employee'  
}

export class LoadEmployees implements Action {
  readonly type = EmployeeActionTypes.LoadEmployees;
}

export class LoadEmployeesSuccess implements Action {
  readonly type = EmployeeActionTypes.LoadEmployeesSuccess;
  constructor(public payload: { data: employee[] }) { }
}

export class LoadEmployeesFailure implements Action {
  readonly type = EmployeeActionTypes.LoadEmployeesFailure;
  constructor(public payload: { error: any }) { }
}

export class DeactivateEmployee implements Action{
  readonly type = EmployeeActionTypes.DeactivateEmployee;
  constructor(public payload:{data:employee[]}){}
}
export class DeleteEmployee implements Action{  
  readonly type=EmployeeActionTypes.DeleteEmployee;
  constructor(public payload:{id:number}){}
}
export type EmployeeActions = LoadEmployees | LoadEmployeesSuccess | LoadEmployeesFailure | DeactivateEmployee | DeleteEmployee;

