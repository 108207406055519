<mat-dialog-content class="mat-typography">
    <h2 mat-dialog-title style="text-align: center;">Track Order</h2>
    <span style="text-align:right">**You don't have access to take any action on the tracking page.</span>
    <!-- <button mat-raised-button color="accent" class="cancelShipment" (click)="cancelShipment(orderID)">Cancel</button> -->
    <div class="content inner_page">
        <div id="main_cont" class="container">
            <div class="">
                <div class="" *ngIf="TrackData.Trackpayload.tracking_data.track_status; else nostatus">
                    <div class="topSection">
                        <div class="hp_cards topsectionFlex" style="margin-top:20px;">
                            <div class="hp_cards_info">
                                <div class="clearfix  delievery_status">
                                    <div class="pull-left status_cont">
                                        <span id="shipment_status_label">Status:</span><br/>
                                        <strong id="shipment_status"><h2>{{shipment_track.current_status}}</h2></strong>
                                    </div>
                                    <div class="pull-right share_icon_wrap">
                                        <div class="pull-right">
                                            <div class="mytooltip">
                                                <!-- <i style="font-size: 24px;cursor: pointer;" onmouseout="outFunc()" onclick="copy_to_clipboard()" class="fa fa-copy">	<span class="tooltiptext" id="myTooltip">Copy URL</span></i> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="topsectionFlex">
                            <!-- order details -->
                            <div class="hp_cards information_block" style="margin-top:20px;">
                                <div class="hp_cards_info" style="margin-top:20px;">
                                    <h5><i class="order_detail_icon"></i>Order Details</h5>
                                    <div class="clearfix mb-20">
                                        <span class="pull-left"><b>Order ID</b> </span>&nbsp;&nbsp;<span class="pull-right  right_info">{{orderID}}</span>
                                    </div>
                                    <div class="clearfix mb-20">
                                        <span class="pull-left"><b>Order Placed On</b></span>&nbsp;&nbsp; <span class="pull-right  right_info">{{orderDate}}</span>
                                    </div>
                                </div>
                            </div>

                            <!-- order details end-->
                        </div>
                    </div>
                    <hr>
                    <div class="track_activity">
                        <div class="courier_info">
                            <div class="pull-left" id="courierName">
                                <span><b>{{courier_name}}</b></span>
                                <ul>
                                    <li *ngFor="let activity of shipment_track_activities index by date">
                                        <span><b> </b>  <span>{{activity.date}}</span></span><br/>
                                        <i class="circle_icon"></i>
                                    </li>
                                </ul>
                            </div>
                            <div class="pull-right">
                                <span><b>Tracking ID</b> </span>
                                <span class="tracking_id">{{trackingID}}</span>
                                <div class="delievery_info ">
                                    <div class="delievery_list_wrap clearfix">
                                        <ul>
                                            <li *ngFor="let activity of shipment_track_activities index by date">
                                                <!-- <span><b>Date : </b>  <span>{{activity.date}}</span></span><br/> -->
                                                <span><b>Activity : </b>  <span>{{activity.activity}}</span></span><br/>
                                                <span><b>Location : </b>  <span>{{activity.location}}</span></span>
                                                <i class="circle_icon"></i>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <!-- tracking card end-->
                <ng-template #nostatus>
                    <div class="hp_cards topsectionFlex" style="margin-top:20px;">
                        <div class="hp_cards_info">
                            <div class="clearfix  delievery_status" style="display: flex;">
                                <div class="pull-left status_cont">
                                    <span id="shipment_status_label">Status:</span><br/>
                                    <strong id="shipment_status"><h2>NOT GENERATED </h2></strong>
                                </div>
                                <div class="pull-right share_icon_wrap">
                                    <div class="pull-right">
                                        <div class="mytooltip">
                                            <h4>{{TrackData.Trackpayload.tracking_data.error}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</mat-dialog-content>