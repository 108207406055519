import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TooltipComponent } from '../tooltip/tooltip.component';
import {TooltipPosition} from '@angular/material/tooltip';
import { AgGridAngular } from "ag-grid-angular";
import { AngularFrameworkComponentWrapper } from 'ag-grid-angular';
import { permission } from './permission';
import { query } from '@angular/animations';
import { select, Store } from '@ngrx/store';
import * as fromPermission from './permission.selectors';
import * as permissionAction from './permissoin.actions'
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PermissoinService } from './permissoin.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { RollSetup } from '../../shared/userRolls';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.css'],
    animations: [
      trigger('widthGrow', [
          state('closed', style({
            height:  0,
          })),
          state('open', style({
            height: '80px'
          })),
          transition('* => *', animate(80))
      ]),
  ]
})
export class PermissionComponent implements OnInit {

  HeaderTitle:string='Cosmic 2.0';
  pageTtitle:string='Permission';
  paginationPageSize:number=19;
  sideBar:boolean = false;
  rowData!:permission[]
  addPermissionForm!:FormGroup
  PermissionSectionSwitch:boolean=false;
  state = "closed";
  waitingImage:boolean=false;
  permissionCategoryList=['User','Admin','Super User','Agent','Order','Password','Export','Products','SMS','Ship','Logs','Courier','Report','Communication']
  filteredCategory!:Observable<string[]>
  defaultColDef = {
    editable:true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:100,
    sideBar:true
  };
  
  columnDefs = [
    {headerName: 'Id', field: 'id',hide:false},
    {headerName: 'Title', field: 'title',hide:false},
    {headerName: 'Status', field: 'status',hide:false},
    {headerName: 'Category', field: 'category',hide:false}
  ]


  constructor(private store:Store,private _permissionService:PermissoinService) { 
    this.addPermissionForm=new FormGroup({
      PermissionTitle: new FormControl('',[Validators.required]),
      PermissionCat: new FormControl('',[Validators.required])
    })
    this.filteredCategory=this.addPermissionForm.controls.PermissionCat.valueChanges.pipe(
      startWith(null),
      map((category:string | null)=>(category ? this._filter(category):this.permissionCategoryList.slice()))
    )
  }
  userRolls:RollSetup[]=[];
  ngOnInit(): void {
    this.store.dispatch(new permissionAction.LoadPermissoins)
    this.store.pipe(select(fromPermission.getPermission)).subscribe(permission=>this.rowData=permission)
    // const local1:any=localStorage.getItem('user');
    // let result:any
    // this.loggenInUserId=JSON.parse(local1);
    // this._permissionService.getRollSetup().subscribe(payload=>{ 
    //   if(payload){  
    //     payload.filter((el)=>{
    //       if(this.loggenInUserId.id==el.user){
    //         this.userPermission=el.permission;
    //       }
    //     })         
    //   }
    // })
    
   
  }
  
  private _filter(value: string) {    
      const filterValue = value.toLowerCase();
      return this.permissionCategoryList.filter(catergory => catergory.toLowerCase().includes(filterValue));
  }

  @ViewChild('permissionList')
  agGrid!:AgGridAngular

  @ViewChild('filterTextBox',{read:ElementRef})
  filterBox!:ElementRef

  onFilterTextBoxChanged(){
    //console.log("df",this.filterBox.nativeElement.value);
    this.agGrid.api.setQuickFilter(this.filterBox.nativeElement.value)
  }  
 
  createPermission(){
    (this.state == "closed") ? this.state = "open" : this.state = "closed"; 
    this.PermissionSectionSwitch=!this.PermissionSectionSwitch    
  }
  submitPermissio(formDirective:FormGroupDirective){
    this.waitingImage=true;
    if(this.addPermissionForm.valid){
      console.log(this.addPermissionForm)
      this._permissionService.createPermission(this.addPermissionForm.value).subscribe((payload)=>{
        if(payload){
          formDirective.resetForm();
          this.refreshUser()
        }
      })
    }
  }
  refreshUser(){
    this.store.dispatch(new permissionAction.LoadPermissoins)
    this.store.pipe(select(fromPermission.getPermission)).subscribe(permission=>this.rowData=permission)
  }
  onResize(event:any){
    console.log("onresize:",event)
  }

}
