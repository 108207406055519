<section class="quickViewSection">
    <ag-grid-angular 
              id='SearchOrderList'
              style="width: 100%; height: 300px;overflow: scroll;"
              class="ag-theme-balham"
              [sideBar]="sideBar"
              [rowData]="rowData" 
              [defaultColDef]="defaultColDef"
              [columnDefs]="columnDefs"
              [animateRows]='false'
              [pagination]='true'
              [paginationPageSize]="paginationPageSize"
              (onColumnResized)="onResize($event)"
              (rowSelected)="onRowSelected($event)"
              [rowSelection]="rowSelection"     
              [rowMultiSelectWithClick]="true"       
              [rowHeight]="rowHeight"       
              >
          </ag-grid-angular>
</section>