<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-1">
                <div class="col-sm-6">
                    <h2 class="m-0">{{pageTitle | uppercase}}</h2>
                </div><!-- /.col -->          
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right buttonList">               
                        <li class="" (click)="AddCourier()"><button mat-mini-fab color="accent"><i class="fas fa-plus"></i></button></li>
                        <li class="" (click)="LoadCourier()"><button mat-mini-fab color="accent"><i class="fas fa-globe-asia"></i></button></li>              
                    </ol>            
                </div>
            </div>
        </div>
    </div>
    <section [@widthGrow]="state" style='height: 0;' *ngIf='CourierSectionSwitch'>       
        <form autocomplete="off" class='addProduct' (ngSubmit)="submitCourier(formDirective)" [formGroup]="addCourierForm" #formDirective="ngForm">
            <div>
                <mat-form-field appearance="outline">
                    <mat-label>Courier Name</mat-label>
                    <input formControlName='CourierName' matInput placeholder="Enter Courier Name" required>
                </mat-form-field>                                           
                <mat-dialog-actions>
                    <button mat-raised-button color="accent" type="reset">Reset</button>
                    <button mat-raised-button color="primary" type="submit" [disabled]= "!addCourierForm.valid">Create</button>
                    <a (click)='AddCourier()'>Close</a>
                </mat-dialog-actions>
            </div>

        </form>        
    </section>
    <section class="content" id="renderDataSection">
        <div class="container-fluid">              
            <div class="row" >                            
                <ag-grid-angular 
                    #CourierList
                    style="width: 1470px; height: 300px;"
                    class="ag-theme-balham"                    
                    [rowData]="rowData" 
                    [defaultColDef]="defaultColDef"
                    [columnDefs]="columnDefs"
                    [animateRows]='true'
                    [pagination]='true'
                    [paginationPageSize]="paginationPageSize"
                    (onColumnResized)="onResize($event)"
                    >
                </ag-grid-angular>
            </div>          
        </div>
      </section>
    <section class="content">
        <div class="container-fluid">
          <!-- Info boxes -->    
            <div class="row" >
                <div cdkDropList cdkDropListOrientation="horizontal" class="example-list" (cdkDropListDropped)="drop($event)">
                    <div class="example-box" *ngFor="let timePeriod of timePeriods" cdkDrag>                        
                        <img *ngIf="timePeriod.name=='Shiplyte'" src="../../../assets/courier logo/shyplite_logo.png" class="courierLogo">
                        <img *ngIf="timePeriod.name=='Picker'" src="../../../assets/courier logo/pickrr_logo.jpg" class="courierLogo">
                        <img *ngIf="timePeriod.name=='Ecom'" src="../../../assets/courier logo/ecom_logo.png" class="courierLogo">
                        <img *ngIf="timePeriod.name=='Bluedart'" src="../../../assets/courier logo/bluedart_logo.png" class="courierLogo">
                        <img *ngIf="timePeriod.name=='Shiprocket'" src="../../../assets/courier logo/shiprocket_logo.png" class="courierLogo">
                        <p>{{timePeriod.name}}</p>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
</div>


