import { createFeatureSelector, createSelector } from '@ngrx/store';
import {State  } from "./shift.reducer";

const getShiftFeatureState = createFeatureSelector<State>('shift');

export const getShift= createSelector(
    getShiftFeatureState,
    state => state.shift
)

export const getError = createSelector(
    getShiftFeatureState,
    state => state.error
)
export const createShift= createSelector(
    getShiftFeatureState,
    state=>state.shift
)
