import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';

import { from } from 'rxjs';
import { environment } from '../../environments/environment';
import * as fromUser from '../user.reducer';
import * as fromEmployee from '../dashboard/employee/employee.reducer';
import * as fromOrder from '../dashboard/orders/order.reducer';
import * as fromProduct from '../dashboard/product/product.reducer';
import * as fromCourse from '../dashboard/course/course.reducer'
import * as fromOrderStatus from '../dashboard/order-status/order-status.reducer'
import * as fromPermission from '../dashboard/permissoin/permission.reducer'
import * as fromReport from '../dashboard/report/report.reducer'
import * as fromDomain from '../dashboard/domain/domain.reducer'
import * as fromLoginAudit from '../dashboard/login-audit/login-audit.reducer'
import * as fromdashboardPage from '../dashboard/dashboard-page/dashboard-page.reducer'
import * as fromRoleSetupPermission from '../dashboard/permission-roll-setup/permission-roll-setup.reducer'
import * as fromLeastConfirmAgent from '../dashboard/least-order-confirmation-agent/least-order-confirmation-agent.reducer'
import * as fromShift from '../dashboard/shift/shift.reducer'
import * as fromOrderActionButton from '../dashboard/order-action-button/order-action-button.reducer';



export interface State {
  [fromUser.userFeatureKey]: fromUser.State;
  [fromEmployee.employeeFeatureKey]:fromEmployee.State;
  [fromOrder.orderFeatureKey]:fromOrder.State;
  [fromProduct.productFeatureKey]:fromProduct.State;
  [fromCourse.courseFeatureKey]:fromCourse.State;
  [fromOrderStatus.orderStatusFeatureKey]:fromOrderStatus.State;
  [fromPermission.permissionFeatureKey]:fromPermission.State;
  [fromReport.reportFeatureKey]:fromReport.State;
  [fromDomain.domainFeatureKey]:fromDomain.State;
  [fromLoginAudit.loginAuditFeatureKey]:fromLoginAudit.State;
  [fromdashboardPage.dashboardPageFeatureKey]:fromdashboardPage.State;
  [fromRoleSetupPermission.permissionRollSetupFeatureKey]:fromRoleSetupPermission.State;
  [fromLeastConfirmAgent.leastOrderConfirmationAgentFeatureKey]:fromLeastConfirmAgent.State;
  [fromShift.shiftFeatureKey]:fromShift.State;
  [fromOrderActionButton.orderActionButtonFeatureKey]:fromOrderActionButton.State;
}

export const reducers: ActionReducerMap<any,any> = {
  [fromUser.userFeatureKey]: fromUser.reducer,
  [fromEmployee.employeeFeatureKey]:fromEmployee.reducer,
  [fromOrder.orderFeatureKey]:fromOrder.reducer,
  [fromProduct.productFeatureKey]:fromProduct.reducer,
  [fromCourse.courseFeatureKey]:fromCourse.reducer,
  [fromOrderStatus.orderStatusFeatureKey]:fromOrderStatus.reducer,
  [fromPermission.permissionFeatureKey]:fromPermission.reducer,
  [fromReport.reportFeatureKey]:fromReport.reducer,
  [fromDomain.domainFeatureKey]:fromDomain.reducer,
  [fromLoginAudit.loginAuditFeatureKey]:fromLoginAudit.reducer,
  [fromdashboardPage.dashboardPageFeatureKey]:fromdashboardPage.reducer,
  [fromRoleSetupPermission.permissionRollSetupFeatureKey]:fromRoleSetupPermission.reducer,
  [fromLeastConfirmAgent.leastOrderConfirmationAgentFeatureKey]:fromLeastConfirmAgent.reducer,
  [fromShift.shiftFeatureKey]:fromShift.reducer,
  [fromOrderActionButton.orderActionButtonFeatureKey]:fromOrderActionButton.reducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
