<form class="editShiftForm" (ngSubmit)="updateShiftAction(formDirective)" [formGroup]="editShiftForm" #formDirective="ngForm" autocomplete="off">
    <mat-dialog-content class="mat-typography">
        <h5 class="successHeader" *ngIf='successHeader' [@fade]="'out'">Shift Updated Successfully</h5>
        <h2 mat-dialog-title style="text-align: center;">EDIT SHIFT: {{shiftID}}</h2>
        <div class="example-container inputBlock">
            <mat-form-field class="example-full-width">                            
                <mat-label>Start Time &nbsp;&nbsp;</mat-label>
                <input  matInput
                    formControlName="startDate"
                    type="time"
                    id="startDate"
                    name="startDate"
                    class='typeSomethingFilter'
                    value="startdate | date : 'HH:mm'"
                    (change)="calculateHours($event)">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>End Time &nbsp;&nbsp;</mat-label>
                <input 
                    matInput 
                    formControlName="endDate"
                    type="time"
                    id="endDate"
                    name="endDate"
                    class='typeSomethingFilter'
                    (change)="calculateHours($event)">
            </mat-form-field>
        </div>
        <div class="example-container duration">            
            Duration: &nbsp;&nbsp; <strong style="color: #ff4081;font-size: 15px;">{{duration}}</strong> &nbsp;&nbsp;
        </div>
        <mat-dialog-actions align="end">
            <button mat-raised-button mat-dialog-close>Cancel</button>
            <button mat-raised-button color="accent" type="reset">Reset</button>
            <button mat-raised-button color="primary" type="submit" [disabled]= "!editShiftForm.valid || errorExist">Assign</button>
        </mat-dialog-actions>
        <div *ngIf="errorExist" class="timingError">Wrong Timings</div>
  </mat-dialog-content>
</form>
<style>
    mat-form-field{
        width: 214px !important;
    }
    #renderDataSection{
        margin-top: 17px;
    }
    .timingError{
        color: red;    
        text-align: center;    
        margin-top: 29px;
    }

</style>
