import { ElementSchemaRegistry } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import Swal from "sweetalert2";
import { Store,select } from "@ngrx/store";
import { UserService } from "../users/user.service";
import { employee } from '../employee/employee';
import * as EmployeeActions from "../employee/employee.actions";
import * as fromEmployee from "../employee/employee.selectors";

@Component({
  selector: 'app-employee-action-buttons',
  templateUrl: './employee-action-buttons.component.html',
  styleUrls: ['./employee-action-buttons.component.css']
})
export class EmployeeActionBUttonsComponent implements OnInit {
  deactivateToggle:boolean=true;
  paramsData!:employee;
  completeParamView:any;
  constructor(private _userService:UserService,private store:Store) { }
  agInit(params:any){
    this.paramsData=params.data;
    this.completeParamView=params;
  }
  ngOnInit(): void {   
    if(this.paramsData.isActive == false){
      this.deactivateToggle=false
    }
  }
  EditUser(event:Event){
   
  }
  DeactivateEmployeeToggle(){
    //console.log(this.paramsData,"sd",this.completeParamView.data);
    this._userService.deactivateEmployee(this.completeParamView.data.id).subscribe((payload)=>{console.log(payload)})
    //this.store.dispatch(new EmployeeActions.LoadEmployees);
  }
  DeleteUser(){
    var id= this.paramsData.id;
    this._userService.deleteEmployee(this.paramsData.id).subscribe(()=>{})
    this.store.dispatch(new EmployeeActions.DeleteEmployee({id}))
  }

}
