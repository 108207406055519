import { Action } from '@ngrx/store';
import { product } from './product';

export enum ProductActionTypes {
  LoadProducts = '[Product] Load Products',
  LoadProductsSuccess = '[Product] Load Products Success',
  LoadProductsFailure = '[Product] Load Products Failure',
}

export class LoadProducts implements Action {
  readonly type = ProductActionTypes.LoadProducts;
}

export class LoadProductsSuccess implements Action {
  readonly type = ProductActionTypes.LoadProductsSuccess;
  constructor(public payload: { data: product[] }) { }
}

export class LoadProductsFailure implements Action {
  readonly type = ProductActionTypes.LoadProductsFailure;
  constructor(public payload: { error: any }) { }
}

export type ProductActions = LoadProducts | LoadProductsSuccess | LoadProductsFailure;

