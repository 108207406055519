<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-1">
          <div class="col-sm-6">
            <h2 class="m-0">{{pageTtitle | uppercase}}</h2>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right buttonList">                      
                  <!-- <li class="" (click)="checkTestPhp()"><button mat-mini-fab color="accent"><i class="fas fa-filter"></i></button></li>               -->              
                <li class=""  *appHideForRoles="['35']"><button mat-mini-fab color="accent"><i class="fas fa-filter"></i></button></li>
                <li class="" (click)='AddStatus()'><button mat-mini-fab color="accent"><i class="fas fa-plus"></i></button></li>
                <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-globe-asia"></i></button></li>              
                <li class="" ><button mat-mini-fab color="accent" type="reset"> <i class="fas fa-sync-alt" aria-hidden="true"></i></button></li>              
                <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-filter"></i></button></li>              
            </ol>
          </div>
          <!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
    
    <!-- Main content -->
    <section class="content" id="renderDataSection">
        <div class="container-fluid">
        <!-- Info boxes -->    
        <div class="row createOrderStatus">
            <section [@widthGrow]="state" style='height: 0;margin: 0 auto;' *ngIf='CreateOrderSectionSwitch'>       
                <form autocomplete="off" class='addProduct' (ngSubmit)="submitOrderStatus(formDirective)" [formGroup]="addOrderStatus" #formDirective="ngForm">
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Title</mat-label>
                            <input formControlName='StatusName' matInput placeholder="Enter Title" required>
                        </mat-form-field>                             
                        <mat-form-field appearance="outline">
                            <mat-label>Category</mat-label>
                            <input type="text"
                                   placeholder="Pick one"
                                   aria-label="Choose Category"
                                   matInput
                                   formControlName="StatusCategory"
                                   [matAutocomplete]="StatusCategory" required>
                            <mat-autocomplete #StatusCategory="matAutocomplete">
                              <mat-option *ngFor="let category of StatusCategoryList" [value]="category">
                                {{category}}
                              </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-dialog-actions>
                            <button mat-raised-button color="accent" type="reset">Reset</button>
                            <button mat-raised-button color="primary" type="submit" [disabled]= "!addOrderStatus.valid">Create</button>
                            <a (click)='AddStatus()'>Close</a>
                        </mat-dialog-actions>
                    </div>                
                </form>        
            </section>   
        </div>
            <div class="ag-header-container" style='text-align: right;'>
                <input type="text" #filterTextBox placeholder="Type Something..." (input)='onFilterTextBoxChanged()' id='typeSomethingFilter'/>
            </div>    
            <div class="row" >
                <ag-grid-angular 
                    #permissionList
                    style="width: 1470px; height: 500px;"
                    class="ag-theme-balham"
                    [sideBar]="sideBar"
                    [rowData]="statusList" 
                    [defaultColDef]="defaultColDef"
                    [columnDefs]="columnDefs"
                    [animateRows]='true'
                    [pagination]='true'
                    [paginationPageSize]="paginationPageSize"
                    (onColumnResized)="onResize($event)"
                    >
                </ag-grid-angular>            
            </div>
        </div>
    </section>
</div>
