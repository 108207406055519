import { Action } from '@ngrx/store';
import { dashboardPage } from './dashboardPage';

export enum DashboardPageActionTypes {
  LoadDashboardPages = '[DashboardPage] Load DashboardPages',
  LoadDashboardPagesSuccess = '[DashboardPage] Load DashboardPages Success',
  LoadDashboardPagesFailure = '[DashboardPage] Load DashboardPages Failure',
}

export class LoadDashboardPages implements Action {
  readonly type = DashboardPageActionTypes.LoadDashboardPages;
}

export class LoadDashboardPagesSuccess implements Action {
  readonly type = DashboardPageActionTypes.LoadDashboardPagesSuccess;
  constructor(public payload: { data: dashboardPage[] }) { }
}

export class LoadDashboardPagesFailure implements Action {
  readonly type = DashboardPageActionTypes.LoadDashboardPagesFailure;
  constructor(public payload: { error: any }) { }
}

export type DashboardPageActions = LoadDashboardPages | LoadDashboardPagesSuccess | LoadDashboardPagesFailure;

