import { Action } from '@ngrx/store';
import { employee } from './dashboard/employee/employee';
import { IUser } from './user';

export enum UserActionTypes {
  LoadUsers = '[User] Load Users',
  LoadUsersSuccess = '[User] Load Users Success',
  LoadUsersFailure = '[User] Load Users Failure',
  UserDisable = '[User] Apply Disable changes',
  createUser='[User] Create New Employee',
  deleteUser='[User] delete user',
  LoginSetup='[User login setup of pending users',
  EditUserPermission='[User] Edit user Permission'
}

export class LoadUsers implements Action {
  readonly type = UserActionTypes.LoadUsers;
}

export class LoadUsersSuccess implements Action {
  readonly type = UserActionTypes.LoadUsersSuccess;
  constructor(public payload: { data: IUser[] }) { }
}

export class LoadUsersFailure implements Action {
  readonly type = UserActionTypes.LoadUsersFailure;
  constructor(public payload: { error: string }) { }
}

export class UserDisable implements Action{
  readonly type= UserActionTypes.UserDisable;
  constructor(public payload:{data:IUser[]}){}
}

export class createUser implements Action{
  readonly type= UserActionTypes.createUser;
  constructor(public payload:{data:any}){}
}

export class deleteUser implements Action{
  readonly type=UserActionTypes.deleteUser;
  constructor(public payload:{id:number}){}
}

export class loginSetup implements Action{
  readonly type=UserActionTypes.LoginSetup;
  constructor(public payload:{id:number}){}
}

export class EditUserPermission implements Action{
  readonly type=UserActionTypes.EditUserPermission;
}

export type UserActions = LoadUsers | LoadUsersSuccess | LoadUsersFailure | UserDisable | createUser| deleteUser | loginSetup | EditUserPermission;

