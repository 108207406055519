import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { StoreDevtoolsOptions } from '@ngrx/store-devtools';
import { Observable } from 'rxjs';
import { routes } from '../app-routing.module';
import { PermissoinService } from '../dashboard/permissoin/permissoin.service';
import { HideForRolesDirective } from '../shared/directives/hide-for-roles.directive';
import * as roleSetupAction from '../dashboard/permission-roll-setup/permission-roll-setup.actions'
import * as fromRoleSetup from '../dashboard/permission-roll-setup/permission-roll-setup.selectors'

@Injectable({
  providedIn: 'root'
})
export class PermissionGaurdGuard implements CanActivate {
  loggenInUserId:any
  userPermission:Array<any>=[];
  roleMatchs:any
  allowed:boolean=false;
  constructor(private _router:Router, private store:Store){
    
    console.log("1")
    const local1:any=localStorage.getItem('user');
    this.loggenInUserId=JSON.parse(local1);
    this.store.pipe(select(fromRoleSetup.getPermission)).subscribe(roles => {
      console.log("2",roles)
      roles.filter((el,index)=>{
        if(this.loggenInUserId.id==el.user){
          this.userPermission=el.permission;
        }
      })
    })    
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.isAuthorized(route,state);
  }
  
  isAuthorized(route:ActivatedRouteSnapshot,state:RouterStateSnapshot){
    const expectedRoles = route.data.Roles;
    console.log("trrtrt=",this.userPermission)         
    this.roleMatchs =this.userPermission.findIndex((role:any)=>expectedRoles.indexOf(role) !== -1);        
    if(this.roleMatchs === -1){
      console.log("4");
      // alert("Caution ! Action can't be perfored");
      return false;         
    }else{  
      console.log("5")
      return true;
    }    
  }
}
