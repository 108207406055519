import { Component, OnInit } from '@angular/core';
import { UserService } from "../users/user.service";
// import { LoginSetupModal,UsersComponent} from "../users/users.component";
import Swal from "sweetalert2";
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { pendingUser } from '../users/pendingUser';
import { Store } from "@ngrx/store";
import { loginSetup } from 'src/app/user.actions';


@Component({
  selector: 'app-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.css']
})
export class EditButtonComponent implements OnInit {

  constructor(private _userService:UserService,/*private loginSetup:LoginSetupModal,*/ private store:Store) { }

  componentView:any;
  params:any;
  agInit(params:any){
    this.componentView=params.value;
    this.params=params;
    console.log(this.params,'params');
  }
  GeneratePassword(){
    return Math.random().toString(36).slice(-8);
  }
  setupLogin(){
    const credentials={
      id:this.params.data.id,
      username:this.params.data.id,
      password:this.GeneratePassword(),
      name:this.params.data.fname+' '+this.params.data.lname
    }
    this._userService.loginSetupOfPendingUser(credentials).subscribe(payload=>{
      //console.log("loginSetupComplete",payload,this.componentView,this.params);
      if(payload){
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: this.params.data.fname+' '+this.params.data.lname+'\'s login setup now completed !',
          showConfirmButton: false,
          timer:2500,
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        });    

        // this.loginSetup.pendingUsers=this.loginSetup.pendingUsers.filter((item,index) =>{
        //   return (item.id!=this.params.data.id)
        // })
        // this._userService.fetchUsers().subscribe((payload)=>{
        //   console.log("wee",payload);
        //   this.usercomponent.rowData=payload;
        // })
      }else{
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title:'Setup already done !',
          showConfirmButton: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        })
      }
    }) 
    var tempId=this.params.data.id;
    this.store.dispatch(new loginSetup(tempId))   
  }

  // DeleteUser(){
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then((result) => {
  //     if (result.isConfirmed) {   
  //       this._userService.deleteUser(this.params.data.id).subscribe(()=>{
  //         this.loginSetup.pendingUsers=this.loginSetup.pendingUsers.filter((item,index) =>{
  //           return (item.id!=this.params.data.id)
  //         })
  //       })   
  //       Swal.fire(
  //         'Deleted!',
  //         'User has been deleted.',
  //         'success'
  //       )
  //     }
  //   })
  // }

  ngOnInit(): void {
  
  }

}
