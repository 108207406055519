import { Component } from '@angular/core';
import {NavigationEnd, NavigationStart, Router  } from "@angular/router";
import {filter} from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { PermissionRollSetupComponent } from './dashboard/permission-roll-setup/permission-roll-setup.component';
import * as roleSetupAction from './dashboard/permission-roll-setup/permission-roll-setup.actions'
import { ShiftComponent } from './dashboard/shift/shift.component';
import * as fromShift from './dashboard/shift/shift.selectors'
import { DigitalClockComponent } from './dashboard/digital-clock/digital-clock.component';

let pd!:any[];
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'ver1';
  HeaderTitle:string='Cosmic 2.0';
  pageTtitle:string='dashboard';
  recievedOrders:number=0;
  confirmOrders:number=0;
  finalOrders:number=0;
  newEmploye:number=0;
  isloggedIn:boolean=false;
  subscription: any;
  browserRefresh:boolean | undefined

  constructor(private _digiClock:DigitalClockComponent,private store:Store,private _router:Router,private roleSetCom:PermissionRollSetupComponent,private _shiftComp:ShiftComponent ){
    this.store.dispatch(new roleSetupAction.LoadPermissionRollSetups)
    this._shiftComp.ngOnInit();
    this.store.pipe(select(fromShift.getShift)).subscribe(payload=>{     
      payload.map(el=>{
        console.log("text=",el.status,this._digiClock.shiftTitle,typeof(el.status))
        if(el.status==true){
          //console.log(el.shiftName,'shiftName');
          this._digiClock.shiftTitle=el.shiftName
        }
      })
    })
    // _router.events.subscribe((event) => {
    //   if (event instanceof NavigationStart && event.url !='/Dashboard'){ 
    //     console.log("1",event.url);
    //     this.browserRefresh = !_router.navigated;        
    //   }else{
    //     console.log("2",);
    //     this._router.navigate(['Dashboard']);
    //   }            
    // })    
  }
  
  ngOnInit(){  
    if(localStorage.getItem("loggedIn")){
      this.isloggedIn=true; 
    }
    //this.roleSetCom.ngOnInit();    
    this._router.events.pipe(filter((event:any) => event instanceof NavigationEnd)).subscribe(event => {
      if(event.url=='/login'){            
        return this._router.navigate(['Dashboard']);        
      }
      return true    
    });

    // this.store.dispatch(new ProductActions.LoadProducts)
    // this.store.pipe(select(fromProduct.getProduct)).subscribe(product=>pd=product)
  }
 
  
}
