import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './permission-roll-setup.reducer';

const getRoleSetupPermissionFeatureState = createFeatureSelector<State>('permissionRollSetup');

export const getPermission = createSelector(
    getRoleSetupPermissionFeatureState,
    state => state.userRollPermission
)

export const getError = createSelector(
    getRoleSetupPermissionFeatureState,
    state => state.error
)
export const createPermission= createSelector(
    getRoleSetupPermissionFeatureState,
    state=>state.userRollPermission
)
