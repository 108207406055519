<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-1">
          <div class="col-sm-6">
            <h2 class="m-0">{{pageTitle | uppercase}}</h2>
          </div><!-- /.col -->          
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right buttonList">                      
                <li class="" (click)="AddDomain()"><button mat-mini-fab color="accent"><i class="fas fa-plus"></i></button></li>
                <!-- <li class="" (click)="LoadDomain()"><button mat-mini-fab color="accent"><i class="fas fa-globe-asia"></i></button></li>               -->
                <li class="" (click)="refresh()"><button mat-mini-fab color="accent" type="reset"> <i class="fas fa-sync-alt" aria-hidden="true"></i></button></li>
            </ol>
          </div>
        </div>
    </div>
    <section [@widthGrow]="state" style='height: 0;' *ngIf='DomainSectionSwitch'>       
        <form autocomplete="off" class='addDomain' (ngSubmit)="submitDomain(formDirective)" [formGroup]="addDomainForm" #formDirective="ngForm">
            <div class="formWrapper">
                <mat-form-field appearance="outline">
                    <mat-label>Domain Name</mat-label>
                    <input formControlName='DomainName' matInput placeholder="Enter Domain Name" required>
                </mat-form-field>    
                <mat-dialog-actions>
                    <button mat-raised-button color="primary" type="submit" [disabled]= "!addDomainForm.valid">Create</button>
                    <a (click)='AddDomain()'>Close</a>
                </mat-dialog-actions>
            </div>

        </form>        
    </section>
    <section class="content" id="renderDataSection">
        <div class="container-fluid">
          <!-- Info boxes -->
              
            <div class="row" >
                <!-- <div class='filterToggle'><a (click)='showFilter()'>Show Filter</a></div> -->
                <!-- <div class="filterSection" *ngIf='filterRowShow'>
                    <div class="ag-header-container" style='text-align: center;'>
                        <input type="text" #filterTextBox placeholder="Type Something..." (input)='onFilterTextBoxChanged()' id='typeSomethingFilter'/>
                    </div>
                </div>                -->
                <ag-grid-angular 
                    #DomainList
                    style="width: 1470px; height: 500px;"
                    class="ag-theme-balham"                    
                    [rowData]="rowData" 
                    [defaultColDef]="defaultColDef"
                    [columnDefs]="columnDefs"
                    [animateRows]='true'
                    [pagination]='true'
                    [paginationPageSize]="paginationPageSize"
                    (onColumnResized)="onResize($event)"
                    >
                </ag-grid-angular>
            <!-- /.col -->
            </div>
          <!-- /.row -->
  
       
          <!-- /.row -->
  
          <!-- Main row -->
        
          <!-- /.row -->
        </div><!--/. container-fluid -->
      </section>
</div>