import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LeftMenuSectionComponent } from "../left-menu-section/left-menu-section.component";
import { LodingBarServiceService } from "../loading-bar/loding-bar-service.service";
import * as DashboardPageActions from "./dashboard-page.actions"
import { dashboardPage } from './dashboardPage';
import * as fromdashboardPage from './dashboard-page.selectors'
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { dashboardPageFeatureKey } from './dashboard-page.reducer';
import { UtilService } from 'src/app/utilities/util.service';
import { PermissoinService } from '../permissoin/permissoin.service';
import { DashboardPageService } from './dashboard-page.service';
import { MonthlyRecapReport } from './MontlyRecapReport';
import { UserService } from '../users/user.service';
import { empOfMonth } from './employeOfThmeMonth';
import { TLofMonth } from './TLofMonth';


@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.css']
})
export class DashboardPageComponent implements OnInit {

  HeaderTitle:string='Cosmic 2.0';
  pageTtitle:string='dashboard';
  recievedOrders:number=0;
  confirmOrders:number=0;
  finalOrders:number=0;
  newEmploye:number=0;
  dashboardData!:dashboardPage[];  
  Months:any=[];
  loggenInUser: any;
  monthlyRecapReport!:MonthlyRecapReport[]
  yearOrdersReport!:any[]
  latestUsers!:any[]
  starPerformers!:empOfMonth[]
  starTLperformer!:TLofMonth[]
  //dashboardData:DashboardModal= new DashboardModal();
  constructor(private _userService:UserService,private lbs:LodingBarServiceService,private store:Store, private _utils:UtilService,private _permissionService:PermissoinService,private _dashboardPgService:DashboardPageService) {
    this.Months=this._utils.months;
    const local1:any=localStorage.getItem('user');
    this.loggenInUser=JSON.parse(local1);   
  }
  getMonthTitle(index:number):any{
    return this.Months.find((month:any)=>month.index==index)?.name
  }
  changeDashBoardMonthlyReport(event:any){
    this._dashboardPgService.getMonthlyRecapReport(event.option.value,this.loggenInUser.id,this.loggenInUser.category).subscribe(payload=>{
      this.monthlyRecapReport=payload;
    })
  }
  ngOnInit(): void {
    this.lbs.showBar(true);
    // this._permissionService.getRollSetup().subscribe((payload:any)=>{
    //   console.log("payload=",payload);
    //  })
    this._dashboardPgService.getEmpOfMonthReport().subscribe(payloadSTAR=>this.starPerformers=payloadSTAR);  
    this._dashboardPgService.getTLOfMonthReport().subscribe(payloadTL=>this.starTLperformer=payloadTL);      
    this.store.dispatch(new DashboardPageActions.LoadDashboardPages)
    this.store.pipe(select(fromdashboardPage.getDashboardPage)).subscribe((payload)=>{
      if(payload.length){     
        this.dashboardData=payload;
        this.lbs.clear();
      }      
    })
    this._dashboardPgService.getMonthlyRecapReport("undefined",this.loggenInUser.id,this.loggenInUser.category).subscribe(RecapReportPayload=>{
      this.monthlyRecapReport=RecapReportPayload;
    })
    this._dashboardPgService.getYearOrderReport().subscribe(yearReportPayload=>{
      this.yearOrdersReport=yearReportPayload
    })
    this._userService.fetchLatestMembers().subscribe((payload:any)=>{
      this.latestUsers=payload;
    })
  }
  clear(){
    this.lbs.clear();
  }
  ngAfterViewInit(){
    this.lbs.clear()
  }

}
