<div>
    <h5>Edit User</h5>
    <form class="EditUserForm" (ngSubmit)="EditUserAction(formDirective)" [formGroup]="EditUserForm" #formDirective="ngForm">    
        <mat-dialog-content class="mat-typography">
            <div class="userANDdesignation">
                <mat-form-field class="example-chip-list" appearance="fill">
                    <mat-label>Users</mat-label>
                    <mat-chip-list #chipList aria-label="Fruit selection" >
                        <mat-chip
                          *ngFor="let fruit of fruits"
                          (removed)="remove(fruit)">
                          {{fruit.id}}-{{fruit.name}}
                          <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                          </button>
                        </mat-chip>
                        <input
                          placeholder="New fruit..."
                          #fruitInput
                          formControlName="user"                   
                          [matAutocomplete]="auto"
                          [matChipInputFor]="chipList"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" required>
                      </mat-chip-list>
                      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">
                          {{fruit.id}}-&nbsp;{{fruit.name}}
                        </mat-option>
                      </mat-autocomplete>
                </mat-form-field>
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Designation</mat-label>
                    <input type="text"
                        placeholder="Pick one"
                        aria-label="Designation"
                        matInput
                        formControlName="designation"
                        [matAutocomplete]="designation" required>
                    <mat-autocomplete #designation="matAutocomplete" (optionSelected)="onSelectionChange($event)"  [displayWith]="getDesignationTitle.bind(this)">
                        <mat-option *ngFor="let designation of filteredDesignationList | async"  [value]="designation.id">
                            {{designation.category}} - {{designation.department}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="userANDdesignation">
                <div class="example-chip-list productList">
                    <mat-label><strong style='text-align: center;'>Products:</strong></mat-label>
                    <mat-selection-list #products fromControlName='product' (selectionChange)="onProductSelection($event, products.selectedOptions.selected)">
                        <mat-list-option *ngFor="let product of productList index by id" [value]='product.id'>
                          {{product.name}}
                        </mat-list-option>
                    </mat-selection-list> 
                    <!-- <p>Products Selected: {{products.selectedOptions.selected.length}}</p>                                     -->
                </div>               
                <div class="example-chip-list productList">
                    <mat-label><strong style='text-align: center;'>Permission:</strong></mat-label>
                    <mat-selection-list #permission fromControlName='Permission' (selectionChange)="onPermissionSelection($event, permission.selectedOptions.selected)">
                        <!-- <mat-select-filter [array]="permissionList" (filteredReturn)="permissionList =$event"></mat-select-filter> -->
                        <mat-list-option *ngFor="let Permission of permissionList index by id" [value]='Permission.id'>
                          {{Permission.title}}
                        </mat-list-option>
                    </mat-selection-list>                    
                    <!-- <p>Products Selected: {{products.selectedOptions.selected.length}}</p>                                      -->
                </div>               
            </div>
            <mat-dialog-actions align="end">
                <button mat-raised-button mat-dialog-close>Cancel</button>
                <button mat-raised-button color="accent" type="reset">Reset</button>
                <button mat-raised-button color="primary" type="submit" [disabled]= "!EditUserForm.valid">Update</button>
            </mat-dialog-actions>
        </mat-dialog-content>
    </form>
</div>
<style>
    div h5{
        background: #ff4081;
        color: #fff;
        text-align: center;
        top: 0;
        position: absolute;
        left: 0;
        right: 0;
        font-size: 16px;
        padding: 6px;
    }
</style>