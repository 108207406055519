import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from "./order-status.reducer";

const getorderStatusFeatureState = createFeatureSelector<State>('orderStatus');

export const getorderStatus = createSelector(
    getorderStatusFeatureState,
    state => state.orderStatus
)

export const getError = createSelector(
    getorderStatusFeatureState,
    state => state.error
)
// export const createProduct= createSelector(
//     getorderStatusFeatureState,
//     state=>state.orderStatus
// )
