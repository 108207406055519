import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Domain } from './domain';
import { DomainService } from './domain.service';
import * as DomainAction from './domain.actions';
import * as fromDomain from "./domain.selectors";

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.css'],
  animations: [
    trigger('widthGrow', [
        state('closed', style({
          height:  '0px',
          opacity: 0          
        })),
        state('open', style({
          height: '80px',
          opacity: 1
        })),
        transition('* => *', animate(250))
    ]),
  ]
})
export class DomainComponent implements OnInit {

  pageTitle:string='Domain'
  paginationPageSize=19
  waitingImage:boolean=false
  state = "closed";
  rowData!:Domain[]
  addDomainForm!:FormGroup
  DomainSectionSwitch:boolean=false
  constructor(private _domainService:DomainService, private store:Store) { 
    this.addDomainForm=new FormGroup({
      DomainName: new FormControl('',Validators.required)
    })
  }

  ngOnInit() {
    this.store.dispatch(new DomainAction.LoadDomains)
    this.store.pipe(select(fromDomain.getDomain)).subscribe(payload=>this.rowData=payload) 
  }
  defaultColDef = {
    editable:true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:100,
    sideBar:true
  };
  columnDefs=[
    {headerName: 'Id', field: 'id',hide:false},
    {headerName: 'Domain', field: 'domain',hide:false},
    {headerName: 'Status', field: 'status',hide:false}
  ]
  AddDomain(){
    (this.state == "closed") ? this.state = "open" : this.state = "closed";
    //this.DomainSectionSwitch=!this.DomainSectionSwitch    
    this.DomainSectionSwitch=true;
  }
  LoadDomain(){
    this.ngOnInit()
  }
  refresh(){

  }
  onResize($event:any){

  }
  CloseDomain(){

  }
  submitDomain(formDirective:FormGroupDirective){
    this.waitingImage=true;
    if(formDirective.valid){
      this._domainService.addNewDomain(this.addDomainForm.value).subscribe(payload=>{
        console.warn("payload",payload)
        if(payload){
          formDirective.resetForm()
          this.LoadDomain();
        }          
      });
    }
  }



}
