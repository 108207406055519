import { Action } from '@ngrx/store';
import { orders } from "./orders"; 
import { OrderActionTypes,OrderActions } from "./order.actions";

import { stat } from 'node:fs';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
export const orderFeatureKey = 'order';
export const postReducers='reducer';

export interface State {
  orders:orders[]
  error:string,
  editOrder:orders[]
  reOrder:orders[]
}

export const initialState: State = {
  orders:[],
  error:"",
  editOrder:[],
  reOrder:[]
};

export function reducer(state = initialState, action: OrderActions): State {
  switch (action.type) {
    case OrderActionTypes.LoadOrders:
      return {
        ...state
      }
    case OrderActionTypes.LoadOrdersFailure:
      return{
        ...state,
        orders:state.orders,
        error:action.payload.error
      }
    case OrderActionTypes.LoadOrdersSuccess:
      return{
        ...state,
        orders:action.payload.data,
        error:""
      }
    case OrderActionTypes.CreateOrder:    
      console.log("2")
      return{
        ...state
      }
    case OrderActionTypes.EditOrder:
      console.log(state.orders);
      var selectOrderForEdit = state.orders.filter(orders =>(orders.id === action.payload.id))
      console.log(selectOrderForEdit);
      return{
        ...state,
        editOrder:selectOrderForEdit
      }
    case OrderActionTypes.loadFilterOrders:
      return{
        ...state,
        orders:action.payload.data,
        error:""
      }
    // case OrderActionTypes.EditOrderFailure:
    //   return{
    //     ...state,
    //     orders:state.orders,
    //     error:action.payload.error
    //   }    
    default:
      return state;
  }
}
