import { Action } from '@ngrx/store';
import { Shift } from './Shift';
import { ShiftActionTypes,ShiftActions } from "./shift.actions";


export const shiftFeatureKey = 'shift';
export const shiftReducers='shift'

export interface State {
  shift:Shift[],
  error:string
}

export const initialState: State = {
  shift:[],
  error:""
};

export function reducer(state = initialState, action: ShiftActions): State {
  switch (action.type) {
    
    case ShiftActionTypes.LoadShifts:
      return{
        ...state
      }
      case ShiftActionTypes.LoadShiftsSuccess:
        return{
          ...state,
          shift:action.payload.data,
          error:""
        }
      case ShiftActionTypes.LoadShiftsFailure:
        return{
          ...state,
          error:action.payload.error
        }
      default:
        return state;
  }
}
