import { Action } from '@ngrx/store';
import { LoginAudit } from './login-audit';

export enum LoginAuditActionTypes {
  LoadLoginAudits = '[LoginAudit] Load LoginAudits',
  LoadLoginAuditsSuccess = '[LoginAudit] Load LoginAudits Success',
  LoadLoginAuditsFailure = '[LoginAudit] Load LoginAudits Failure',  
}
// export enum LoggedInUserActionTypes{
//   LoadLoggedInUsers = '[LoggedInUsers] Load LoggedInUsers',
//   LoadLoggedInUsersSuccess = '[LoggedInUsers] Load LoggedInUsers Success',
//   LoadLoggedInUsersFailure = '[LoggedInUsers] Load LoggedInUsers Failure',  
// }

export class LoadLoginAudits implements Action {
  readonly type = LoginAuditActionTypes.LoadLoginAudits;
}

export class LoadLoginAuditsSuccess implements Action {
  readonly type = LoginAuditActionTypes.LoadLoginAuditsSuccess;
  constructor(public payload: { data: LoginAudit[] }) { }
}

export class LoadLoginAuditsFailure implements Action {
  readonly type = LoginAuditActionTypes.LoadLoginAuditsFailure;
  constructor(public payload: { error: any }) { }
}

export type LoginAuditActions = LoadLoginAudits | LoadLoginAuditsSuccess | LoadLoginAuditsFailure;

// export class LoadLoggedInUsers implements Action {
//   readonly type = LoggedInUserActionTypes.LoadLoggedInUsers;
// }

// export class LoadLoggedInUsersSuccess implements Action {
//   readonly type = LoggedInUserActionTypes.LoadLoggedInUsersSuccess;
//   constructor(public payload: { data: LoggedInUsers[] }) { }
// }

// export class LoadLoggedInUsersFailure implements Action {
//   readonly type = LoggedInUserActionTypes.LoadLoggedInUsersFailure;
//   constructor(public payload: { error: any }) { }
// }

// export type LoggedInUserActions = LoadLoggedInUsers | LoadLoggedInUsersSuccess | LoadLoggedInUsersFailure;

