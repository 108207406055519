import { parseLazyRoute } from '@angular/compiler/src/aot/lazy_routes';
import { ɵangular_packages_platform_browser_dynamic_platform_browser_dynamic_a } from '@angular/platform-browser-dynamic';
import { Action } from '@ngrx/store';
import { Report } from './report';
import { ReportActions,ReportActionTypes } from './report.actions';


export const reportFeatureKey = 'report';
export const reportReducers='report';

export interface State {
  report:Report[],
  error:string
}

export const initialState: State = {
  report:[],
  error:""
};

export function reducer(state = initialState, action: ReportActions): State {
  switch (action.type) {
    case ReportActionTypes.LoadReports:
      return{
        ...state
      }
    case ReportActionTypes.LoadReportsSuccess:
      return{
        ...state,
        report:action.payload.data,
        error:""
      }
    case ReportActionTypes.LoadReportsFailure:
      return{
        ...state,
        error:action.payload.error
      }
    default:
      return state;
  }
}
