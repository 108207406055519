import { Action } from '@ngrx/store';

export enum PermissionRollSetupActionTypes {
  LoadPermissionRollSetups = '[PermissionRollSetup] Load PermissionRollSetups',
  LoadPermissionRollSetupsSuccess = '[PermissionRollSetup] Load PermissionRollSetups Success',
  LoadPermissionRollSetupsFailure = '[PermissionRollSetup] Load PermissionRollSetups Failure',
}

export class LoadPermissionRollSetups implements Action {
  readonly type = PermissionRollSetupActionTypes.LoadPermissionRollSetups;
}

export class LoadPermissionRollSetupsSuccess implements Action {
  readonly type = PermissionRollSetupActionTypes.LoadPermissionRollSetupsSuccess;
  constructor(public payload: { data: any }) { }
}

export class LoadPermissionRollSetupsFailure implements Action {
  readonly type = PermissionRollSetupActionTypes.LoadPermissionRollSetupsFailure;
  constructor(public payload: { error: any }) { }
}

export type PermissionRollSetupActions = LoadPermissionRollSetups | LoadPermissionRollSetupsSuccess | LoadPermissionRollSetupsFailure;

