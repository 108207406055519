import { ObserversModule } from '@angular/cdk/observers';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { baseUrl } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { dashboardPage } from './dashboardPage';
import { empOfMonth } from './employeOfThmeMonth';
import { MonthlyRecapReport } from './MontlyRecapReport';
import { TLofMonth } from './TLofMonth';

@Injectable({
  providedIn: 'root'
})
export class DashboardPageService {

  constructor(private _http:HttpClient) { }
  handleError(errorResponse: HttpErrorResponse) {
    const error= errorResponse.error;
    const keys=Object.keys(error);
    const key = keys[0];
    let message = error[key];
    console.log(keys);
    if(errorResponse.status == 0){
      // auth token delete
      //redirect login page
      message="no Server"
    }
    if(error[key] instanceof Array){
      message = error[key][0];
    }
    if(key === "isTrusted"){
      Swal.fire({
        position: 'bottom-end',
        icon: 'error',
        title:'Server not started!',
        showConfirmButton: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      })
    }else{
      message = key+ ':'+message;
    }
    return throwError({messages:message,error})
  }

  getTodayDashboardPageResult(user:any,category:any):Observable<dashboardPage[]>{
    const params={params:{user:user,category:category}}
    return this._http.get<dashboardPage[]>(baseUrl+'/getTodayDashboardResult.php',params).pipe(catchError(this.handleError.bind(this)));
  }
  getCurrentMonthDashboardPageResult():Observable<dashboardPage[]>{
    return this._http.get<dashboardPage[]>(baseUrl+'/getCurrentMonthDashboardResult.php?pp').pipe(catchError(this.handleError.bind(this)));
  }
  getMonthlyRecapReport(month:any,user:any,category:any):Observable<MonthlyRecapReport[]>{
    const params={params:{month:month,user:user,category:category}}
    return this._http.get<MonthlyRecapReport[]>(baseUrl+'/getMonthlyRecapReport.php',params).pipe(catchError(this.handleError.bind(this)))
  }
  getYearOrderReport():Observable<any[]>{
    return this._http.get<any>(baseUrl+'/getYearOrderReport.php').pipe(catchError(this.handleError.bind(this)));
  }
  getEmpOfMonthReport():Observable<empOfMonth[]>{
    return this._http.get<empOfMonth[]>(baseUrl+'/getEmpOfMonth.php').pipe(catchError(this.handleError.bind(this)));
  }
  getTLOfMonthReport():Observable<TLofMonth[]>{
    return this._http.get<TLofMonth[]>(baseUrl+'/getTLOfMonth.php').pipe(catchError(this.handleError.bind(this)));
  }

}
