<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-1">
          <div class="col-sm-6">
            <h2 class="m-0">{{pageTtitle | uppercase}}</h2>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">        
              <li class="" (click)="createPermission()"><button mat-mini-fab color="accent"><i class="fas fa-plus"></i></button></li>                      
              <li class="" (click)="refreshUser()"><button mat-mini-fab color="accent" type="reset"> <i class="fas fa-sync-alt" aria-hidden="true"></i></button></li>
            </ol>
          </div>
          <!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
    <section [@widthGrow]="state" style='height: 0;' *ngIf='PermissionSectionSwitch'>       
      <form autocomplete="off" class='addPermission' (ngSubmit)="submitPermissio(formDirective)" [formGroup]="addPermissionForm" #formDirective="ngForm">
          <div>
              <mat-form-field appearance="outline">
                  <mat-label>Permission Name</mat-label>
                  <input formControlName='PermissionTitle' matInput placeholder="Permission Title" required>
              </mat-form-field>
              <mat-form-field appearance="outline">
                  <mat-label>Category</mat-label>
                  <input type="text"
                         placeholder="Pick one"
                         aria-label="Choose Category"
                         matInput
                         formControlName="PermissionCat"
                         [matAutocomplete]="PermissionCat" required>
                  <mat-autocomplete #PermissionCat="matAutocomplete">
                    <mat-option *ngFor="let category of filteredCategory | async" [value]="category">
                      {{category}}
                    </mat-option>
                  </mat-autocomplete>
              </mat-form-field>
              <mat-dialog-actions>
                  <button mat-raised-button color="accent" type="reset">Reset</button>
                  <button mat-raised-button color="primary" type="submit" [disabled]= "!addPermissionForm.valid">Create</button>
                  <a (click)='createPermission()'>Close</a>
              </mat-dialog-actions>
          </div>
      </form>        
    </section>
    <!-- Main content -->
    <section class="content" id="renderDataSection">
      <div class="container-fluid">
        <!-- Info boxes -->
        <div class="ag-header-container" style='text-align: right;'>
          <input type="text" #filterTextBox placeholder="Type Something..." (input)='onFilterTextBoxChanged()' id='typeSomethingFilter'/>
        </div>    
        <div class="row" >
          <ag-grid-angular 
              #permissionList
              style="width: 1470px; height: 500px;"
              class="ag-theme-balham"
              [sideBar]="sideBar"
              [rowData]="rowData" 
              [defaultColDef]="defaultColDef"
              [columnDefs]="columnDefs"
              [animateRows]='true'
              [pagination]='true'
              [paginationPageSize]="paginationPageSize"
              (onColumnResized)="onResize($event)"
              >
          </ag-grid-angular>
          <!-- /.col -->
        </div>
        <!-- /.row -->

     
        <!-- /.row -->

        <!-- Main row -->
      
        <!-- /.row -->
      </div><!--/. container-fluid -->
    </section>
    <!-- /.content -->
  </div>