import { ElementSchemaRegistry } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { IUser } from '../../user';
import { UserService } from "../users/user.service";
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import Swal from "sweetalert2";
import { Store,select } from "@ngrx/store";
import { deleteUser } from 'src/app/user.actions';
import { Console } from 'node:console';
import { loginHistory } from './loginHistory';
import {PermissoinService} from "../permissoin/permissoin.service"
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { OrderActionButtonComponent } from '../order-action-button/order-action-button.component';
import { PermisionActionButtonComponent } from '../permision-action-button/permision-action-button.component';


@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.css']
})
export class ActionButtonsComponent implements OnInit { 
  constructor(private _userService:UserService,private dialog:MatDialog,private store:Store,private _permissionService:PermissoinService) { }
  componentView:any;
  params!: { data: IUser; };
  sureDelete:boolean=false;
  nextParam:any;
  disableToggle:boolean=true;
  isDialogOpned: boolean=false;
  isLoginHistoryDialogOpned:boolean=false
  isPermissionAssignedDialogOpned: boolean=false;
  loginHistoryData:loginHistory[]=[]  
  
  agInit(params:any){
    this.componentView=params.value;
    this.params=params;
    this.nextParam=params;
  }
  ngOnInit():void{
    if(this.params.data.loginPermission==true){      
      this.disableToggle=true;
    }else{
      this.nextParam.eParentOfValue.offsetParent.childNodes.forEach((_element: any) => {
        _element.className +=" disableClass";
      });
      this.disableToggle=false;
    }
  }
  refresh(params:any):boolean{
    return true
  }
  DeleteUser(){
    var id=this.params.data.id;
    console.log("df=",id);
    this._userService.disableUser(id).subscribe(()=>{});
    this.store.dispatch(new deleteUser({id}))
  }
  EditUser(event:Event){
    
  }
  Reorder(event:any){
    console.log("calling reorder");
  }
  passwordReset(){
    Swal.fire({
      title: 'Are you sure want to reset the password?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, do it!'
    }).then((result) => {
      if (result.isConfirmed) {   
        this._userService.passwordReset(this.params.data.id).subscribe((payload)=>{          
          console.log(payload);          
        });   
        Swal.fire(
          'Password Reset Complete!',
          'success'
        )
      }
    })
  }  
  DisableUser(){
    this._userService.disableUser(this.params.data.id).subscribe(()=>{
      this.nextParam.eParentOfValue.offsetParent.childNodes.forEach((_element: any) => {
        console.log(_element.className.indexOf("disableClass"));
        if(_element.className.indexOf("disableClass")!= -1){
          _element.className = _element.className.replace('disableClass','');
        }else{
          _element.className += " disableClass";
        }        
      });
     })
  }
  loginHistory(event:any){
    this._userService.loginHistory(this.params.data.id).subscribe(loginHistory=>{
      if(loginHistory){
        if(!this.isLoginHistoryDialogOpned){
          const dialogRef = this.dialog.open(LoginHistoryDialog,{
            width: '1200px',
            disableClose:false,
            data:loginHistory,
            panelClass: 'loginHistoryDialog'
          });   
          this.isLoginHistoryDialogOpned = !this.isLoginHistoryDialogOpned;
          dialogRef.afterClosed().subscribe(result => {
            this.isLoginHistoryDialogOpned = !this.isLoginHistoryDialogOpned;
            console.log(`Dialog result: ${result}`);
          });
        }
      }else{
        Swal.fire("No Login Records");
      }
    })
  }
  permissionAssigned(event:any){
    this._permissionService.getPerticulerRollSetup(this.params.data.id).subscribe(permissionAssignedPayload=>{      
      if(permissionAssignedPayload){
        if(!this.isPermissionAssignedDialogOpned){
          const dialogRef = this.dialog.open(PermissionAssignedDialog,{
            width: '800px',
            disableClose:false,
            data:permissionAssignedPayload,
            panelClass: 'loginHistoryDialog'
          });   
          this.isPermissionAssignedDialogOpned = !this.isPermissionAssignedDialogOpned;
          dialogRef.afterClosed().subscribe(result => {
            this.isPermissionAssignedDialogOpned = !this.isPermissionAssignedDialogOpned;
            console.log(`Dialog result: ${result}`);
          });
        }
      }else{
        Swal.fire({
          title: 'No Permission Assigned',
          icon:'info',
          width: 600,
          padding: '3em',
          background: '#fff',
          customClass: {
            container:'swalbackgroundMargin',
            // popup:'popupcustom'
          },
          focusConfirm: false,
          confirmButtonText:'<i class="fa fa-exclamation-circle" aria-hidden="true"></i> OK',
        })
      }
    })
  }
}

@Component({
  selector: 'login-history-dialog',
  templateUrl: './login-history-dialog.html',
  styleUrls: ['./action-buttons.component.css'],
  animations:[
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [ style({ opacity: 0 }), animate(600) ]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class LoginHistoryDialog {
  loginHistoryDetails!:loginHistory[];
  historyLoginID!:number
  constructor(@Inject(MAT_DIALOG_DATA) private data: loginHistory[]){    
    this.loginHistoryDetails=this.data;
    this.historyLoginID=this.loginHistoryDetails[0].userId;
  }
  ngOnInit(): void {
   
  }
}
@Component({
  selector: 'permission-assigned-dialog',
  templateUrl: './permission-assigned-dialog.html',
  styleUrls: ['./action-buttons.component.css'],
  animations:[
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [ style({ opacity: 0 }), animate(600) ]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})

export class PermissionAssignedDialog {  
  userID!:number  
  permissionAssignedDetails: any[];
  paginationPageSize:number=11;
  sideBar:boolean = false;
  public rowSelection = 'multiple';
  constructor(@Inject(MAT_DIALOG_DATA) private data: any[],private _permissionService:PermissoinService){    
    this.permissionAssignedDetails=this.data[0].permission;
    this.userID=this.data[0].user;
  } 
  defaultColDef = {
    editable:true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:100,
    sideBar:true,
    columnHoverHighlight: true
  };  
  columnDefs = [
    {headerName: 'Action',cellRendererFramework:PermisionActionButtonComponent,checkboxselection:true,headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true, resizable: true,minWidth:150},
    {headerName: 'Permission Name', field: 'name',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Code', field: 'code',cellRendererFramework:TooltipComponent,hide:false}
  
  ]
  ngOnInit(): void {

  }
  onResize(event:any){

  }
  onRowSelected(event:any){

  }
  user():any{
    return this.userID;
  }
  refresh(){
    this._permissionService.getPerticulerRollSetup(this.userID).subscribe(permissionAssignedPayload=>{      
      this.permissionAssignedDetails=permissionAssignedPayload[0].permission;
    })
  }

}
