import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { DashboardPageService } from "./dashboard-page.service";
import * as DashboardPageActions from "./dashboard-page.actions"
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { dashboardPage} from './dashboardPage'



@Injectable()
export class DashboardPageEffects {
  loggenInUser: any;
  constructor(private actions$: Actions,private _dashboardPageServices:DashboardPageService) {
    const local1:any=localStorage.getItem('user');
    this.loggenInUser=JSON.parse(local1);
    console.log(JSON.parse(local1));
  }
  loadDashboardPage=createEffect(()=> {
    return this.actions$.pipe(
     ofType(DashboardPageActions.DashboardPageActionTypes.LoadDashboardPages),
     mergeMap(
       action => this._dashboardPageServices.getTodayDashboardPageResult(this.loggenInUser.id,this.loggenInUser.category).pipe(
         map((dashboardPg) => new DashboardPageActions.LoadDashboardPagesSuccess({data:dashboardPg})),
         catchError(err=> of(new DashboardPageActions.LoadDashboardPagesFailure({error:err})))
       )
     )
   )
 })
}
