import { Action, createReducer, on } from '@ngrx/store';
import { PermissionRollSetupActions,PermissionRollSetupActionTypes } from "./permission-roll-setup.actions";


export const permissionRollSetupFeatureKey = 'permissionRollSetup';

export interface State {
  userRollPermission:any[],
  error:any
}

export const initialState: State = {
  userRollPermission:[],
  error:""

};


export function reducer(state = initialState, action: PermissionRollSetupActions): State {
  switch (action.type) {
    case PermissionRollSetupActionTypes.LoadPermissionRollSetups:
      return{
        ...state
      }
    case PermissionRollSetupActionTypes.LoadPermissionRollSetupsSuccess:
      return {
        ...state,
        userRollPermission:action.payload.data,
        error:""
      }
    case PermissionRollSetupActionTypes.LoadPermissionRollSetupsFailure:
      return{
        ...state,
        error:action.payload.error
      }
      default:
        return state;
    }
}

