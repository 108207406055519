import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { baseUrl } from "../../environments/environment";
import { Observable,BehaviorSubject } from "rxjs";
import { User } from '../auth/user';

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  x:User={username:"",password:""};

  user: BehaviorSubject<User> = new BehaviorSubject<User>(this.x);

  constructor(private _http:HttpClient) { }

  authUser(data:User):Observable<any>{
    this.x.username=data.username;
    this.x.password=data.password;
    const params={params:{un:data.username,pwd:data.password}}
    return this._http.get(baseUrl+'/authUser.php?5',params);
  }


}
