import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DashboardPageService } from 'src/app/dashboard/dashboard-page/dashboard-page.service';
import { PermissoinService } from 'src/app/dashboard/permissoin/permissoin.service';
import * as roleSetupAction from '../../dashboard/permission-roll-setup/permission-roll-setup.actions'
import * as fromRoleSetup from '../../dashboard/permission-roll-setup/permission-roll-setup.selectors'


@Directive({
  selector: '[appHideForRoles]'
})
export class HideForRolesDirective implements OnInit{

  userPermission:Array<any>=[];
  loggenInUserId:any;
  constructor(private store:Store,private viewContainerRef:ViewContainerRef,private templateRef:TemplateRef<any>,private _dashboardPage:DashboardPageService,private _permissionService:PermissoinService) {
    
  }
  
  ngOnInit(): void {
  
  }

  @Input() set appHideForRoles(hideForRoles:Array<string>){
    const local1:any=localStorage.getItem('user');
    this.loggenInUserId=JSON.parse(local1);
    //console.log(this.loggenInUserId.id);
    const hideFor=hideForRoles || [];    
    this.store.pipe(select(fromRoleSetup.getPermission)).subscribe(roles => {
      if(roles){
        roles.filter((el,index)=>{
          if(this.loggenInUserId.id==el.user){
            this.userPermission=el.permission;
          }            
        })
        if(hideFor.length>0){          
          this.roleChecker(hideFor,this.userPermission);
        }else{
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      }    
    });
  }

  roleChecker(hideFor:Array<string>,permissions:any){
    const userRoles:Array<string>=permissions;
    console.log("userRolesPermitted",userRoles,hideFor)
    if(userRoles.length === 0){
      this.viewContainerRef.clear();
    }else{
      const indx =userRoles.findIndex(role => hideFor.indexOf(role) != -1);
      if(indx <0 ){
        console.log(indx,"<=")
        return this.viewContainerRef.clear()
      }else{
        //console.log(indx,"not")
        return this.viewContainerRef.createEmbeddedView(this.templateRef)
      }
    }
  }

}
