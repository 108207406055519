import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent implements ICellRendererAngularComp {

  constructor() { }
  params:any
  refresh(params: ICellRendererParams): boolean {
    return false;
  }
  agInit(params:any):void {
    this.params=params;
  }

  ngOnInit(): void {
  }

}
