<form class="createOrderForm" (ngSubmit)="createOrderAction(formDirective)" [formGroup]="createForm" #formDirective="ngForm" autocomplete="off">
    <mat-dialog-content class="mat-typography">
        <h5 class="successHeader" *ngIf='successHeader' [@fade]="'out'">User Created Successfully</h5>
        <h2 mat-dialog-title style="text-align: center;">New Order</h2>

        <!-- <div class="example-container">
      <mat-form-field appearance="fill">
        <mat-label>Tracking ID/AWB No.</mat-label>
        <input matInput placeholder="Enter Tracking ID/AWB No" formControlName="AWBnumber" required>
        <mat-error *ngIf="createForm.controls['AWBnumber'].hasError('required')">
          This is <strong>required</strong> field
        </mat-error>
        <mat-error *ngIf="createForm.controls['AWBnumber'].hasError('pattern')">
          Only Alphbates can be accepted
        </mat-error> 
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Courier Company</mat-label>
        <input matInput placeholder="Enter Courier company name" formControlName="CourierComp" required>
        <mat-error *ngIf="createForm.controls['CourierComp'].hasError('required')">
          This is <strong>required</strong> field
        </mat-error>
        <mat-error *ngIf="createForm.controls['CourierComp'].hasError('pattern')">
          Only Alphbates can be accepted
        </mat-error> 
      </mat-form-field>      
      <mat-form-field class="example-full-width">
        <mat-label>Courier Mode</mat-label>
        <input type="text"
               placeholder="Pick one"
               aria-label="Courier Mode"
               matInput
               formControlName="courierMode"
               [matAutocomplete]="courierMode" required>
        <mat-autocomplete #courierMode="matAutocomplete">
          <mat-option *ngFor="let mode of courierModeList" [value]="mode">
            {{mode}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
 
    </div> -->
        <div class="example-container">
            <mat-form-field appearance="legacy">
                <mat-label>Contact Number</mat-label>
                <input type='tel' matInput placeholder="Enter your mobile" formControlName="mobile" (focusout)="onFocusOutEvenMobile($event)" required>
                <mat-error *ngIf="createForm.controls['mobile'].hasError('required')">
                    This is <strong>required</strong> field
                </mat-error>
                <mat-error *ngIf="createForm.controls['mobile'].hasError('maxlength')">
                    Mobile contains more than <strong>10</strong> digits
                </mat-error>
                <mat-error *ngIf="createForm.controls['mobile'].hasError('minlength')">
                    Mobile contains less than <strong>10</strong> digits
                </mat-error>
                <mat-error *ngIf="createForm.controls['mobile'].hasError('pattern')">
                    Only Digits can be accepted
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="legacy">
                <mat-label>Alt Contact Number</mat-label>
                <input type='tel' matInput placeholder="Enter your mobile" formControlName="altmobile" (focusout)="onFocusOutEventAltMobile($event)" required>
                <mat-error *ngIf="createForm.controls['altmobile'].hasError('required')">
                    This is <strong>required</strong> field
                </mat-error>
                <mat-error *ngIf="createForm.controls['altmobile'].hasError('maxlength')">
                    Mobile contains more than <strong>10</strong> digits
                </mat-error>
                <mat-error *ngIf="createForm.controls['altmobile'].hasError('minlength')">
                    Mobile contains less than <strong>10</strong> digits
                </mat-error>
                <mat-error *ngIf="createForm.controls['altmobile'].hasError('pattern')">
                    Only Digits can be accepted
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="legacy">
                <mat-label>Customer Name</mat-label>
                <input matInput placeholder="Enter customer name" formControlName="cname" required>
                <mat-error *ngIf="createForm.controls['cname'].hasError('required')">
                    This is <strong>required</strong> field
                </mat-error>
                <mat-error *ngIf="createForm.controls['cname'].hasError('pattern')">
                    Only Alphbates can be accepted
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Product</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Product" matInput formControlName="product" [matAutocomplete]="product" required>
                <mat-autocomplete #product="matAutocomplete" [displayWith]="getProductTitle.bind(this)">
                    <mat-option *ngFor="let product of productList index by id" [value]="product.id">
                        {{product.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="example-container">
            <mat-form-field class="example-full-width course" appearance="legacy">
                <mat-label>Course</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Course" matInput formControlName="course" [matAutocomplete]="course" required>
                <mat-autocomplete #course="matAutocomplete" (optionSelected)="onSelectionChange($event)" [displayWith]="getCourseTitle.bind(this)">
                    <mat-option *ngFor="let course of CourseList index by id" [value]="course.id">
                        {{course.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="example-full-width price" appearance="legacy">
                <mat-label>Base Price</mat-label>
                <input type="number" placeholder="Pick one" aria-label="Base Price" matInput formControlName="bprice" required readonly>
            </mat-form-field>
            <mat-form-field class="example-full-width price" appearance="legacy">
                <mat-label>Offered Price</mat-label>
                <input type="tel" placeholder="Pick one" aria-label="price" matInput formControlName="price" (focusin)="onFocusInEvenPrice($event)" (focusout)="onFocusOutEvenPrice($event)" required>
                <mat-error *ngIf="createForm.controls['price'].hasError('maxlength')">
                    Price can only be <strong>4</strong> digits Long
                </mat-error>
                <mat-error *ngIf="createForm.controls['price'].hasError('pattern')">
                    Only Digits can be accepted
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width pincode" appearance="legacy">
                <mat-label>Pin Code</mat-label>
                <input type="tel" placeholder="Enter Pin Code" aria-label="pincode" matInput formControlName="pincode" (keyup)='GetPinCodeDetails($event)' required>
                <mat-error *ngIf="createForm.controls['pincode'].hasError('maxlength')">
                    more than <strong>6</strong> digits
                </mat-error>
                <mat-error *ngIf="createForm.controls['pincode'].hasError('minlength')">
                    less than <strong>6</strong> digits
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Post Office</mat-label>
                <input type="text" placeholder="Pick one" aria-label="postOffice" matInput formControlName="postOffice" [matAutocomplete]="postOffice" required>
                <mat-autocomplete #postOffice="matAutocomplete">
                    <mat-option *ngFor="let postOffice of postOfficeList" [value]="postOffice.Name">
                        {{postOffice.Name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="example-full-width state" appearance="legacy">
                <mat-label>State</mat-label>
                <input type="text" placeholder="Pick one" aria-label="state" matInput formControlName="state" [matAutocomplete]="state" required>
                <mat-autocomplete #state="matAutocomplete">
                    <mat-option *ngFor="let state of stateList" [value]="state">
                        {{state}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="example-container">
            <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>City</mat-label>
                <input type="text" placeholder="Pick one" aria-label="city" matInput formControlName="city" [matAutocomplete]="city" required>
                <mat-autocomplete #city="matAutocomplete">
                    <mat-option *ngFor="let city of cityList" [value]="city">
                        {{city}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="example-full-width landmark" appearance="legacy">
                <mat-label>Landmark</mat-label>
                <input matInput #landmark maxlength="256" placeholder="Landmark" formControlName="landmark" required>
                <mat-hint align="start"><strong>Landmark should be around 100 to 500 meters</strong> </mat-hint>
                <mat-hint align="end">{{landmark.value.length}} / 256</mat-hint>
            </mat-form-field>
            <mat-form-field class="example-full-width remark" appearance="legacy">
                <mat-label>Remark</mat-label>
                <input matInput #message maxlength="256" placeholder="Remark" formControlName='remark' required>
                <mat-hint align="start"><strong>Comment about Order/Customer update </strong> </mat-hint>
                <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
            </mat-form-field>
        </div>
        <div class="example-container">
            <mat-form-field class="example-full-width address" appearance="legacy">
                <mat-label>Address</mat-label>
                <textarea matInput placeholder="Complete Address" formControlName='address' required></textarea>
            </mat-form-field>
        </div>
        <mat-dialog-actions align="end">
            <button mat-raised-button mat-dialog-close>Cancel</button>
            <button mat-raised-button color="accent" type="reset">Reset</button>
            <button mat-raised-button color="primary" type="submit" [disabled]="!createForm.valid">Create</button>
        </mat-dialog-actions>
    </mat-dialog-content>
</form>
<style>
    .example-container .mat-form-field+.mat-form-field {
        margin-left: 8px;
    }
    
    .example-container {
        text-align: center;
        padding: 5px;
    }
    
    .successHeader {
        top: 0;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        text-align: center;
        background: #0080009e;
        color: #fff;
    }
</style>
<app-waiting-image *ngIf="waitingImage"></app-waiting-image>