import { Injectable } from '@angular/core';
import { LeastOrderConfirmationAgentService } from './least-order-confirmation-agent.service';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as LeastConfirmationAgentAction from './least-order-confirmation-agent.actions'



@Injectable()
export class LeastOrderConfirmationAgentEffects {
  constructor(private actions$: Actions,private _leastConfirmationAgentService:LeastOrderConfirmationAgentService) {}
  loadLeastConfirmationAgentList=createEffect(()=> {
    return this.actions$.pipe(
     ofType(LeastConfirmationAgentAction.LeastOrderConfirmationAgentActionTypes.LoadLeastOrderConfirmationAgents),
     mergeMap(
       action => this._leastConfirmationAgentService.getLeastOrderConfirmationAgent().pipe(
         map((agentList) => new LeastConfirmationAgentAction.LoadLeastOrderConfirmationAgentsSuccess({data:agentList})),
         catchError(err=> of(new LeastConfirmationAgentAction.LoadLeastOrderConfirmationAgentsFailure({error:err})))
       )
     )
   )
 })

}
