import { Action } from '@ngrx/store';
import { orders } from "./orders";

export enum OrderActionTypes {
  LoadOrders = '[Order] Load Orders',
  LoadOrdersSuccess = '[Order] Load Orders Success',
  LoadOrdersFailure = '[Order] Load Orders Failure',
  CreateOrder = '[Order] Create Order',
  EditOrder = '[Order] Edit Order',
  ReOrder='[Order] ReOrder',
  EditOrderSuccess = '[Order] Edit Order Success',
  EditOrderFailure = '[Order] Edit Order Failure',
  OpenEditableOrder='[Order] Editable Order',
  loadFilterOrders='[Order] filter Order'
}

export class LoadOrders implements Action {
  readonly type = OrderActionTypes.LoadOrders;
}

export class LoadOrdersSuccess implements Action {
  readonly type = OrderActionTypes.LoadOrdersSuccess;
  constructor(public payload: { data: orders[] }) { }
}

export class LoadOrdersFailure implements Action {
  readonly type = OrderActionTypes.LoadOrdersFailure;
  constructor(public payload: { error: "" }) { }
}
export class CreateOrder implements Action {  
  readonly type = OrderActionTypes.CreateOrder;
  constructor(public payload:{data:any}){ }
}
export class EditOrder implements Action{
  readonly type= OrderActionTypes.EditOrder;
  constructor(public payload:{id:any}){}
}
export class ReOrder implements Action{
  readonly type= OrderActionTypes.ReOrder;
  constructor(public payload:{id:any}){}
}
export class EditOrderSuccess implements Action{
  readonly type= OrderActionTypes.EditOrderSuccess;
  constructor(public payload:{data:orders[]}){}
}
export class EditOrderFailure implements Action{
  readonly type=OrderActionTypes.EditOrderFailure;
  constructor(public payload:{error:""}){}
}
export class OpenEditableOrder implements Action{
  readonly type=OrderActionTypes.OpenEditableOrder;
  constructor(public payload:{data:orders[]}){}
}
export class loadFilterOrders implements Action{
  readonly type=OrderActionTypes.loadFilterOrders;
  constructor(public payload: any) { }
}


export type OrderActions = LoadOrders | LoadOrdersSuccess | LoadOrdersFailure | CreateOrder | EditOrder | EditOrderSuccess| EditOrderFailure |
OpenEditableOrder | ReOrder | loadFilterOrders;

