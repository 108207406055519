<section class="quickViewSection">
  <div class="info-box-content">
    <span class="info-box-text">Recived</span>
    <span class="info-box-number">
      {{QuickViewData && QuickViewData[0].totalRecived}}
    <small></small>
    </span>
  </div>
  <div class="info-box-content">
    <span class="info-box-text">Confirm</span>
    <span class="info-box-number">{{QuickViewData && QuickViewData[0].totalConfirm}}</span>
  </div>
  <div class="info-box-content">
    <span class="info-box-text">Final</span>
    <span class="info-box-number">{{QuickViewData && QuickViewData[0].totalFinal}}</span>
  </div>
  <div class="info-box-content">
    <span class="info-box-text">Shipped</span>
    <span class="info-box-number">{{QuickViewData && QuickViewData[0].totalDelivered}}</span>
  </div>
  <div class="info-box-content">
    <span class="info-box-text">ODA</span>
    <span class="info-box-number">{{QuickViewData && QuickViewData[0].totalDelivered}}</span>
  </div>
  <div class="info-box-content">
    <span class="info-box-text">Delivered</span>
    <span class="info-box-number">{{QuickViewData && QuickViewData[0].totalDelivered}}</span>
  </div>
  <div class="info-box-content">
    <span class="info-box-text">Undelivered</span>
    <span class="info-box-number">{{QuickViewData && QuickViewData[0].totalDelivered}}</span>
  </div>
  <div class="info-box-content">
    <span class="info-box-text">Unread</span>
    <span class="info-box-number">{{QuickViewData && QuickViewData[0].totalUnread}}</span>
  </div>
  <div class="info-box-content">
    <span class="info-box-text">Complete</span>
    <span class="info-box-number">{{QuickViewData && QuickViewData[0].totalComplete}}</span>
  </div>
</section>