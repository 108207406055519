import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _loginService:LoginService,private _router:Router){}
  canActivate( 
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this._loginService.user.pipe(
      map((user) => {
        if (localStorage.getItem("loggedIn")){         
            if(state.url === "/"){
              return this._router.createUrlTree(['Dashboard']);
            }                 
            return true;    
        }
        return this._router.createUrlTree(['login']);      
      })
    );      
  }
  
}
