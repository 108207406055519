import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { OrderStatusActionButtonComponent } from '../order-status-action-button/order-status-action-button.component';
import * as orderStatuList from "./order-status.actions"
import * as fromOrderStatus from "./order-status.selectors";
import { OrderStatusService } from './order-status.service';
import { OrderStatus } from './orderStatus';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.css'],
  animations: [
    trigger('widthGrow', [
        state('closed', style({
          height:  0
        })),
        state('open', style({
          height: '80px'
        })),
        transition('* => *', animate(80))
    ]),
]
})
export class OrderStatusComponent implements OnInit {
  pageTtitle:string='Order Status'
  statusList!:OrderStatus[]
  sideBar:boolean = false;
  paginationPageSize:number=19;
  state:string= "closed";
  CreateOrderSectionSwitch:boolean=false
  addOrderStatus!:FormGroup;
  StatusCategoryList=['Order','Tracking']
  defaultColDef = {
    editable:true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:50,
    sideBar:true
  };
  
  columnDefs = [
    {headerName: 'Id', field: 'id',hide:false},
    {headerName: 'Title', field: 'name',hide:false},
    {headerName: 'Status', field: 'category',hide:false},
    // {headerName: 'Action',cellRendererFramework:OrderStatusActionButtonComponent,checkboxselection:true,/*headerCheckboxSelection: true*/}
  ]

  constructor(private store:Store,private _orderStatusService:OrderStatusService) { 
    this.addOrderStatus=new FormGroup({
      StatusName:new FormControl('',[Validators.required]),
      StatusCategory: new FormControl('',[Validators.required])
    })
  }

  ngOnInit(): void {
    this.store.dispatch(new orderStatuList.LoadOrderStatuss)
    this.store.pipe(select(fromOrderStatus.getorderStatus)).subscribe(status=> this.statusList=status);
  }
  onResize(event:any){

  }
  onFilterTextBoxChanged(){

  }
  AddStatus(){
    console.log(this.state);
    (this.state === "closed") ? this.state = "open" : this.state = "closed";
    console.log(this.state);
    this.CreateOrderSectionSwitch=!this.CreateOrderSectionSwitch    
  }
  submitOrderStatus(formDirective:FormGroupDirective){
    console.log(formDirective.value);
    this._orderStatusService.createOrderStatus(formDirective.value).subscribe((payload)=>{
      console.log("payload=",payload)
    })
  }

}
