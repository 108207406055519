import { Time } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { catchError, map, tap } from 'rxjs/operators';
import { IUser } from 'src/app/user';
import { baseUrl } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { employee } from '../employee/employee';
import { pendingUser } from './pendingUser';

interface newEmploye{
  fname:string,
  lname:string,
  fatherName:string,
  department:string,
  category:string,
  bsalary:string,
  mobile:string,
  idproof:string,
  profilePic:string,
  date:Date,
  time:Time
}

interface CrendentialForLoginSetup{
  id:number,
  username:string,
  password:string
}

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor( private _http:HttpClient) { }

  private _refreshNeeded$= new Subject<void>();


  handleError(errorResponse: HttpErrorResponse) {
    const error= errorResponse.error;
    const keys=Object.keys(error);
    const key = keys[0];
    let message = error[key];
    console.log(keys);
    if(errorResponse.status == 0){
      // auth token delete
      //redirect login page
      message="no Server"
    }
    if(error[key] instanceof Array){
      message = error[key][0];
    }
    if(key === "isTrusted"){
      Swal.fire({
        position: 'bottom-end',
        icon: 'error',
        title:'Server not started!',
        showConfirmButton: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      })
    }else{
      message = key+ ':'+message;
    }

    return throwError({messages:message,error})

    // let errorMessage = 'Unknown error!';
    // console.log(error,'error');
    // if (error.error instanceof ErrorEvent) {
    //   // Client-side errors
    //   errorMessage = `Error: ${error.error.message}`;
    // } else {
    //   // Server-side errors
    //   errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    // }
    // window.alert(errorMessage);
    // return throwError(errorMessage);
  }

  get refreshNeeded(){
    return this._refreshNeeded$;
  }

  fetchUsers():Observable<IUser[]>{
    return this._http.get<IUser[]>(baseUrl+'/getUser.php?5wud').pipe(tap(()=>{this._refreshNeeded$.next()}),catchError(this.handleError.bind(this)));
  }
  fetchEmployee():Observable<employee[]>{
    return this._http.get<employee[]>(baseUrl+'/getEmployee.php').pipe(tap(()=>{this._refreshNeeded$.next()}),catchError(this.handleError.bind(this)));
  }
  createUser(user:newEmploye):Observable<any>{     
    console.log(user,"service");
    return this._http.post<any>(baseUrl+'/createUser.php?p1',user).pipe(catchError(this.handleError.bind(this)))
  }
  pendingUsers():Observable<pendingUser[]>{
    return this._http.get<pendingUser[]>(baseUrl+'/loginSetupPending.php?ggq').pipe(tap(()=>{this._refreshNeeded$.next()}),catchError(this.handleError.bind(this)))
  }
  loginSetupOfPendingUser(crendetial:CrendentialForLoginSetup){
    return this._http.post<any>(baseUrl+'/loginSetup.php?pd1',crendetial).pipe(catchError(this.handleError.bind(this)))
  }
  deleteEmployee(id:any){
    const params={params:{id:id}}
    return this._http.get(baseUrl+'/deleteEmployee.php?p',params).pipe(catchError(this.handleError.bind(this)));
  }
  disableUser(id:number){
    return this._http.post<any>(baseUrl+'/disableUser.php?df43g1',id).pipe(catchError(this.handleError.bind(this)));
  }
  passwordReset(id:number){
    const temp={tempP:Math.random().toString(36).slice(-8),id}
    return this._http.post<any>(baseUrl+'/resetPass.php?pted',id).pipe(catchError(this.handleError.bind(this)));
  }
  deactivateEmployee(id:number){
    console.log("id=",id);
    return this._http.post<any>(baseUrl+'/deactivateEmployee.php?WsUd',id).pipe(catchError(this.handleError.bind(this)));
  }
  getDesignation(){
    return this._http.get<any>(baseUrl+'/getDesignation.php').pipe(catchError(this.handleError.bind(this)));
  }
  createPermission(permissionData:any):Observable<any>{
    return this._http.post<any>(baseUrl+'/addPermission.php',permissionData).pipe(catchError(this.handleError.bind(this)));
  }
  loginHistory(user:any):Observable<any>{
    const params={params:{user:user}}
    return this._http.get<any>(baseUrl+'/loginHistroy.php?p',params).pipe(catchError(this.handleError.bind(this)));
  }
  fetchLatestMembers(){
    return this._http.get(baseUrl+'/getLatestMember.php').pipe(catchError(this.handleError.bind(this)));
  }
}
