import { Action } from '@ngrx/store';
import { sample } from 'rxjs/operators';
import { IUser } from './user';
import { UserActions, UserActionTypes } from './user.actions';


export const userFeatureKey = 'userState';
export const postReducers='reducer';

export interface State {
  users: IUser[],
  error: string

}

export const initialState: State = {
  users: [],
  error: ''
};

export function reducer(state:State = initialState, action: UserActions): State {
  switch (action.type) {

    case UserActionTypes.LoadUsers:
      return {
        ...state
      }

    case UserActionTypes.LoadUsersSuccess:
      return {
        ...state,
        users: action.payload.data,
        error: ""
      }

    case UserActionTypes.LoadUsersFailure:
      return {
        ...state,
        users: [],
        error: action.payload.error
      }
    case UserActionTypes.UserDisable:
      return {
        ...state,
        users:action.payload.data,
        error:""
      }
    case UserActionTypes.createUser:
      return {
        ...state,
        error:action.payload.data
      }
    case UserActionTypes.deleteUser:
    var StateUsers=state.users.filter(user=> user.id !== action.payload.id);
    return {
        ...state,
        users:StateUsers
      }
    case UserActionTypes.LoginSetup:
    var StateUsers=state.users.filter(user=>user.id!== action.payload.id);
    return {
      ...state,
      users:StateUsers
    }
    default:
      return state;
  }
}
