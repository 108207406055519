import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { Course } from "./course";
import { product } from "../product/product";
import { select, Store } from "@ngrx/store";
import * as CourseAction from "./course.actions"
import * as fromCourse from './course.selectors';
import { CourseService } from './course.service';
import { WaitingImageComponent } from "../waiting-image/waiting-image.component";

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css'],
  animations: [
    trigger('widthGrow', [
        state('closed', style({
          height:  0,
        })),
        state('open', style({
          height: '80px'
        })),
        transition('* => *', animate(80))
    ]),
]
})
export class CourseComponent implements OnInit {
  pageTitle:string='Course'
  state = "closed";
  waitingImage:boolean=false
  CourseSectionSwitch:boolean=false
  addCourseForm!:FormGroup
  filterRowShow:boolean=false;
  CourseCategoryList=['1 Month','2 Month','3 Month','4 Month','5 Month','6 Month']
  rowData!:Course[];
  paginationPageSize:number=19;
  defaultColDef = {
    editable:true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:100,
    sideBar:true
  };

  columnDefs=[
    {headerName: 'Id', field: 'id',hide:false},
    {headerName: 'Name', field: 'name',hide:false},
    {headerName: 'Price', field: 'price',hide:false},
    {headerName: 'Category', field: 'category',hide:false},
    {headerName: 'Created @', field: 'dateOfCreation',hide:false},
    {headerName: 'Time', field: 'time',hide:false},
    {headerName: 'Month', field: 'month',hide:true},
    {headerName: 'Year', field: 'year',hide:true},

  ]
  
  @ViewChild('CourseList')
  agGrid!:AgGridAngular

  @ViewChild('filterTextBox',{read:ElementRef})
  filterBox!:ElementRef

  constructor(private store:Store,private _course:CourseService) { 
    this.addCourseForm=new FormGroup({
      CourseName: new FormControl('',[Validators.required]),
      // CourseProduct: new FormControl('',[Validators.required]),
      CourseCategory: new FormControl('',[Validators.required]),
      CoursePrice: new FormControl('',[Validators.required])
    })
  }

  private getCourse(){
    this.store.dispatch(new CourseAction.LoadCourses)
    this.store.pipe(select(fromCourse.getCourse)).subscribe(course=>this.rowData=course)   
  }
  ngOnInit(): void {
    this.getCourse()
  }
  AddCourse(){
    (this.state == "closed") ? this.state = "open" : this.state = "closed";
    this.CourseSectionSwitch=!this.CourseSectionSwitch    
  }
  LoadCourse(){
    this.getCourse()
  }
  refresh(){
    this.getCourse()
  }
  showFilter(){}
  submitCourse(formDirective:FormGroupDirective){
    //console.log(formDirective.valid,formDirective.value,this.addCourseForm.valid,this.addCourseForm.value)
    this.waitingImage=true;
    if(formDirective.valid){
      this._course.addNewCourse(this.addCourseForm.value).subscribe(payload=>{
        console.warn("payload",payload)
        if(payload){
          formDirective.resetForm()
          this.getCourse();
        }
          
      });
    }
    
  }
  onResize(event:any){}
  onFilterTextBoxChanged(){
    //console.log("df",this.filterBox.nativeElement.value);
    this.agGrid.api.setQuickFilter(this.filterBox.nativeElement.value)
  }

}
