import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.css']
})
export class UserCardComponent implements OnInit {

  constructor(private _router:Router,private _authService:AuthService) { }
  showCard:boolean=false;
  userLoggedInData:any;
  ngOnInit(): void {
    const local1:any=localStorage.getItem('user');
    this.userLoggedInData=JSON.parse(local1);
  }    
  OpenUserCard(){
    this.showCard=!this.showCard;
  }
  Logout(){
    this._authService.setLogout(this.userLoggedInData.id).subscribe(payload=>{
      if(payload){
        localStorage.clear();
        window.location.href="http://cosmicpro.in/";
      }
    });
    
  }

}
