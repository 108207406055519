import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from "@ngrx/store";
import { Observable, of } from 'rxjs';
import { UserService } from "../../dashboard/users/user.service";
import * as EmployeeActions from "./employee.actions";
import { mergeMap,map,catchError } from "rxjs/operators";
import { employee } from "./employee";

@Injectable()
export class EmployeeEffects {

  constructor(private actions$: Actions,private _userServices:UserService) {}
  //loadUsers$:Observable<Action> =
  loadEmployee=createEffect(()=> {
   return this.actions$.pipe(
    ofType(EmployeeActions.EmployeeActionTypes.LoadEmployees),
    mergeMap(
      action => this._userServices.fetchEmployee().pipe(
        map((employee) => new EmployeeActions.LoadEmployeesSuccess({data:employee })),
        catchError(err=> of(new EmployeeActions.LoadEmployeesFailure({error:err})))
      )
    )
  )
})
}

