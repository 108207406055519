import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { RowDataChangedEvent } from 'ag-grid-community';
import { ProductService } from "./product.service";
import * as fromProduct from "./product.selectors";
import * as ProductActions from "./product.actions"
import { LoadingBarComponent } from '../loading-bar/loading-bar.component';
import { LodingBarServiceService } from '../loading-bar/loding-bar-service.service';
import { product } from './product';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AgGridAngular } from 'ag-grid-angular';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
  animations: [
      trigger('widthGrow', [
          state('closed', style({
            height:  0,
          })),
          state('open', style({
            height: '80px'
          })),
          transition('* => *', animate(80))
      ]),
  ]
})
export class ProductComponent implements OnInit {
  pageTtitle:string='Products'
  sideBar:boolean=false;
  rowData!:product[];
  paginationPageSize:number=19;
  addProductForm!:FormGroup
  productCategoryList=['Energy Booster','Oil']
  waitingImage: boolean=false;
  ProductSectionSwitch:boolean=false;
  state = "closed";
  filterRowShow:boolean=false

  @ViewChild('productList')
  agGrid!:AgGridAngular

  @ViewChild('filterTextBox',{read:ElementRef})
  filterBox!:ElementRef

  constructor(private _productService:ProductService,private store:Store, private lbs:LodingBarServiceService) { 
    this.addProductForm = new FormGroup({
      productName: new FormControl('',[Validators.required]),
      productCat: new FormControl('',[Validators.required])
    })
  }

  ngOnInit(): void {
    this.getProducts()
  }
  refresh(){
    this.getProducts()
  }
  defaultColDef = {
    editable:true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:100,
    sideBar:true
  };
  filterParams={
    comparator:(filterLocalDateAtMidnight:any,cellValue:any)=>{
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split('-');
      console.log(dateParts,"HJ");
      var cellDate = new Date(
        Number(dateParts[0]),
        Number(dateParts[1])-1, 
        Number(dateParts[2])        
      )  
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {return 0;}
      if (cellDate < filterLocalDateAtMidnight) {return -1;}
      if (cellDate > filterLocalDateAtMidnight) {return 1;}
      return 0
    },
    browserDatePicker: true,
  }
  
  columnDefs = [
    {headerName: 'Id', field: 'id',rowDrag:true,hide:false},
    {headerName: 'Name', field: 'name',hide:false},
    {headerName: 'Category', field: 'category',hide:false},
    {headerName: 'Status', field: 'status',hide:false},
    {headerName: 'Created @', field: 'dateOfCreation',hide:false,filter: 'agDateColumnFilter',filterParams:this.filterParams},
    {headerName: 'Time', field: 'time',hide:false},
    {headerName: 'Month', field: 'month',hide:false},
    {headerName: 'Year', field: 'year',hide:false},
  ]
  onResize(event:any){

  }

  private getProducts(){
    this.store.dispatch(new ProductActions.LoadProducts) //Action dispatch
    this.store.pipe(select(fromProduct.getProduct)).subscribe(
      products =>{
        this.rowData=products
        this.lbs.clear();
      }
    )    
  }
  
  AddProduct(){    
    (this.state == "closed") ? this.state = "open" : this.state = "closed"; 
      this.ProductSectionSwitch=!this.ProductSectionSwitch    
  }
  showFilter(){
    this.filterRowShow=!this.filterRowShow;
  }
  LoadProducts(){

  }
  onFilterTextBoxChanged(){
    //console.log("df",this.filterBox.nativeElement.value);
    this.agGrid.api.setQuickFilter(this.filterBox.nativeElement.value)
  }
  submitProduct(formDirective:FormGroupDirective){
    console.log("df");
    this.waitingImage=true;
    if(this.addProductForm.valid){
      console.log(this.addProductForm)
      this._productService.addNewProduct(this.addProductForm.value).subscribe((payload)=>{
        if(payload){
          formDirective.resetForm();
          this.refresh()
        }
      })
    }
  }

}
