import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { baseUrl } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LoginAudit } from './login-audit';

@Injectable({
  providedIn: 'root'
})
export class LoginAuditService {

  constructor(private _http:HttpClient) { }
  handleError(errorResponse: HttpErrorResponse) {
    const error= errorResponse.error;
    const keys=Object.keys(error);
    const key = keys[0];
    let message = error[key];
    console.log(keys);
    if(errorResponse.status == 0){
      // auth token delete
      //redirect login page
      message="no Server"
    }
    if(error[key] instanceof Array){
      message = error[key][0];
    }
    if(key === "isTrusted"){
      Swal.fire({
        position: 'bottom-end',
        icon: 'error',
        title:'Server not started!',
        showConfirmButton: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      })
    }else{
      message = key+ ':'+message;
    }

    return throwError({messages:message,error})
  }
  fetchLoginAuditDetails(userID:any):Observable<LoginAudit[]>{
    const params={params:{userID:userID}}
    return this._http.get<LoginAudit[]>(baseUrl+'/getLoginAuditDetails.php',params).pipe(catchError(this.handleError.bind(this)));
  }
}
