import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import { select, Store } from '@ngrx/store';
import { dashboardPage } from '../dashboard-page/dashboardPage';
import * as DashboardPageActions from "../dashboard-page/dashboard-page.actions"
import * as fromdashboardPage from '../dashboard-page/dashboard-page.selectors'
import { LodingBarServiceService } from '../loading-bar/loding-bar-service.service';
import { OrderService } from '../orders/order.service';
import { Orders } from 'src/app/models/orders.model';
import { LoadingBarComponent } from '../loading-bar/loading-bar.component';
import { OrderActionButtonComponent } from '../order-action-button/order-action-button.component';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { ShippingDetailsComponent } from '../shipping-details/shipping-details.component';
import { AgGridAngular, AngularFrameworkComponentWrapper, ICellRendererAngularComp, } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { OrdersComponent } from '../orders/orders.component';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  HeaderTitle:string='Cosmic 2.0';
  pageTtitle:string='dashboard';
  recievedOrders:number=0;
  confirmOrders:number=0;
  finalOrders:number=0;
  newEmploye:number=0;
  isQuickViewOpened:boolean=false;
  isQuickViewSearchOrderOpened: boolean=false;



  constructor(private _ElementRef:ElementRef,private _QuickView:MatBottomSheet,private _QuickViewRef: MatBottomSheetRef<QuickViewReport>,private _orderService:OrderService) { }  
   
    ngOnInit(): void {
    }

  openBottomSheet() {
    if(!this.isQuickViewOpened){
      let guy=this._QuickView.open(QuickViewReport);
      this.isQuickViewOpened=!this.isQuickViewOpened;
      guy.afterDismissed().subscribe(result => {
        this.isQuickViewOpened=!this.isQuickViewOpened;
      })
    }         
  } 
  searchOrder(){
    if(!this.isQuickViewSearchOrderOpened){
      let searchOrder=this._QuickView.open(QuickViewSearchOrder,{data:this._ElementRef.nativeElement.querySelector('#searchOrder').value});
      this.isQuickViewSearchOrderOpened=!this.isQuickViewSearchOrderOpened
      searchOrder.afterDismissed().subscribe(result => {
        this.isQuickViewSearchOrderOpened=!this.isQuickViewSearchOrderOpened;
      })
    }        
  }
  
}

@Component({
  selector: 'QuickViewReport',
  templateUrl: 'QuickViewReport.html',
  styleUrls: ['./header.component.css']
})
export class QuickViewReport{

  QuickViewData!: dashboardPage[];

  constructor(private _QuickViewRef: MatBottomSheetRef<QuickViewReport>,private lbs:LodingBarServiceService,private store:Store) {}

  openLink(event: MouseEvent): void {
    this._QuickViewRef.dismiss();
    event.preventDefault();
  }

 ngOnInit(){
  this.store.dispatch(new DashboardPageActions.LoadDashboardPages)
    this.store.pipe(select(fromdashboardPage.getDashboardPage)).subscribe((payload:dashboardPage[])=>{
      if(payload.length){     
        this.QuickViewData=payload;
        this.lbs.clear();
      }      
    })
 }
 
}

@Component({
  selector: 'QuickViewSearchOrder',
  templateUrl: 'quickViewSearchOrders.html',
  styleUrls: ['./header.component.css']
})
export class QuickViewSearchOrder {
  rowData!:Orders[];
  paginationPageSize:number=3;
  pagination:boolean=true;
  sideBar:boolean=false
  public rowSelection = 'multiple';
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,private _orderActionButtonCom:OrderActionButtonComponent,private _ElementRef:ElementRef, private _orderService:OrderService,private lbs:LodingBarServiceService){
    // console.log(this._orderActionButtonCom.reorderFlag,"before");
    // this._orderActionButtonCom.reorderFlag=true;
    // console.log(this._orderActionButtonCom.reorderFlag,"After");
  }  
  defaultColDef = {
    editable:true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:100,
    sideBar:true,
    columnHoverHighlight: true
  };
  onResize(e:any){}
  onRowSelected(e:any){}
  filterParams={
    comparator:(filterLocalDateAtMidnight:any,cellValue:any)=>{
      //console.log(filterLocalDateAtMidnight,cellValue);
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split('-');
      console.log(dateParts,"HJ");
      var cellDate = new Date(
        Number(dateParts[0]),
        Number(dateParts[1])-1, 
        Number(dateParts[2])        
      )
      //console.log(cellDate,"DF",Number(dateParts[1])-1,Number(dateParts[2]),Number(dateParts[0]), new Date(2021,6,15));
  
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
  
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
  
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0
    },
    browserDatePicker: true,
  } 
  columnDefs = [
    {headerName: 'Action',cellRendererFramework:OrderActionButtonComponent,checkboxselection:true,headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true, resizable: true,minWidth:150},
    {headerName: 'Id', field: 'id',cellRendererFramework:TooltipComponent,hide:false,width:50},
    {headerName: 'Time', field: 'orderTime',hide:false,cellRenderer:(date:any)=>{
      //console.log(date.value.split(':'));
      const dateArray=date.value.split(':');
      var hours= dateArray[0]<=12?dateArray[0]:dateArray[0]-12;
      hours=hours<10?'0'+hours:hours;
      var minutes= dateArray[1];
      minutes=minutes<10?'0'+minutes:minutes;
      var seconds= dateArray[2];
      var ampm = dateArray[0]>=12?'PM':'AM'
      return hours+':'+minutes+'&nbsp;'+ampm;
    }},
    {headerName: 'Date', field: 'orderDate',cellRendererFramework:TooltipComponent,hide:false,filter: 'agDateColumnFilter',filterParams:this.filterParams},
    {headerName: 'Remark', field: 'remark',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Agent', field: 'agentName',cellRendererFramework:TooltipComponent,hide:false},
    
    //{headerName: 'Super Agent', field: 'superAgent',cellRendererFramework:TooltipComponent,hide:false},
    // {headerName: 'confirmedBy', field: 'confirmedBy',cellRendererFramework:TooltipComponent,hide:false},
    // {headerName: 'Product', field: 'ProductName',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Status', field: 'ostatus',cellRendererFramework:TooltipComponent,hide:false},
    // {headerName: 'Category', field: 'orderCategory',cellRendererFramework:TooltipComponent,hide:false},
    // {headerName: 'Courier Status', field: 'courierStatus',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Shipping Details', cellRendererFramework:ShippingDetailsComponent,hide:false,minWidth:150},
    // {headerName: 'Shift', field: 'orderShift',cellRendererFramework:TooltipComponent,hide:false},
    // {headerName: 'Mobile', field: 'customerMobile',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Name', field: 'customerName',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'customerMobile', field: 'Mobile',cellRendererFramework:TooltipComponent,hide:false},
    // {headerName: 'B.Price', field: 'BasePrice',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'C.Price', field: 'confirmedPrice',cellRendererFramework:TooltipComponent,hide:false}
  ]
  public rowHeight = 70;
  ngOnInit(){
    this.lbs.showBar(true);
    this._orderService.searchOrders(this.data).subscribe(payload=>{
      this.rowData=payload
      this.lbs.clear();
    })
  }

  
}
