import { Component, OnInit,ElementRef,ViewChild,OnDestroy, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { baseUrl } from "../../environments/environment";
import { FormGroup,FormControl,NgForm,Validators,ReactiveFormsModule } from "@angular/forms";
import { LoginService } from "./login.service";
import { analyzeNgModules, SelectorContext } from '@angular/compiler';
import { NavigationEnd, Router } from "@angular/router";
import { NgbAlert } from "@ng-bootstrap/ng-bootstrap"; 
import { Subject } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";
import { AppComponent} from "../app.component";
import { AuthService } from "../auth/auth.service";
import { DOCUMENT } from '@angular/common';
import { LoadingBarComponent } from '../dashboard/loading-bar/loading-bar.component';
import { LodingBarServiceService } from '../dashboard/loading-bar/loding-bar-service.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isDisplayPwdResetBlock:boolean = true;  
  ispwdFormSbmt:boolean = false;
  signInForm:FormGroup;
  loginErrorType:String="";
  loginError:boolean = false;
  progressBar:boolean=false;
  errorFormStyle={ }
  private _warning = new Subject<string>();


  constructor(private lbs:LodingBarServiceService, private _loginService:LoginService,private _ElementRef:ElementRef,private _router:Router,private _m:AppComponent,private _authService:AuthService) {
    this.signInForm = new FormGroup({
      username:new FormControl('',Validators.required),
      password:new FormControl('',Validators.required)
    })  
    //this.selfClosingAlert= new NgbAlert<any>() | undefined;
  }

  staticAlertClosed = false;
  successMessage = '';
  @ViewChild('selfClosingAlert', { static: false })
  selfClosingAlert!: NgbAlert;


  ngOnInit(): void {
    this._warning.subscribe(message => this.successMessage = message);
    this._warning.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });    
  }
  public changeSuccessMessage(a:string) { this._warning.next(a); }
 
  togglePwdResetBlock(){
    this.isDisplayPwdResetBlock = !this.isDisplayPwdResetBlock;
  }
  pwdResetSubmit(){
    this.ispwdFormSbmt=true;
  }
  toggleFormShadow(){
    if(this.loginError){
      this._ElementRef.nativeElement.querySelector("#formContent").classList.add('highlightedErrorForm');
    }else{
      if(this._ElementRef.nativeElement.querySelector("#formContent").classList.contains('highlightedErrorForm')){
        this._ElementRef.nativeElement.querySelector("#formContent").classList.remove('highlightedErrorForm');
      }      
    }
  }
  signInUser(){
    const userLoginCredentials={
      username:this.signInForm.controls.username.value,
      password:this.signInForm.controls.password.value
    }
    console.log(userLoginCredentials);
    this._loginService.authUser(userLoginCredentials).subscribe((payload:any)=>{      
      if(payload=='notExist'){
        this.loginError=true;
        this.changeSuccessMessage("User not exist")
        this.toggleFormShadow();
      }else if(payload=='notPermitted'){
        this.loginError=true;
        this.changeSuccessMessage("You're out of working hours")
        this.toggleFormShadow();
      }else if(payload=='loggedIn'){
        this.loginError=true;
        this.changeSuccessMessage("User already Logged in somewhere")
        this.toggleFormShadow();
      }else if(payload=='emptyUnPw'){
        this.loginError=true;
        this.changeSuccessMessage("Provide username/password")
        this.toggleFormShadow();
      }else{
        this.progressBar=true;
        console.log(payload);
        this._authService.setLoginStatus(userLoginCredentials.username).subscribe(loginStatus=>{   
          if(loginStatus){
            this._authService.setLoggedIn(new Boolean(loginStatus).valueOf(),payload);
            this.loginError=false;
            this.loginErrorType='';
            this._m.isloggedIn=true;            
            this.toggleFormShadow();
            this.progressBar=false;        
            this._router.navigate(['Dashboard'])
          }else{
            this._router.navigate(['login'])
          }
        })
        // if(this._authService.setLoggedIn(true,payload)){
        //   this.loginError=false;
        //   this.loginErrorType='';
        //   this._m.isloggedIn=true;
        //   this.toggleFormShadow();        
        //   this._router.navigate(['Dashboard'])
        // }else{
        //   this._router.navigate(['login'])
        // }
        
      }

    })
  }
}
