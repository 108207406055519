import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { LoginAuditActionTypes } from './login-audit.actions';
import * as LoginDetailsAction from './login-audit.actions'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoginAuditService } from './login-audit.service';

@Injectable()
export class LoginAuditEffects {
  constructor(private actions$: Actions, private _loginAuditService:LoginAuditService){}
 loadLoginAuditDetails=createEffect(()=>{
   return this.actions$.pipe(
     ofType<any>(LoginDetailsAction.LoginAuditActionTypes.LoadLoginAudits),
     switchMap(
       action=>this._loginAuditService.fetchLoginAuditDetails(action).pipe(
         map((loginDetails)=>new LoginDetailsAction.LoadLoginAuditsSuccess({data:loginDetails})),
         catchError(err=>of(new LoginDetailsAction.LoadLoginAuditsFailure({error:err})))
       )
     )
   )
 })
}


