<div class="wrapper">
    <!-- Navbar -->

    <!-- /.navbar -->

    <!-- Main Sidebar Container -->

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper" style="margin-top: unset;">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">{{pageTtitle | uppercase}}</h1>
                    </div>
                    <!-- /.col -->
                    <!-- <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Dashboard </li>
              </ol>
            </div> -->
                    <!-- /.col -->
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <!-- Info boxes -->
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-2">
                        <div class="info-box">
                            <span class="info-box-icon bg-info elevation-1"><i class="fas fa-arrow-circle-down"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text">Recived</span>
                                <span class="info-box-number">
                    {{dashboardData && dashboardData[0].totalRecived}}
                    <small></small>
                  </span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <!-- /.col -->
                    <div class="col-12 col-sm-6 col-md-2">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-orange elevation-1"><i class="fa fa-thumbs-up"  aria-hidden="true"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text">Confirm</span>
                                <span class="info-box-number">{{dashboardData && dashboardData[0].totalConfirm}}</span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <!-- /.col -->

                    <!-- fix for small devices only -->
                    <div class="clearfix hidden-md-up"></div>

                    <div class="col-12 col-sm-6 col-md-2">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-purple elevation-1"><i class="fas fa-shopping-cart"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text">Final</span>
                                <span class="info-box-number">{{dashboardData && dashboardData[0].totalFinal}}</span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <!-- /.col -->
                    <div class="col-12 col-sm-6 col-md-2">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-shipping-fast"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text">Shipped</span>
                                <span class="info-box-number">{{dashboardData && dashboardData[0].totalDelivered}}</span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>

                    <div class="col-12 col-sm-6 col-md-2">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-warning elevation-1"><i class="fas fas fa-biking"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text">ODA</span>
                                <span class="info-box-number">{{dashboardData && dashboardData[0].totalDelivered}}</span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-success elevation-1"><i class="fas fa-check-circle"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text">Delivered</span>
                                <span class="info-box-number">{{dashboardData && dashboardData[0].totalDelivered}}</span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>




                    <div class="col-12 col-sm-6 col-md-2">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-danger elevation-1"><i class="fa fa-thumbs-down" aria-hidden="true"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text">Undelivered</span>
                                <span class="info-box-number">{{dashboardData && dashboardData[0].totalDelivered}}</span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <!-- /.col -->

                    <div class="col-12 col-sm-6 col-md-2">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-secondary elevation-1"><i class="fas fa-folder-open"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text">Unread</span>
                                <span class="info-box-number">{{dashboardData && dashboardData[0].totalUnread}}</span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-success elevation-1"><i class="fa fa-gift" aria-hidden="true"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text">Complete</span>
                                <span class="info-box-number">{{dashboardData && dashboardData[0].totalComplete}}</span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-success elevation-1"><i class="fa fa-tags"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text">NOT PICK</span>
                                <span class="info-box-number">{{dashboardData && dashboardData[0].totalNotpick}}</span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-success elevation-1"><i class="fa fa-pause-circle" aria-hidden="true"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text">HOLD</span>
                                <span class="info-box-number">{{dashboardData && dashboardData[0].totalHold}}</span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-success elevation-1"><i class="fa fa-pause-circle" aria-hidden="true"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text">CANCLE</span>
                                <span class="info-box-number">{{dashboardData && dashboardData[0].totalCancel}}</span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-success elevation-1"><i class="fa fa-pause-circle" aria-hidden="true"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text">FAKE</span>
                                <span class="info-box-number">{{dashboardData && dashboardData[0].totalFake}}</span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                </div>


                <!-- Main row -->
                <div class="row">
                    <!-- Left col -->
                    <div class="col-md-8">
                        <!-- MAP & BOX PANE -->
                        <div class="card" *appHideForRoles="['52']">
                            <div class="card-header">
                                <h3 class="card-title">Order Reports</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                      <i class="fas fa-minus"></i>
                    </button>
                                    <button type="button" class="btn btn-tool" data-card-widget="remove">
                      <i class="fas fa-times"></i>
                    </button>
                                </div>
                            </div>
                            <!-- /.card-header -->
                            <div class="card-body p-0">
                                <div class="d-md-flex">
                                    <div class="p-1 flex-fill" style="overflow: hidden">
                                        <!-- Map will be created here -->
                                        <div id="world-map-markers" style="height: 325px; overflow: hidden">
                                            <div class="map">
                                                <div class="description-block" style="display: block;margin: 10px 0;text-align: center;background: #004d5991;box-shadow: 4px 5px 11px -1px black;" *ngFor="let yearOrder of yearOrdersReport">
                                                    <h5 class="description-text" style="text-align:left;margin-left: 7px;color: #fff;">{{yearOrder.year}}</h5>
                                                    <h5 class="description-header">Complete: {{yearOrder.totalComplete}}</h5>
                                                    <h5 class="description-header">Delivered: {{yearOrder.totalDelivered}}</h5>
                                                    <h5 class="description-header" style="font-size: 50px;font-weight: 900;color: #ffc107;">ꜛ</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-pane-right bg-success pt-2 pb-2 pl-4 pr-4">
                                        <h5 class="textColorBlack font-weight700">ORDER PERFORMANCE YEARS</h5>
                                        <div class="sparkbar pad" data-color="#fff" style="text-align:center;text-decoration:underline;font-weight: 600;">Total Orders Recived</div>
                                        <div class="description-block" style="display: block;margin: 10px 0;text-align: center;background: #004d5991;box-shadow: 4px 5px 11px -1px black;" *ngFor="let yearOrder of yearOrdersReport">
                                            <span class="description-text">{{yearOrder.year}}</span>
                                            <h5 class="description-header"><span style="text-decoration:underline;">Recieved</span>: {{yearOrder.totalOrders}}</h5>
                                            <h5 class="description-header" style="font-size: 50px;font-weight: 900;color: #ffc107;">ꜛ</h5>
                                        </div>
                                    </div>
                                    <!-- /.card-pane-right -->
                                </div>
                                <!-- /.d-md-flex -->
                            </div>
                            <!-- /.card-body -->
                        </div>
                        <!-- /.card -->
                        <div class="row">

                            <!-- Chat section commented by kuldeep, will enable it later -->
                            <div class="col-md-6">

                                <div class="card direct-chat direct-chat-warning">
                                    <div class="card-header">
                                        <h3 class="card-title">Direct Chat</h3>

                                        <div class="card-tools">
                                            <span title="3 New Messages" class="badge badge-warning">3</span>
                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                          <i class="fas fa-minus"></i>
                        </button>
                                            <button type="button" class="btn btn-tool" title="Contacts" data-widget="chat-pane-toggle">
                          <i class="fas fa-comments"></i>
                        </button>
                                            <button type="button" class="btn btn-tool" data-card-widget="remove">
                          <i class="fas fa-times"></i>
                        </button>
                                        </div>
                                    </div>

                                    <div class="card-body">

                                        <div class="direct-chat-messages">

                                            <div class="direct-chat-msg">
                                                <div class="direct-chat-infos clearfix">
                                                    <span class="direct-chat-name float-left">Alexander Pierce</span>
                                                    <span class="direct-chat-timestamp float-right">23 Jan 2:00 pm</span>
                                                </div>

                                                <img class="direct-chat-img" src="../../../assets/img/user1-128x128.jpg" alt="message user image">

                                                <div class="direct-chat-text">
                                                    Is this template really for free? That's unbelievable!
                                                </div>

                                            </div>



                                            <div class="direct-chat-msg right">
                                                <div class="direct-chat-infos clearfix">
                                                    <span class="direct-chat-name float-right">Sarah Bullock</span>
                                                    <span class="direct-chat-timestamp float-left">23 Jan 2:05 pm</span>
                                                </div>

                                                <img class="direct-chat-img" src="../../../assets/img/user3-128x128.jpg" alt="message user image">

                                                <div class="direct-chat-text">
                                                    You better believe it!
                                                </div>

                                            </div>



                                            <div class="direct-chat-msg">
                                                <div class="direct-chat-infos clearfix">
                                                    <span class="direct-chat-name float-left">Alexander Pierce</span>
                                                    <span class="direct-chat-timestamp float-right">23 Jan 5:37 pm</span>
                                                </div>

                                                <img class="direct-chat-img" src="../../../assets/img/user1-128x128.jpg" alt="message user image">

                                                <div class="direct-chat-text">
                                                    Working with AdminLTE on a great new app! Wanna join?
                                                </div>

                                            </div>



                                            <div class="direct-chat-msg right">
                                                <div class="direct-chat-infos clearfix">
                                                    <span class="direct-chat-name float-right">Sarah Bullock</span>
                                                    <span class="direct-chat-timestamp float-left">23 Jan 6:10 pm</span>
                                                </div>

                                                <img class="direct-chat-img" src="../../../assets/img/user3-128x128.jpg" alt="message user image">

                                                <div class="direct-chat-text">
                                                    I would love to.
                                                </div>

                                            </div>


                                        </div>



                                        <div class="direct-chat-contacts">
                                            <ul class="contacts-list">
                                                <li>
                                                    <a href="#">
                                                        <img class="contacts-list-img" src="../../../assets/img/user1-128x128.jpg" alt="User Avatar">

                                                        <div class="contacts-list-info">
                                                            <span class="contacts-list-name">
                                  Count Dracula
                                  <small class="contacts-list-date float-right">2/28/2015</small>
                                </span>
                                                            <span class="contacts-list-msg">How have you been? I was...</span>
                                                        </div>

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="#">
                                                        <img class="contacts-list-img" src="../../../assets/img/user7-128x128.jpg" alt="User Avatar">

                                                        <div class="contacts-list-info">
                                                            <span class="contacts-list-name">
                                  Sarah Doe
                                  <small class="contacts-list-date float-right">2/23/2015</small>
                                </span>
                                                            <span class="contacts-list-msg">I will be waiting for...</span>
                                                        </div>

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="#">
                                                        <img class="contacts-list-img" src="../../../assets/img/user3-128x128.jpg" alt="User Avatar">

                                                        <div class="contacts-list-info">
                                                            <span class="contacts-list-name">
                                  Nadia Jolie
                                  <small class="contacts-list-date float-right">2/20/2015</small>
                                </span>
                                                            <span class="contacts-list-msg">I'll call you back at...</span>
                                                        </div>

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="#">
                                                        <img class="contacts-list-img" src="../../../assets/img/user5-128x128.jpg" alt="User Avatar">

                                                        <div class="contacts-list-info">
                                                            <span class="contacts-list-name">
                                  Nora S. Vans
                                  <small class="contacts-list-date float-right">2/10/2015</small>
                                </span>
                                                            <span class="contacts-list-msg">Where is your new...</span>
                                                        </div>

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="#">
                                                        <img class="contacts-list-img" src="../../../assets/img/user6-128x128.jpg" alt="User Avatar">

                                                        <div class="contacts-list-info">
                                                            <span class="contacts-list-name">
                                  John K.
                                  <small class="contacts-list-date float-right">1/27/2015</small>
                                </span>
                                                            <span class="contacts-list-msg">Can I take a look at...</span>
                                                        </div>

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="#">
                                                        <img class="contacts-list-img" src="../../../assets/img/user8-128x128.jpg" alt="User Avatar">

                                                        <div class="contacts-list-info">
                                                            <span class="contacts-list-name">
                                  Kenneth M.
                                  <small class="contacts-list-date float-right">1/4/2015</small>
                                </span>
                                                            <span class="contacts-list-msg">Never mind I found...</span>
                                                        </div>

                                                    </a>
                                                </li>

                                            </ul>

                                        </div>

                                    </div>

                                    <div class="card-footer">
                                        <form action="#" method="post">
                                            <div class="input-group">
                                                <input type="text" name="message" placeholder="Type Message ..." class="form-control">
                                                <span class="input-group-append">
                            <button type="button" class="btn btn-warning">Send</button>
                          </span>
                                            </div>
                                        </form>
                                    </div>

                                </div>

                            </div>

                            <div class="col-md-6">
                                <div class="card">
                                    <div class="card-header">
                                        <h3 class="card-title">Latest Members</h3>

                                        <div class="card-tools">
                                            <span class="badge badge-danger">8 New Members</span>
                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                          <i class="fas fa-minus"></i>
                        </button>
                                            <button type="button" class="btn btn-tool" data-card-widget="remove">
                          <i class="fas fa-times"></i>
                        </button>
                                        </div>
                                    </div>

                                    <div class="card-body p-0" style="padding: 17px 0 17px 0!important;">
                                        <ul class="users-list clearfix">
                                            <li *ngFor="let latest of latestUsers">
                                                <i class="fa fa-user" aria-hidden="true" style="background: aquamarine;border-radius: 50%;font-size: 45px;"></i>
                                                <a class="users-list-name" href="#">{{latest.username}}</a>
                                                <a class="users-list-name" href="#">{{latest.name}}</a>

                                            </li>
                                        </ul>

                                    </div>

                                    <div class="card-footer text-center">
                                        <a href="javascript:">View All Users</a>
                                    </div>

                                </div>

                            </div>

                        </div>


                        <!-- TABLE: LATEST ORDERS -->
                        <!-- <div class="card">
                <div class="card-header border-transparent">
                  <h3 class="card-title">Latest Orders</h3>
  
                  <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                      <i class="fas fa-minus"></i>
                    </button>
                    <button type="button" class="btn btn-tool" data-card-widget="remove">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
                
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table m-0">
                      <thead>
                      <tr>
                        <th>Order ID</th>
                        <th>Item</th>
                        <th>Status</th>
                        <th>Popularity</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td><a href="pages/examples/invoice.html">OR9842</a></td>
                        <td>Call of Duty IV</td>
                        <td><span class="badge badge-success">Shipped</span></td>
                        <td>
                          <div class="sparkbar" data-color="#00a65a" data-height="20">90,80,90,-70,61,-83,63</div>
                        </td>
                      </tr>
                      <tr>
                        <td><a href="pages/examples/invoice.html">OR1848</a></td>
                        <td>Samsung Smart TV</td>
                        <td><span class="badge badge-warning">Pending</span></td>
                        <td>
                          <div class="sparkbar" data-color="#f39c12" data-height="20">90,80,-90,70,61,-83,68</div>
                        </td>
                      </tr>
                      <tr>
                        <td><a href="pages/examples/invoice.html">OR7429</a></td>
                        <td>iPhone 6 Plus</td>
                        <td><span class="badge badge-danger">Delivered</span></td>
                        <td>
                          <div class="sparkbar" data-color="#f56954" data-height="20">90,-80,90,70,-61,83,63</div>
                        </td>
                      </tr>
                      <tr>
                        <td><a href="pages/examples/invoice.html">OR7429</a></td>
                        <td>Samsung Smart TV</td>
                        <td><span class="badge badge-info">Processing</span></td>
                        <td>
                          <div class="sparkbar" data-color="#00c0ef" data-height="20">90,80,-90,70,-61,83,63</div>
                        </td>
                      </tr>
                      <tr>
                        <td><a href="pages/examples/invoice.html">OR1848</a></td>
                        <td>Samsung Smart TV</td>
                        <td><span class="badge badge-warning">Pending</span></td>
                        <td>
                          <div class="sparkbar" data-color="#f39c12" data-height="20">90,80,-90,70,61,-83,68</div>
                        </td>
                      </tr>
                      <tr>
                        <td><a href="pages/examples/invoice.html">OR7429</a></td>
                        <td>iPhone 6 Plus</td>
                        <td><span class="badge badge-danger">Delivered</span></td>
                        <td>
                          <div class="sparkbar" data-color="#f56954" data-height="20">90,-80,90,70,-61,83,63</div>
                        </td>
                      </tr>
                      <tr>
                        <td><a href="pages/examples/invoice.html">OR9842</a></td>
                        <td>Call of Duty IV</td>
                        <td><span class="badge badge-success">Shipped</span></td>
                        <td>
                          <div class="sparkbar" data-color="#00a65a" data-height="20">90,80,90,-70,61,-83,63</div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                
                </div>
                
                <div class="card-footer clearfix">
                  <a href="javascript:void(0)" class="btn btn-sm btn-info float-left">Place New Order</a>
                  <a href="javascript:void(0)" class="btn btn-sm btn-secondary float-right">View All Orders</a>
                </div>
                
              </div> -->

                    </div>
                    <!-- /.col -->

                    <div class="col-md-4">
                        <!-- Info Boxes Style 2 -->
                        <!-- hidden by kuldeep -->
                        <div class="info-box mb-3 bg-success starPerformer" *ngFor="let starPerformer of starPerformers">
                            <span class="info-box-icon"><img src="../../../assets/img/award1.png" alt=""></span>

                            <div class="info-box-content">
                                <span class="info-box-text">{{starPerformer && starPerformer.userID}}-{{starPerformer && starPerformer.name}} (Agent)</span>
                                <div class="starPerformerData">
                                    <span class="info-box-number">Recieved: {{starPerformer && starPerformer.totalOrderRecieved}}</span>
                                    <span class="info-box-number">Final: {{starPerformer && starPerformer.totalOrderFial}}</span>
                                    <span class="info-box-number">Delivered: {{starPerformer && starPerformer.totalOrderDelived}}</span>
                                </div>
                            </div>
                        </div>
                        <!-- /.info-box -->
                        <!-- hidden by kuldeep -->

                        <!-- /.info-box -->
                        <!-- hiiden by Kuldeep -->
                        <div class="info-box mb-3 bg-success starTLPerformer" *ngFor="let starTL of starTLperformer">
                            <span class="info-box-icon"><img src="../../../assets/img/award.png" alt=""></span>

                            <div class="info-box-content ">
                                <span class="info-box-text">{{starTL && starTL.userID}}-{{starTL && starTL.name}} (Team Lead)</span>
                                <div class="starTLPerformerData">
                                    <span class="info-box-number">Recieved: {{starTL && starTL.totalOrderRecieved}}</span>
                                    <span class="info-box-number">Final: {{starTL && starTL.totalOrderFial}}</span>
                                    <span class="info-box-number">Delivered: {{starTL && starTL.totalOrderDelived}}</span>
                                </div>
                            </div>

                        </div>
                        <!-- /.info-box -->
                        <!-- hidden by kuldeep -->

                        <!-- /.info-box -->
                        <!-- WIll display later on this ,hidden by kuldeep -->
                        <!-- <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Browser Usage</h3>
  
                  <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                      <i class="fas fa-minus"></i>
                    </button>
                    <button type="button" class="btn btn-tool" data-card-widget="remove">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
                
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="chart-responsive">
                        <canvas id="pieChart" height="150"></canvas>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <ul class="chart-legend clearfix">
                        <li><i class="far fa-circle text-danger"></i> Chrome</li>
                        <li><i class="far fa-circle text-success"></i> IE</li>
                        <li><i class="far fa-circle text-warning"></i> FireFox</li>
                        <li><i class="far fa-circle text-info"></i> Safari</li>
                        <li><i class="far fa-circle text-primary"></i> Opera</li>
                        <li><i class="far fa-circle text-secondary"></i> Navigator</li>
                      </ul>
                    </div>
                    
                  </div>
                  
                </div>
                
                <div class="card-footer bg-light p-0">
                  <ul class="nav nav-pills flex-column">
                    <li class="nav-item">
                      <a href="#" class="nav-link">
                        United States of America
                        <span class="float-right text-danger">
                          <i class="fas fa-arrow-down text-sm"></i>
                          12%</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#" class="nav-link">
                        India
                        <span class="float-right text-success">
                          <i class="fas fa-arrow-up text-sm"></i> 4%
                        </span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#" class="nav-link">
                        China
                        <span class="float-right text-warning">
                          <i class="fas fa-arrow-left text-sm"></i> 0%
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                
              </div> -->

                        <!-- PRODUCT LIST -->
                        <!-- <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Recently Added Products</h3>
  
                  <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                      <i class="fas fa-minus"></i>
                    </button>
                    <button type="button" class="btn btn-tool" data-card-widget="remove">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
                
                <div class="card-body p-0">
                  <ul class="products-list product-list-in-card pl-2 pr-2">
                    <li class="item">
                      <div class="product-img">
                        <img src="../../../assets/img/default-150x150.png" alt="Product Image" class="img-size-50">
                      </div>
                      <div class="product-info">
                        <a href="javascript:void(0)" class="product-title">Samsung TV
                          <span class="badge badge-warning float-right">$1800</span></a>
                        <span class="product-description">
                          Samsung 32" 1080p 60Hz LED Smart HDTV.
                        </span>
                      </div>
                    </li>
                    
                    <li class="item">
                      <div class="product-img">
                        <img src="../../../assets/img/default-150x150.png" alt="Product Image" class="img-size-50">
                      </div>
                      <div class="product-info">
                        <a href="javascript:void(0)" class="product-title">Bicycle
                          <span class="badge badge-info float-right">$700</span></a>
                        <span class="product-description">
                          26" Mongoose Dolomite Men's 7-speed, Navy Blue.
                        </span>
                      </div>
                    </li>
                    
                    <li class="item">
                      <div class="product-img">
                        <img src="../../../assets/img/default-150x150.png" alt="Product Image" class="img-size-50">
                      </div>
                      <div class="product-info">
                        <a href="javascript:void(0)" class="product-title">
                          Xbox One <span class="badge badge-danger float-right">
                          $350
                        </span>
                        </a>
                        <span class="product-description">
                          Xbox One Console Bundle with Halo Master Chief Collection.
                        </span>
                      </div>
                    </li>
                    
                    <li class="item">
                      <div class="product-img">
                        <img src="../../../assets/img/default-150x150.png" alt="Product Image" class="img-size-50">
                      </div>
                      <div class="product-info">
                        <a href="javascript:void(0)" class="product-title">PlayStation 4
                          <span class="badge badge-success float-right">$399</span></a>
                        <span class="product-description">
                          PlayStation 4 500GB Console (PS4)
                        </span>
                      </div>
                    </li>
                    
                  </ul>
                </div>
                
                <div class="card-footer text-center">
                  <a href="javascript:void(0)" class="uppercase">View All Products</a>
                </div>
                
              </div> -->
                        <!-- /.card -->
                    </div>
                    <!-- /.col -->
                </div>
                <!-- /.row -->
                <!-- /.row -->

                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="card-title capitalize">Monthly Recap Report</h5>
                                <mat-form-field class="example-full-width monthlyShortReport " appearance="legacy">
                                    <mat-label>Months</mat-label>
                                    <input type="text" placeholder="Pick one" aria-label="Month" matInput formControlName="courseMonth" [matAutocomplete]="month" required>
                                    <mat-autocomplete #month="matAutocomplete" (optionSelected)="changeDashBoardMonthlyReport($event)" [displayWith]="getMonthTitle.bind(this)">
                                        <mat-option *ngFor="let month of Months index by index" [value]="month.index">
                                            {{month.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                      <i class="fas fa-minus"></i>
                    </button>
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown">
                        <i class="fas fa-wrench"></i>
                      </button>
                                        <div class="dropdown-menu dropdown-menu-right" role="menu">
                                            <a href="#" class="dropdown-item">Action</a>
                                            <a href="#" class="dropdown-item">Another action</a>
                                            <a href="#" class="dropdown-item">Something else here</a>
                                            <a class="dropdown-divider"></a>
                                            <a href="#" class="dropdown-item">Separated link</a>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-tool" data-card-widget="remove">
                      <i class="fas fa-times"></i>
                    </button>
                                </div>
                            </div>
                            <!-- /.card-header -->
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-8" style="flex: 0 0 100.666667%; max-width: 99.666667%">
                                        <!-- <p class="text-center">
                        <strong>Sales: 1 Jan, 2014 - 30 Jul, 2014</strong>
                      </p> -->

                                        <div class="chart">
                                            <table class="table table-striped">
                                                <tr class="textColorDarkSkyBlue font-weight500 table-info ">
                                                    <td class="firstCol">Final</td>
                                                    <td class="font-weight900">{{monthlyRecapReport && monthlyRecapReport[0].totalFinal}}</td>
                                                </tr>
                                                <tr class="textColorDarkSkyBlue font-weight500 table-info ">
                                                    <td class="firstCol">Recived</td>
                                                    <td class="font-weight900">{{monthlyRecapReport && monthlyRecapReport[0].totalRecived}}</td>
                                                </tr>
                                                <tr class="textColorGreen font-weight500 table-success">
                                                    <td class="firstCol">Complete</td>
                                                    <td class="font-weight900">{{monthlyRecapReport && monthlyRecapReport[0].totalComplete}}</td>
                                                </tr>
                                                <tr class="textColorGreen font-weight500 table-success">
                                                    <td class="firstCol">Delivered</td>
                                                    <td class="font-weight900">{{monthlyRecapReport && monthlyRecapReport[0].totalDelivered}}</td>
                                                </tr>
                                                <tr class="textColorYellow font-weight500 table-success">
                                                    <td class="firstCol">Shipped</td>
                                                    <td class="font-weight900">{{monthlyRecapReport && monthlyRecapReport[0].totalComplete}}</td>
                                                </tr>
                                                <tr class="textColorOrange font-weight500 table-warning">
                                                    <td class="firstCol">Confirm</td>
                                                    <td class="font-weight900">{{monthlyRecapReport && monthlyRecapReport[0].totalConfirm}}</td>
                                                </tr>
                                                <tr class="textColorBlack font-weight500 table-danger">
                                                    <td class="firstCol">Fake</td>
                                                    <td class="font-weight900">{{monthlyRecapReport && monthlyRecapReport[0].totalFake}}</td>
                                                </tr>
                                                <tr class="textColorOrange font-weight500 table-info ">
                                                    <td class="firstCol">Hold</td>
                                                    <td class="font-weight900">{{monthlyRecapReport && monthlyRecapReport[0].totalHold}}</td>
                                                </tr>
                                                <tr class="textColorGreen font-weight500 table-danger">
                                                    <td class="firstCol">Cancle</td>
                                                    <td class="font-weight900">{{monthlyRecapReport && monthlyRecapReport[0].totalCancel}}</td>
                                                </tr>
                                                <tr class="textColorRed font-weight500 bg-danger">
                                                    <td class="firstCol">Undelivered</td>
                                                    <td class="font-weight900">{{monthlyRecapReport && monthlyRecapReport[0].totalRecived}}</td>
                                                </tr>
                                                <tr class="textColorGray font-weight500 table-warning">
                                                    <td class="firstCol">Unanswered</td>
                                                    <td class="font-weight900">{{monthlyRecapReport && monthlyRecapReport[0].totalNotpick}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <!-- /.chart-responsive -->
                                    </div>
                                    <!-- /.col -->
                                    <!-- Goal Completion hidden by Kuldeep  -->
                                    <!-- <div class="col-md-4">
                      <p class="text-center">
                        <strong>Goal Completion</strong>
                      </p>
  
                      <div class="progress-group">
                        Add Products to Cart
                        <span class="float-right"><b>160</b>/200</span>
                        <div class="progress progress-sm">
                          <div class="progress-bar bg-primary" style="width: 80%"></div>
                        </div>
                      </div>
                      
  
                      <div class="progress-group">
                        Complete Purchase
                        <span class="float-right"><b>310</b>/400</span>
                        <div class="progress progress-sm">
                          <div class="progress-bar bg-danger" style="width: 75%"></div>
                        </div>
                      </div>
  
                      
                      <div class="progress-group">
                        <span class="progress-text">Visit Premium Page</span>
                        <span class="float-right"><b>480</b>/800</span>
                        <div class="progress progress-sm">
                          <div class="progress-bar bg-success" style="width: 60%"></div>
                        </div>
                      </div>
  
                      
                      <div class="progress-group">
                        Send Inquiries
                        <span class="float-right"><b>250</b>/500</span>
                        <div class="progress progress-sm">
                          <div class="progress-bar bg-warning" style="width: 50%"></div>
                        </div>
                      </div>
                      
                    </div> -->
                                    <!-- /.col -->
                                </div>
                                <!-- /.row -->
                            </div>
                            <!-- ./card-body -->
                            <!-- Footer that calculates the matrix which is very useful hidden by Kuldeep Because they will be implemented later on this project  -->
                            <!-- <div class="card-footer">
                  <div class="row">
                    <div class="col-sm-3 col-6">
                      <div class="description-block border-right">
                        <span class="description-percentage text-success"><i class="fas fa-caret-up"></i> 17%</span>
                        <h5 class="description-header">$35,210.43</h5>
                        <span class="description-text">TOTAL REVENUE</span>
                      </div>
                      
                    </div>
                    
                    <div class="col-sm-3 col-6">
                      <div class="description-block border-right">
                        <span class="description-percentage text-warning"><i class="fas fa-caret-left"></i> 0%</span>
                        <h5 class="description-header">$10,390.90</h5>
                        <span class="description-text">TOTAL COST</span>
                      </div>
                    
                    </div>
                    
                    <div class="col-sm-3 col-6">
                      <div class="description-block border-right">
                        <span class="description-percentage text-success"><i class="fas fa-caret-up"></i> 20%</span>
                        <h5 class="description-header">$24,813.53</h5>
                        <span class="description-text">TOTAL PROFIT</span>
                      </div>
                    
                    </div>
                    
                    <div class="col-sm-3 col-6">
                      <div class="description-block">
                        <span class="description-percentage text-danger"><i class="fas fa-caret-down"></i> 18%</span>
                        <h5 class="description-header">1200</h5>
                        <span class="description-text">GOAL COMPLETIONS</span>
                      </div>
                    
                    </div>
                  </div>
                  
                </div> -->
                            <!-- /.card-footer -->
                        </div>
                        <!-- /.card -->
                    </div>
                    <!-- /.col -->
                </div>
                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>
    <!-- /.control-sidebar -->

    <!-- Main Footer -->

</div>
<!-- ./wrapper -->