<nav class="main-header topMenuSwitch">
  <h2><i class="fa fa-angle-down" aria-hidden="true"></i><span>Switch Report View</span><i class="fa fa-angle-down" aria-hidden="true"></i></h2>
  <input id="toggle" type="checkbox" checked>
  <ul>
    <li (click)="switchReport($event)" ><a id="user" href="#" (click)="disableRedirection()">USER</a></li>
    <li (click)="switchReport($event)"><a  id="monthly" href="#" (click)="disableRedirection()">MONTHLY</a></li>
    <li (click)="switchReport($event)" ><a id="yearly" href="#" (click)="disableRedirection()">YEARLY</a></li>
    <li (click)="switchReport($event)" ><a id="course" href="#" (click)="disableRedirection()">COURSE</a></li>
    <li (click)="switchReport($event)" ><a id="product" href="#" (click)="disableRedirection()">PRODUCT</a></li>
    <li (click)="switchReport($event)" ><a id="manual" href="#" (click)="disableRedirection()">MANUAL</a></li>
  </ul>
</nav>
<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">       
        <div class="row mb-1">          
          <div class="col-sm-6">
            <h2 class="m-0">{{pageTitle | uppercase}}</h2>
          </div><!-- /.col -->
          <div class="col-sm-6 userList">
            <form class="UserReportForm" (ngSubmit)="UserReportAction(formDirective)" [formGroup]="UserReportForm" #formDirective="ngForm">
                <mat-form-field class="example-full-width " appearance="legacy" *ngIf="userReport">
                    <mat-label>User</mat-label>
                    <input type="text"
                        placeholder="Pick one"
                        aria-label="User"
                        matInput
                        formControlName="users"
                        [matAutocomplete]="users" required>
                    <mat-autocomplete #users="matAutocomplete" (optionSelected)="changeUserReport($event)" [displayWith]="getUserTitle.bind(this)">
                        <mat-option *ngFor="let user of userList index by id"  [value]="user.id">
                            {{user.id}}  {{user.fname +' '+ user.lname}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field class="example-full-width " appearance="legacy" *ngIf="monthlyReport">
                  <mat-label>Months</mat-label>
                  <input type="text"
                      placeholder="Pick one"
                      aria-label="Month"
                      matInput
                      formControlName="month"
                      [matAutocomplete]="month" required>
                  <mat-autocomplete #month="matAutocomplete" (optionSelected)="changeMonthReport($event)" [displayWith]="getMonthTitle.bind(this)">
                      <mat-option *ngFor="let month of Months index by id"  [value]="month.index">
                          {{month.name}}
                      </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-form-field class="example-full-width " appearance="legacy" *ngIf="yearlyReport">
                  <mat-label>Year</mat-label>
                  <input type="text"
                      placeholder="Pick one"
                      aria-label="Year"
                      matInput
                      formControlName="year"
                      [matAutocomplete]="year" required>
                  <mat-autocomplete #year="matAutocomplete" (optionSelected)="changeYearReport($event)">
                      <mat-option *ngFor="let year of Years"  [value]="year">
                          {{year}}
                      </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-form-field class="example-full-width " appearance="legacy" *ngIf="courseReport">
                  <mat-label>Months</mat-label>
                  <input type="text"
                      placeholder="Pick one"
                      aria-label="Month"
                      matInput
                      formControlName="courseMonth"
                      [matAutocomplete]="month" required>
                  <mat-autocomplete #month="matAutocomplete" (optionSelected)="changeCourseReport($event)" [displayWith]="getMonthTitle.bind(this)">
                      <mat-option *ngFor="let month of Months index by id"  [value]="month.index">
                          {{month.name}}
                      </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-form-field class="example-full-width " appearance="legacy" *ngIf="productReport">
                  <mat-label>Months</mat-label>
                  <input type="text"
                      placeholder="Pick one"
                      aria-label="Month"
                      matInput
                      formControlName="productMonth"
                      [matAutocomplete]="month" required>
                  <mat-autocomplete #month="matAutocomplete" (optionSelected)="changeProductReport($event)" [displayWith]="getMonthTitle.bind(this)">
                      <mat-option *ngFor="let month of Months index by id"  [value]="month.index">
                          {{month.name}}
                      </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <div *ngIf="manualReport">
                  
                </div>
               
            </form>
          </div>
          <!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content" id="renderDataSection">
        <div class="container-fluid">
          <!-- Info boxes -->    
            <div class="row" >
              <ag-grid-angular 
                #ReportsList
                style="width: 1470px; height: 570px;"
                class="ag-theme-balham"
                [sideBar]="sideBar"
                [rowData]="ReportList" 
                [defaultColDef]="defaultColDef"
                [columnDefs]="columnDefs"
                [animateRows]='true'
                [pagination]='true'
                [paginationPageSize]="paginationPageSize"
                (onColumnResized)="onResize($event)"
                *ngIf="monthlyReport"
                >
              </ag-grid-angular>            
              <ag-grid-angular 
                #ReportsList
                style="width: 1470px; height: 570px;"
                class="ag-theme-balham"
                [sideBar]="sideBar"
                [rowData]="ReportList" 
                [defaultColDef]="defaultColDef"
                [columnDefs]="columnsYearDefs"
                [animateRows]='true'
                [pagination]='true'
                [paginationPageSize]="paginationPageSize"
                (onColumnResized)="onResize($event)"
                *ngIf="yearlyReport"
                >
              </ag-grid-angular>      
              <ag-grid-angular 
                #ReportsList
                style="width: 1470px; height: 570px;"
                class="ag-theme-balham"
                [sideBar]="sideBar"
                [rowData]="UserReportList" 
                [defaultColDef]="defaultColDef"
                [columnDefs]="columnsUserDefs"
                [animateRows]='true'
                [pagination]='true'
                [paginationPageSize]="paginationPageSize"
                (onColumnResized)="onResize($event)"
                *ngIf="userReport"
                >
              </ag-grid-angular>
              <ag-grid-angular 
                  #ReportsList
                  style="width: 1470px; height: 570px;"
                  class="ag-theme-balham"
                  [sideBar]="sideBar"
                  [rowData]="ReportList" 
                  [defaultColDef]="defaultColDef"
                  [columnDefs]="columnsCourseDefs"
                  [animateRows]='true'
                  [pagination]='true'
                  [paginationPageSize]="paginationPageSize"
                  (onColumnResized)="onResize($event)"
                  *ngIf="courseReport"
                  >
              </ag-grid-angular>
              <ag-grid-angular 
                  #ReportsList
                  style="width: 1470px; height: 570px;"
                  class="ag-theme-balham"
                  [sideBar]="sideBar"
                  [rowData]="ReportList" 
                  [defaultColDef]="defaultColDef"
                  [columnDefs]="columnsProductDefs"
                  [animateRows]='true'
                  [pagination]='true'
                  [paginationPageSize]="paginationPageSize"
                  (onColumnResized)="onResize($event)"
                  *ngIf="productReport"
                  >
              </ag-grid-angular>
            </div>
        </div>
    </section>
    <!-- /.content-header -->
</div>

