import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, range, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { baseUrl } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { orders } from './orders';
import { orderHistory } from '../order-action-button/orderHistory';
import { UtilService } from 'src/app/utilities/util.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { parameter } from './orders.component';
import { AngularFrameworkComponentWrapper } from 'ag-grid-angular';
import { parse } from 'path';
import { ParseError } from '@angular/compiler';
import { Orders } from 'src/app/models/orders.model';
import { AnyARecord } from 'dns';

@Injectable({
  providedIn: 'root'
})

export class OrderService {

  private _refreshNeeded$= new Subject<void>();

  constructor( private _http:HttpClient,private _util:UtilService) { }
  handleError(errorResponse: HttpErrorResponse) {
    const error= errorResponse.error;
    const keys=Object.keys(error);
    const key = keys[0];
    let message = error[key];
    //console.log(errorResponse,keys);
    if(errorResponse.status == 0){
      // auth token delete
      //redirect login page
      message="no Server"
    }
    if(error[key] instanceof Array){
      message = error[key][0];
    }
    if(key === "isTrusted"){
      Swal.fire({
        position: 'bottom-end',
        icon: 'error',
        title:'Server not started!',
        showConfirmButton: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      })
    }else{
      message = key+ ':'+message;
    }
    return throwError({messages:message,error})
  }

  getOrdersP(arg:any):Observable<orders[]>{
    //const params={params:{arg:arg}}
    let params: HttpParams = new HttpParams();
    for (const key of Object.keys(arg)) {
      console.log("arg key=",arg[key],key);
      if (arg[key]) {
        // if (arg[key] instanceof Array) {
        //   arg[key].forEach((item:any) => {
        //     params = params.append(`${key.toString()}[]`, item);
        //   });
        // } else {
          params = params.append(arg[key].name.toString(), arg[key].value);
        //}
      }
      console.log("params=",params)
    }
    return this._http.get<orders[]>(baseUrl+'/check.php',{params:params}).pipe(catchError(this.handleError.bind(this)))
  }
  fetchSingleOrder(orderID:any):Observable<orders[]>{
    const params={params:{orderID:orderID}}
    return this._http.get<orders[]>(baseUrl+'/getSingalOrder.php',params).pipe(catchError(this.handleError.bind(this)));
  }
  fetchOrders(user:any,category:any):Observable<orders[]>{
    const params={params:{user:user,category:category}}
    return this._http.get<orders[]>(baseUrl+'/getOrders.php?p',params).pipe(catchError(this.handleError.bind(this)));
  }
  fetchNotPickOrders():Observable<orders[]>{
    return this._http.get<orders[]>(baseUrl+'/getNotPickOrders.php').pipe(catchError(this.handleError.bind(this)));
  }
  fetchHoldOrders():Observable<orders[]>{
    return this._http.get<orders[]>(baseUrl+'/getHoldOrders.php').pipe(catchError(this.handleError.bind(this)));
  }
  CreateOrder(order:any,shift:any):Observable<any>{ 
    const params={order:order,shift:shift}
    return this._http.post<any>(baseUrl+'/createOrder.php?ty',params).pipe(catchError(this.handleError.bind(this)))
  }
  checkPhpTest(){
    return this._http.get(baseUrl+'/check.php').pipe(catchError(this.handleError.bind(this)))
  }
  fetchAgentsForFilters(){
    return this._http.get(baseUrl+'/AgentListForFilter.php').pipe(catchError(this.handleError.bind(this)))
  }
  UpdateOrder(order:any):Observable<any>{
    return this._http.post<any>(baseUrl+'/updateOrder.php',order).pipe(catchError(this.handleError.bind(this)));
  }
  assignOrder(orders:any,assignTo:number):Observable<any>{
    const params={orders:orders,assignTo:assignTo}
    return this._http.post<any>(baseUrl+'/assignOrder.php',params).pipe(catchError(this.handleError.bind(this)));
  }
  transferOrders(orders:any,assignTo:number):Observable<any>{
    const params={orders:orders,assignTo:assignTo}
    return this._http.post<any>(baseUrl+'/transferOrders.php',params).pipe(catchError(this.handleError.bind(this)));
  }
  orderHistory(orderID:any):Observable<orderHistory[]>{
    const params={params:{orderID:orderID}}
    return this._http.get<orderHistory[]>(baseUrl+'/orderHistory.php',params).pipe(catchError(this.handleError.bind(this)))
  }  
  shipOrder(order:orders[],orderCount:number,token:any):Observable<any[]>{     
      const params={order:order,count:orderCount,token:token}
      return this._http.post<any[]>(baseUrl+'/spr_forward_shipment.php',params).pipe(catchError(this.handleError.bind(this)))    
  }
  getListOfCourier(){
    return this._http.get(baseUrl+'/spr_listOfCourier.php').pipe(catchError(this.handleError.bind(this)))
  }
  updateOrderCourierStatus(orderID:any):Observable<any[]>{
    const params={orderStatus:"COMPLETED",orderID:orderID}
    return this._http.post<any[]>(baseUrl+'/updateCourierStatus.php',params).pipe(catchError(this.handleError.bind(this)));
  }
  searchOrders(mobile:any):Observable<orders[]>{
    const params={params:{mobile:mobile}}
    return this._http.get<orders[]>(baseUrl+'/getMobileSearchOrder.php',params).pipe(catchError(this.handleError.bind(this)));
  }
  cloneOrder(OriginalOrder:any,actionBy:any,category:any,username:any,):Observable<Orders[]>{ 
    const params={OriginalOrder:OriginalOrder,actionBy:actionBy,category:category,username:username}   
    return this._http.post<any>(baseUrl+'/cloneOrder.php?ty',{params}).pipe(catchError(this.handleError.bind(this)))    
  }
  CreateReOrder(order:any):Observable<any>{ 
    return this._http.post<any>(baseUrl+'/createReOrder.php?ty',order).pipe(catchError(this.handleError.bind(this)))
  }
  fetchShippedOrderStatus(order:orders[],orderCount:number,token:any):Observable<any[]>{
    const params={order:order,count:orderCount,token:token}
    return this._http.post<any[]>(baseUrl+'/spr_update_shipped_order_awb.php',params).pipe(catchError(this.handleError.bind(this)))
  }
  UpdateShipedOrderAWB(courierStatus:any[]):Observable<any[]>{
    const params={courierStatus:courierStatus}
    return this._http.post<any[]>(baseUrl+'/updateCourierStatusCheck.php',params).pipe(catchError(this.handleError.bind(this)))
  }
}
