import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from "@ngrx/store";
import { Observable, of } from 'rxjs';
import { ProductService } from "./product.service";
import * as ProductAction from "./product.actions";
import { mergeMap,map,catchError } from "rxjs/operators";
import { product} from "./product";
import { importType } from '@angular/compiler/src/output/output_ast';


@Injectable()
export class ProductEffects {
  constructor(private actions$: Actions,private _productServices:ProductService) {}
  loadProducts=createEffect(()=> {
    return this.actions$.pipe(
     ofType(ProductAction.ProductActionTypes.LoadProducts),
     mergeMap(
       action => this._productServices.fetchProducts().pipe(
         map((product) => new ProductAction.LoadProductsSuccess({data:product})),
         catchError(err=> of(new ProductAction.LoadProductsFailure({error:err})))
       )
     )
   )
 })

}
