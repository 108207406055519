import { Action } from '@ngrx/store';
import { dashboardPage } from './dashboardPage';
import { DashboardPageActionTypes,DashboardPageActions } from './dashboard-page.actions';


export const dashboardPageFeatureKey = 'DashboardPage';
export const postReducers='reducer';

export interface State {
  dashboardPage:dashboardPage[],
  error:string
}

export const initialState: State = {
  dashboardPage:[],
  error:""
};

export function reducer(state = initialState, action: DashboardPageActions): State {
  switch (action.type) {
    case DashboardPageActionTypes.LoadDashboardPages:
      return {
        ...state
      }
    case DashboardPageActionTypes.LoadDashboardPagesSuccess:
      return{
        ...state,
        dashboardPage:action.payload.data,
        error:""
      }
    case DashboardPageActionTypes.LoadDashboardPagesFailure:
      return {
        ...state,
        error:action.payload.error
      }

    default:
      return state;
  }
}
