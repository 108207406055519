import { query } from '@angular/animations';
import { Component, OnInit,ElementRef, ViewChild, AfterViewInit, NgModule } from '@angular/core';
import { AngularFrameworkComponentWrapper } from 'ag-grid-angular';
import { UserService } from './user.service';
import { LodingBarServiceService } from "../loading-bar/loding-bar-service.service";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AgGridAngular } from "ag-grid-angular";
import { EditButtonComponent } from '../edit-button/edit-button.component';
import { ActionButtonsComponent } from "../action-buttons/action-buttons.component";
import { TooltipComponent } from '../tooltip/tooltip.component';
import { FormControl, FormGroup, FormGroupDirective, NgModel, Validators } from '@angular/forms';
import { QuerySelector, ValueFormatterParams } from 'ag-grid-community';
import { Store,select } from "@ngrx/store";
import * as UserActions from '../../user.actions';
import * as fromUser from "../../user.selectors";
import * as productActions from '../../dashboard/product/product.actions'
import * as fromProduct from "../../dashboard/product/product.selectors"
import { IUser } from "../../user";
import { pendingUser } from './pendingUser';
import { element } from 'protractor';
import { GenderRendererComponent } from "../gender-renderer/gender-renderer.component";
import {TooltipPosition} from '@angular/material/tooltip';
import * as userAction from '../../user.actions'
import { Observable } from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { designation } from './designation';
import Swal from 'sweetalert2';
import { exit } from 'process';
import * as EmployeeActions from "../employee/employee.actions";
import * as fromEmployee from "../employee/employee.selectors";
import * as permissionAction from '../permissoin/permissoin.actions';
import * as fromPermission from '../permissoin/permission.selectors';
import { HttpClient } from '@angular/common/http';
import { product } from '../product/product'
import { permission } from '../permissoin/permission';
import { forEachChild } from 'typescript';
import { GroupCellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/groupCellRenderer';
import { HideForRolesDirective } from 'src/app/shared/directives/hide-for-roles.directive';
import { PermissoinService } from '../permissoin/permissoin.service';
import { UtilService } from 'src/app/utilities/util.service';



interface columnList {
  position: number;
  name:any,
  isActive:boolean
};

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css?2']
})

export class UsersComponent implements OnInit,AfterViewInit {
  
  HeaderTitle:string='Cosmic 2.0';
  pageTtitle:string='Users';
  recievedOrders:number=0;
  confirmOrders:number=0;
  finalOrders:number=0;
  newEmploye:number=0;
  userFilter:any="";
  isDialogOpned:boolean=false;
  paginationPageSize:number=19;
  sideBar:boolean = false;
  rowData!:IUser[];
  userPermission:any=[]
  public isCreateOrderDialogOpned:boolean=false;
  ShowHideColumnList: columnList[] = [];
  defaultColDef = {
    editable:true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:100,
    sideBar:true
  };
  loggenInUserId: any;


  
  customActionsForOnlineUsers(params:ValueFormatterParams){
    if(params.data.LS)
      return '<span>'+params.value+'</span><span><i class="fas fa-circle onlineUser"></i></span>';
    return '<span>'+params.value+'</span><span><i class="fas fa-circle offlineUser"></i></span>';
  }
  columnDefs = [
    {headerName: 'Action', cellRendererFramework:ActionButtonsComponent,hide:false},
    {headerName:'department',field:'department',cellRendererFramework:TooltipComponent,hide:false,cellEditorFramework:GenderRendererComponent},
    {headerName: 'Category', field: 'category',cellRendererFramework:TooltipComponent,hide:false},    
    {headerName: 'User Id', field: 'username',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Name', field: 'name',cellRenderer:this.customActionsForOnlineUsers.bind(this),hide:false},    
    {headerName:'Password', field:'password',cellRendererFramework:TooltipComponent,hide:true},
  ];  

  @ViewChild ('userList',{read:ElementRef}) 
  userListQ!:ElementRef

  @ViewChild('userList')
  agGrid!:AgGridAngular

  
  constructor(  private _permissionService:PermissoinService,private _util:UtilService,private _userService:UserService,private lbs: LodingBarServiceService,public dialog:MatDialog,private store:Store,private _actionButton:ActionButtonsComponent) {
   
  } 
 

   
  ngOnInit(): void {     
    this.lbs.showBar(true);  
    this.alluser();
    this.columnDefs.forEach((clm,index)=>{
      if(clm.headerName=='Password'){
        this.ShowHideColumnList.push({ position:index, name: clm.field?.toLocaleLowerCase(), isActive: false });
      }else if(clm.headerName=='Action'){
        this.ShowHideColumnList.push({ position:index, name: clm.headerName?.toLocaleLowerCase(), isActive: true });
      }else{
        this.ShowHideColumnList.push({ position:index, name: clm.field?.toLocaleLowerCase(), isActive: true });
      }      
    })    
  }  

  toggleColumn(column:columnList){
    if(column.isActive == false){
      this.agGrid.columnApi.setColumnVisible(column.name,false);  
    }else{      
      this.agGrid.columnApi.setColumnVisible(column.name,true);     
    }
  }

  private alluser(){
    this.store.dispatch(new UserActions.LoadUsers()) //Action dispatch
    this.store.pipe(select(fromUser.getUsers)).subscribe(
      users =>{
        this.rowData=users;
      }
    )    
  } 
  
  clear() {
    this.lbs.clear()
  }
  refreshUser(){
    this.alluser();
  }
  ngAfterViewInit(){
    // this.filterElementRef.nativeElement.focus();
    // this.userListQ.nativeElement.style.height=window.innerHeight-152+'px';
    this.lbs.clear()    
  }

  loginSetup(){
    if(!this.isDialogOpned){
      const dialogRef = this.dialog.open(LoginSetupModal,{
        width: '550px',height:'421px'
      });   
      this.isDialogOpned = !this.isDialogOpned;
      dialogRef.afterClosed().subscribe(result => {
        this.isDialogOpned = !this.isDialogOpned;
        console.log(`Dialog result: ${result}`);
      });
    }
  }
  loginHistory(){

  }
  onResize(event:any){
    console.log("onresize:",event)
  }
  permissionSetup(){
    this.store.dispatch(new userAction.EditUserPermission())
    //this.store.dispatch(new OrderAction.EditOrder({id}));
    //this.store.dispatch(new OrderAction.OpenEditableOrder({}))
    if(!this.isCreateOrderDialogOpned){
      const dialogRef = this.dialog.open(EditUserPermissiondDialogComponent,{
        width: '1000px',
        disableClose:true
      });   
      this.isCreateOrderDialogOpned = !this.isCreateOrderDialogOpned;
      dialogRef.afterClosed().subscribe(result => {
        this.isCreateOrderDialogOpned = !this.isCreateOrderDialogOpned;
        console.log(`Dialog result: ${result}`);
      });
    }
  }


}

@Component({
  selector: 'login-setup-modal',
  templateUrl:'loginSetupModal.html',
  styleUrls:['users.component.css'],
})
export class LoginSetupModal implements OnInit{

  @ViewChild('pendingUserList')
  pendingUserListQ!: AgGridAngular;

  frameworkComponents: any;
  api: any;
  gridApi:any
  pendingUsers!:pendingUser[];
  paginationPageSize = 10;
  defaultColDef = {
    editable: true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 100
  };
  columnDefs = [
    {headerName: 'Id', field: 'id',unSortIcon: true,width:'100px',/* checkboxSelection:true,*/cellRendererFramework:TooltipComponent},
    {headerName:'department',field:'department',width:'150px',unSortIcon:true,cellRendererFramework:TooltipComponent},
    {headerName: 'First Name', field: 'fname',unSortIcon: true,cellRendererFramework:TooltipComponent},
    {headerName: 'Last Name', field: 'lname',unSortIcon: true,cellRendererFramework:TooltipComponent},
    {headerName: 'Activate', cellRendererFramework:EditButtonComponent}
  ];

  onGridReady(params:any){
    this.gridApi = params.api;
  }
  constructor(private _userService:UserService){
    
  }
  // autoGroupColumnDef = {
  //   headerName: 'department',
  //   field:'department'
  // };
  pendingUser(){
    this._userService.pendingUsers().subscribe(payload =>{
      this.pendingUsers=payload;
    })
  }

  GetSelectedRow(){
    const selectedRows = this.pendingUserListQ.api.getSelectedNodes();
    const selectedData = selectedRows.map(node => node.data);
    const selectedDataStringPagination= selectedData.map(node => node.id).join(",");
    console.warn(selectedDataStringPagination);
  }

  ngOnInit(): void {
    this.pendingUser();
  }

}


interface tempUser{
  id:number,
  name:string 
}
// export interface designation{
//   id:number,
//   category:string,
//   department:string,
//   status:boolean
// }
@Component({
  selector: 'edit-user-permission-component',
  templateUrl: 'edit-user-permission-component.html',
  styleUrls: ['users.component.css']
})
export class EditUserPermissiondDialogComponent implements OnInit {  
  designationList:designation[]=[]
  filteredDesignationList!:Observable<designation[]>
  EditUserForm!:FormGroup
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredFruits!: Observable<tempUser[]>;
  fruits: tempUser[]=[]
  allFruits: tempUser[]=[]
  exits:boolean=false;
  productList!:product[];
  permissionListFiltered!:Observable<permission[]>;
  permissionList!:permission[];
  productSelected:any[]=[];
  permissionSelected:any[]=[];
  userSelected:any[]=[];

  @ViewChild('fruitInput')
  fruitInput!: ElementRef<HTMLInputElement>;

  constructor(private store:Store,private _userService:UserService,private dialogRef: MatDialogRef<EditUserPermissiondDialogComponent>){
    this.EditUserForm=new FormGroup({
      user:new FormControl([]),
      Permission:new FormControl([]),
      designation:new FormControl(),
      products: new FormControl([])
    })

    this._userService.getDesignation().subscribe(payload=>this.designationList=payload);

    //B. PULL USERS FROM STOER
    this.store.dispatch(new userAction.LoadUsers);
    this.store.pipe(select(fromUser.getUsers)).subscribe(      
      user=> {
        console.log(user,"==")
        user.map(ele=>this.allFruits.push({id:ele.id,name:ele.fname+' '+ele.lname}));
      }
    )
    //E. PULL USERS FROM STOER
    this.store.dispatch(new permissionAction.LoadPermissoins);
    this.store.pipe(select(fromPermission.getPermission)).subscribe(
      permission=>{
        this.permissionList=permission
      }
    )

    //B.FILTER THE USER LIST AS TYPING LETTER
    console.log(this.allFruits.length,'ere',this.filteredFruits)
    this.filteredFruits=this.EditUserForm.controls.user.valueChanges.pipe(
      startWith(null),
      map((fruit:string | null)=>(fruit ? this._filter(fruit):this.allFruits.slice()))
    )
    console.log(this.allFruits.length,'df',this.filteredFruits);
    //E.FILTER THE USER LIST AS TYPING LETTER

    //B. PULL DESIGNATION LIST   

    this.filteredDesignationList= this.EditUserForm.controls.designation.valueChanges.pipe(
      startWith(''),
      map(value => this._filterDesignation(value))
    )
    // this.filteredDesignationList= this.EditUserForm.controls.designation.valueChanges.pipe(
    //   startWith(null),
    //   map((designation:string | null)=>(designation ? this._filterDesignation(designation):this.designationList.slice()))
    // )
    //E. PULL DESIGNATION LIST
    
    //B.PULL THE PRODUCT LIST
      this.store.dispatch(new productActions.LoadProducts);
      this.store.pipe(select(fromProduct.getProduct)).subscribe(
        product=> this.productList=product
      )
    //E.PULL THE PRODUCT LIST
  }
  //B.REMOVW USER SELECTED USER CHIPS 
  remove(fruit: tempUser): void {
    this.fruits.forEach((ele,index)=>{
      if(ele.id==fruit.id)
        this.fruits.splice(index,1);
    })
    this.userSelected=this.userSelected.filter(user => user != fruit.id);
    this.EditUserForm.controls.user.setValue(this.userSelected)
  }
  //B.REMOVW USER SELECTED USER CHIPS 

  selected(event: MatAutocompleteSelectedEvent) {
      if(this.fruits.length >0){
        this.fruits.forEach((ele)=>{
          if(ele.id==event.option.value.id){
            this.exits=true;
            Swal.fire({
              title: 'OOPS...USER ALREADY ADDED',
              text: 'Please Select Another One!',
              icon:'error',
              width: 600,
              padding: '3em',
              background: '#fff',
              customClass: {
                container:'swalbackgroundMargin'
              },
              focusConfirm: false,
              confirmButtonText:'<i class="fa fa-thumbs-down"></i> Cancel',
            });
            this.EditUserForm.controls.user.setValue(this.userSelected);
          }       
        })
        if(this.exits==false){
          this.fruits.push({id:event.option.value.id,name:event.option.value.name});
          this.fruitInput.nativeElement.value = '';
          this.userSelected.push(event.option.value.id);
          this.EditUserForm.controls.user.setValue(this.userSelected);
        }
        this.exits=false;
      }
      if(this.fruits.length==0){
        this.fruits.push({id:event.option.value.id,name:event.option.value.name});
        this.fruitInput.nativeElement.value = '';
        this.userSelected.push(event.option.value.id);
        this.EditUserForm.controls.user.setValue(this.userSelected);
      }
  }
  private _filter(value: string): tempUser[] {
    this.allFruits=this.allFruits.filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i)
    if(Object.prototype.toString.call(value) === "[object String]"){
      const filterValue = value.toLowerCase();
      return this.allFruits.filter(fruit => fruit.name.toLowerCase().includes(filterValue));
    }else{
      return this.allFruits;
    }
  }
  private _filterDesignation(value:string):any[]{
    const filterValue = value.toLowerCase();
    return this.designationList.filter(designation => designation.category.toLowerCase().indexOf(filterValue) === 0 );
  }

  // private _filterDesignation(value: string): any[] {
  //   this.designationList=this.designationList.filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i)
  //   if(Object.prototype.toString.call(value) === "[object String]"){
  //     const filterValue = value.toLowerCase();
  //     return this.designationList.filter(designation => designation.category.toLowerCase().includes(filterValue));
  //   }else{
  //     return this.designationList;
  //   }
  // }
 
  ngOnInit(): void {   

  }

  EditUserAction(formDirective:FormGroupDirective){    
    this._userService.createPermission(formDirective.value).subscribe(payload=>{
      if(payload){
        console.log(payload)
        this.dialogRef.close();
      }else{
        console.log(payload,'df');
        Swal.fire({
          title: 'Some Error In Selection',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        })
      }
                
    })
  }
  getDesignationTitle(designationID:number):any{
    return this.designationList.find((designation)=>designation.id==designationID)?.category
  }
  onSelectionChange(event:any){
    // apply default permission decided
  }
  onProductSelection(event:any,list:any){
    if(event.option.selected && this.productSelected.indexOf(event.option.value) == -1){
      this.productSelected.push(event.option.value);
      this.EditUserForm.controls.products.setValue(this.productSelected)
    }else {
      this.productSelected=this.productSelected.filter(item=> item != event.option.value)
      this.EditUserForm.controls.products.setValue(this.productSelected)
    }
  }
  onPermissionSelection(event:any,list:any){
    if(event.option.selected && this.permissionSelected.indexOf(event.option.value) == -1){
      this.permissionSelected.push(event.option.value);
      this.EditUserForm.controls.Permission.setValue(this.permissionSelected)
    }else {
      this.permissionSelected=this.permissionSelected.filter(item=> item != event.option.value)
      this.EditUserForm.controls.Permission.setValue(this.permissionSelected)
    }
  }
}





function filterDivElementvar(arg0: string, filterDivElementvar: any) {
  throw new Error('Function not implemented.');
}

