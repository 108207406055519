import { Action } from '@ngrx/store';
import { Course } from './course';

export enum CourseActionTypes {
  LoadCourses = '[Course] Load Courses',
  LoadCoursesSuccess = '[Course] Load Courses Success',
  LoadCoursesFailure = '[Course] Load Courses Failure',
}

export class LoadCourses implements Action {
  readonly type = CourseActionTypes.LoadCourses;
}

export class LoadCoursesSuccess implements Action {
  readonly type = CourseActionTypes.LoadCoursesSuccess;
  constructor(public payload: { data: Course[] }) { }
}

export class LoadCoursesFailure implements Action {
  readonly type = CourseActionTypes.LoadCoursesFailure;
  constructor(public payload: { error: string }) { }
}

export type CourseActions = LoadCourses | LoadCoursesSuccess | LoadCoursesFailure;

