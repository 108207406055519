import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { employee } from '../employee/employee';
import { product } from '../product/product';
import { SettingService } from './setting.service';
import { OrderStatus } from "./orderStatus";
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Shift } from './shift';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css'],
  animations: [
    trigger('widthGrow', [
        state('closed', style({
          height:  0
        })),
        state('open', style({
          height: '80px'
        })),
        transition('* => *', animate(80))
    ]),
]
})
export class SettingComponent implements OnInit {
  pageTitle:string="Setting";
  step:string="step1";  
  state:string= "closed";
  addOrderStatus!:FormGroup;
  CreateOrderSectionSwitch:boolean=false
  ProductRowData!:product;
  EmployeeRowData!:employee;
  paginationPageSize:number=19;
  StatusCategoryList=['Order','Tracking']
  sideBar:any;
  rowData!:OrderStatus[];
  shifts!:Shift[];
  defaultColDef = {
    editable:true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:100,
    sideBar:true
  };
  columnDefs = [
    {headerName: 'Id', field: 'id',rowDrag:true,hide:false},
    {headerName: 'Status Title', field: 'name',hide:false},
    {headerName: 'Category', field: 'category',hide:false}
  ] 

  shiftColumnDefs=[
    {headerName: 'Id', field: 'id',rowDrag:true,hide:false},
    {headerName: 'Shift ', field: 'shiftName',hide:false},
    {headerName: 'Status', field: 'status',hide:false}
  ]

  constructor(private _settingService:SettingService) { 
    this.addOrderStatus=new FormGroup({
      StatusName:new FormControl('',[Validators.required]),
      StatusCategory: new FormControl('',[Validators.required])
    })
  }

  ngOnInit(): void {
    this.allStatus();
    this._settingService.fetchShift().subscribe(payload=>this.shifts=payload);
  }
  private allStatus(){
    this._settingService.fetchOrderStatus().subscribe((payload)=>{
      if(payload)
        this.rowData=payload;
    })
  }
  onResize(e:any){

  }
  submitOrderStatus(formDirective:FormGroupDirective){
    console.log(formDirective.value);
    this._settingService.createOrderStatus(formDirective.value).subscribe((payload)=>{
      this.allStatus()
    })
  }
  AddStatus(){
    console.log(this.state);
    (this.state === "closed") ? this.state = "open" : this.state = "closed";
    console.log(this.state);
    this.CreateOrderSectionSwitch=!this.CreateOrderSectionSwitch    
  }




}
