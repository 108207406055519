<mat-dialog-content class="mat-typography">
    <h2 mat-dialog-title style="text-align: center;">Shipping Logs</h2>
    <div class="example-container">
        <section class="content" id="renderDataSection">
            <div class="container-fluid">
              <!-- Info boxes -->    
              <div class="row" >
                    <ag-grid-angular 
                    #LogList
                    id='LogList'
                    style="width: 1470px; height: 235px;"
                    class="ag-theme-balham"
                    [sideBar]="sideBar"
                    [rowData]="LogList" 
                    [defaultColDef]="defaultColDef"
                    [columnDefs]="columnDefs"
                    [animateRows]='false'
                    [pagination]='true'
                    [paginationPageSize]="paginationPageSize"
                    (onColumnResized)="onResize($event)"              
                    >
                    </ag-grid-angular>
                </div>
            </div>
            <mat-dialog-actions align="end">
                <button mat-raised-button mat-dialog-close>Close</button>                
            </mat-dialog-actions>
        </section>
    </div>
</mat-dialog-content>