import { Action } from '@ngrx/store';
import { permission } from "./permission";

export enum PermissoinActionTypes {
  LoadPermissoins = '[Permissoin] Load Permissoins',
  LoadPermissoinsSuccess = '[Permissoin] Load Permissoins Success',
  LoadPermissoinsFailure = '[Permissoin] Load Permissoins Failure',
  
}

export class LoadPermissoins implements Action {
  readonly type = PermissoinActionTypes.LoadPermissoins;
}

export class LoadPermissoinsSuccess implements Action {
  readonly type = PermissoinActionTypes.LoadPermissoinsSuccess;
  constructor(public payload: { data:permission[] }) { }
}

export class LoadPermissoinsFailure implements Action {
  readonly type = PermissoinActionTypes.LoadPermissoinsFailure;
  constructor(public payload: { error: string }) { }
}


export type PermissoinActions = LoadPermissoins | LoadPermissoinsSuccess | LoadPermissoinsFailure ;

