import { Action } from '@ngrx/store';
import { orderStatus } from './order-status.service';
import { OrderStatus } from './orderStatus';

export enum OrderStatusActionTypes {
  LoadOrderStatuss = '[OrderStatus] Load OrderStatuss',
  LoadOrderStatussSuccess = '[OrderStatus] Load OrderStatuss Success',
  LoadOrderStatussFailure = '[OrderStatus] Load OrderStatuss Failure',
}

export class LoadOrderStatuss implements Action {
  readonly type = OrderStatusActionTypes.LoadOrderStatuss;
}

export class LoadOrderStatussSuccess implements Action {
  readonly type = OrderStatusActionTypes.LoadOrderStatussSuccess;
  constructor(public payload: { data: OrderStatus[] }) { }
}

export class LoadOrderStatussFailure implements Action {
  readonly type = OrderStatusActionTypes.LoadOrderStatussFailure;
  constructor(public payload: { error: any }) { }
}

export type OrderStatusActions = LoadOrderStatuss | LoadOrderStatussSuccess | LoadOrderStatussFailure;

