import { Component, Inject, OnInit } from '@angular/core';
import { animate, animation, state, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Time } from '@angular/common';
import { start } from 'repl';
import Swal from 'sweetalert2';
import { ShiftService } from '../shift/shift.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromShift from '../shift/shift.selectors'
import * as shiftAction from '../shift/shift.actions'
import { Shift } from '../shift/Shift';
import { EditOrderdDialogComponent, OrderActionButtonComponent } from '../order-action-button/order-action-button.component';


@Component({
  selector: 'app-shift-action-button',
  templateUrl: './shift-action-button.component.html',
  styleUrls: ['./shift-action-button.component.css']
})
export class ShiftActionButtonComponent implements OnInit {
  paramsData: any;
  completeParamView: any;
  isEditShiftDialogOpned:boolean=false;
  disableToggle:boolean=true
  nextParams: any;
  agInit(params:any){
    this.paramsData=params.data;
    this.completeParamView=params;
    this.nextParams=params
  }
  constructor(private dialog:MatDialog,private _shiftService:ShiftService) { }
  ngOnInit(): void {
    if(this.completeParamView.data.status==true){      
      this.disableToggle=true;
    }else{
      this.nextParams.eParentOfValue.offsetParent.childNodes.forEach((_element: any) => {
        _element.className +=" disableClass";
      });
      this.disableToggle=false;
    }
  }
  EditShift(event:any){
    if(!this.isEditShiftDialogOpned){
      console.log("data in reorer=",this.paramsData.id);
      const dialogRef = this.dialog.open(EditShiftDialog,{
        data:{shiftID:this.paramsData.id},
        width: '500px',
        disableClose:false        
      });   
      this.isEditShiftDialogOpned = !this.isEditShiftDialogOpned;
      dialogRef.afterClosed().subscribe(result => {
        this.isEditShiftDialogOpned = !this.isEditShiftDialogOpned;
        console.log(`Dialog result: ${result}`);
      });
    }
  }
  DisableShift(){
    this._shiftService.disableShift(this.paramsData.id).subscribe(()=>{
      this.nextParams.eParentOfValue.offsetParent.childNodes.forEach((_element: any) => {
        console.log(_element.className.indexOf("disableClass"));
        if(_element.className.indexOf("disableClass")!= -1){
          _element.className = _element.className.replace('disableClass','');
        }else{
          _element.className += " disableClass";
        }        
      });
     })
  }

}

@Component({
  selector: 'edit-shift-dialog',
  templateUrl:'editShiftDialog.html',
  styleUrls:['shift-action-button.component.css'],
  animations:[
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [ style({ opacity: 0 }), animate(600) ]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})


export class EditShiftDialog implements OnInit{  
  editShiftForm:FormGroup;
  // successHeader:string="Edit Shift"
  paramsData: any;
  completeParamView: any;
  successHeader:boolean=false
  duration:any=0
  startvalue:any
  endvalue:any
  shiftID:any
  errorExist:boolean=false;
  constructor(private EditShiftDialogRef: MatDialogRef<EditShiftDialog>,private _shiftService:ShiftService,private store:Store,@Inject(MAT_DIALOG_DATA) public data: any){
    console.log(this.data,"shift ID");
    this.shiftID=this.data.shiftID;
    this.editShiftForm = new FormGroup({
      id:new FormControl('',[Validators.required]), 
      startDate: new FormControl('',[Validators.required]),
      endDate: new FormControl('',[Validators.required]),
      duration:new FormControl('',[Validators.required])
    })
  }
  agInit(params:any){
    this.paramsData=params.data;
    this.completeParamView=params;
  }
  ngOnInit(): void {
    const shift$:Observable<Shift[]>= this.store.select(fromShift.getShift)  
    shift$.subscribe(shift => {
      shift.filter(singleShift=>{
        if(singleShift.id == this.shiftID){
          console.log(singleShift)
          this.editShiftForm.patchValue({
            id:singleShift.id,
            startDate:singleShift.startTime,
            endDate:singleShift.endDate,
            duration:singleShift.duration
          })
          this.duration=singleShift.duration;
        }
      })
    });
  }
  updateShiftAction(formDirective:FormGroupDirective){
    console.log(formDirective.value,this.duration)
    this._shiftService.updateShift(formDirective.value).subscribe(payload=>{
      if(payload){
        this.EditShiftDialogRef.close()
      }
    })
  }
  convertToTime(seconds:any){
    var d = Number(seconds);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    console.log("sec=",d,"h=",h,"m=",m)

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes ") : "";
    console.log(hDisplay + mDisplay)    
    this.duration=h+'.'+m;
    this.editShiftForm.controls['duration'].setValue(this.duration);
    console.log(this.duration,"df");
    return h+'.'+m
  }
  calculateHours(event:any){   
      var name=(event.target as HTMLInputElement).name;    
      if(name == 'startDate'){
        this.startvalue=(event.target as HTMLInputElement).value
        this.endvalue=this.editShiftForm.controls['endDate'].value
      }        
      if(name == 'endDate'){
        this.endvalue=(event.target as HTMLInputElement).value
        this.startvalue=this.editShiftForm.controls['startDate'].value
      }        
      if(this.startvalue && this.endvalue){
        var durationSeconds;
        var endvalueinSec=this.endvalue.split(':').reduce((acc:any,time:any) =>(60 *60 *acc) + +time*60)
        var startvalueinSec=this.startvalue.split(':').reduce((acc:any,time:any) => (60* 60*acc) + +time*60)       
        if(startvalueinSec>=43200){
            durationSeconds=(endvalueinSec+86400)-startvalueinSec;
            this.convertToTime(durationSeconds);
        }
        if(endvalueinSec >=43200){
          if(!(startvalueinSec>endvalueinSec)){
              this.duration=this.convertToTime(endvalueinSec-startvalueinSec)
            }else{
              this.duration=this.convertToTime((startvalueinSec-endvalueinSec))              
            }
        }
      }else{
        console.log("one is empty")
      }
  } 
}

