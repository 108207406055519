<div id="userCardTitle">
  <span class="userCardWrapper">
    <strong class="userCardHeaderName">{{userLoggedInData.fname+" "+userLoggedInData.lname | uppercase }}</strong>
    <strong class="userCardDepartment">Online &nbsp;<i class="fas fa-circle"></i></strong>
    
  </span>
  <span (click)="OpenUserCard()"><i class="fas fa-angle-double-down"></i></span>
</div>
<mat-card class="example-card" *ngIf='showCard'>
  <mat-card-header>
    <div mat-card-avatar class="example-header-image">
      <img src="https://material.angular.io/assets/img/examples/shiba1.jpg" alt="">
    </div>
    <mat-card-title class="userCardHeaderName">{{userLoggedInData.fname+" "+userLoggedInData.lname | uppercase }}</mat-card-title>
    <mat-card-subtitle class="userCardDepartment">{{userLoggedInData.department | uppercase}}{{"-"+userLoggedInData.category | titlecase }}</mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <mat-card-subtitle class="userCardShrtProf">
      <span><strong class="idSection"><i class="fas fa-id-badge"></i> : </strong>{{userLoggedInData.id}}</span>
      <span><strong class="mobileSection"><i class="fas fa-mobile-alt"></i> : </strong>{{userLoggedInData.mobile}}</span>
      <span><strong class="emailSection"><i class="fas fa-envelope-open-text"></i> : </strong>jkuldeep@cosmicplus.com</span>
    </mat-card-subtitle>      
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button color="accent" (click)="Logout()">
      <i class="fas fa-sign-out-alt"></i>
      Logout
    </button>
  </mat-card-actions>
</mat-card>
