import { createFeatureSelector, createSelector } from '@ngrx/store';
import {State  } from "./product.reducer";

const getPoductFeatureState = createFeatureSelector<State>('product');

export const getProduct = createSelector(
    getPoductFeatureState,
    state => state.product
)

export const getError = createSelector(
    getPoductFeatureState,
    state => state.error
)
export const createProduct= createSelector(
    getPoductFeatureState,
    state=>state.product
)



