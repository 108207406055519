import { createFeatureSelector, createSelector } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { State } from "./order-action-button.reducer";

const getOrderActionButtonFeatureState=createFeatureSelector<State>('orderActionButton');

export const getOrders = createSelector(
    getOrderActionButtonFeatureState,
    state => state.orders
)

export const getError = createSelector(
    getOrderActionButtonFeatureState,
    state => state.error
)
export const createOrder= createSelector(
    getOrderActionButtonFeatureState,
    state=>state.orders
)
export const getEditOrders = createSelector(
    getOrderActionButtonFeatureState,
    state => state.editOrder
)
