import { Action } from '@ngrx/store';
import { employee } from './employee';
import { EmployeeActions,EmployeeActionTypes } from './employee.actions';


export const employeeFeatureKey = 'employeeState';
export const postReducers='reducer';

export interface State {
  employee: employee[],
  error: string
}

export const initialState: State = {
  employee:[],
  error:""
};

export function reducer(state = initialState, action: EmployeeActions): State {
  switch (action.type) {
    case EmployeeActionTypes.LoadEmployees:
      return {
        ...state
      }

    case EmployeeActionTypes.LoadEmployeesSuccess:
      //var tempEmpList=action.payload.data.filter(employee=>employee.isDeleted == false)
      return {
        ...state,
        employee: action.payload.data,
        error:""
      }

    case EmployeeActionTypes.LoadEmployeesFailure:
      return {
        ...state,
        employee: [],
        error: action.payload.error
      }
    case EmployeeActionTypes.DeactivateEmployee:
      return {
        ...state,
        employee:action.payload.data
      }
    case EmployeeActionTypes.DeleteEmployee:
      var stateEmployee=state.employee.filter(employee=>employee.id !== action.payload.id)
      return {
        ...state,
        employee:stateEmployee
      }
    default:
      return state;
  }
}
