import { Action } from '@ngrx/store';
import { permission } from './permission';
import { PermissoinActions,PermissoinActionTypes } from "./permissoin.actions";


export const permissionFeatureKey = 'permission';

export interface State {
  permission:permission[];
  error:string
}

export const initialState: State = {
  permission:[],
  error:''
};

export function reducer(state = initialState, action: PermissoinActions): State {
  switch (action.type) {

    case PermissoinActionTypes.LoadPermissoins:
      return{
        ...state
      }
    case PermissoinActionTypes.LoadPermissoinsSuccess:
      return{
        ...state,
        permission:action.payload.data,
        error:''
      }
    case PermissoinActionTypes.LoadPermissoinsFailure:
      return{
        ...state,
        error:action.payload.error
      }
    default:
      return state;
  }
}
