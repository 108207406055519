<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-1">
          <div class="col-sm-6">
            <h2 class="m-0">{{pageTtitle | uppercase}}</h2>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="" (click)="permissionSetup()" *appHideForRoles="['33']"><button mat-mini-fab color="accent" type="reset" #tooltip="matTooltip"
                matTooltip="Permission Setup"><i class="fas fa-tools"></i></button></li>                      
              <li class="" (click)="loginSetup()" *appHideForRoles="['34']"><button mat-mini-fab color="accent" type="reset" #tooltip="matTooltip"
                matTooltip="Login Setup"><i class="fas fa-users-cog"></i></button></li>
              <!-- <li class="" (click)="loginHistory()"><button mat-mini-fab color="accent" type="reset" #tooltip="matTooltip" -->
                <!-- matTooltip="Login History"> <i class="fas fa-history"></i></button></li> -->
              <li class="" (click)="refreshUser()"><button mat-mini-fab color="accent" type="reset" #tooltip="matTooltip"
                matTooltip="Refresh List"> <i class="fas fa-sync-alt" aria-hidden="true"></i></button></li>      
              <li *appHideForRoles="['36']">
                <button mat-icon-button color="accent" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="mainMenuButton">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #mainMenuButton="matMenu">                  
                    <button mat-menu-item (click)="$event.stopPropagation()" *ngFor="let column of ShowHideColumnList;index as i">
                      <mat-slide-toggle [(ngModel)]="column.isActive" (change)="toggleColumn(column)" class="toggleMenuSlideText"></mat-slide-toggle>
                      <span>{{column.name | titlecase}}</span><br>
                    </button>
                </mat-menu>
              </li>
            </ol>
          </div>
          <!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content" id="renderDataSection">
      <div class="container-fluid">
        <!-- Info boxes -->            
        <div class="row" >
        <ag-grid-angular 
            #userList
            style="width: 1470px; height: 500px;"
            class="ag-theme-balham"
            [sideBar]="sideBar"
            [rowData]="rowData" 
            [defaultColDef]="defaultColDef"
            [columnDefs]="columnDefs"
            [animateRows]='true'
            [pagination]='true'
            [paginationPageSize]="paginationPageSize"
            (onColumnResized)="onResize($event)"
             >
        </ag-grid-angular>
          <!-- /.col -->
        </div>
        <!-- /.row -->

     
        <!-- /.row -->

        <!-- Main row -->
      
        <!-- /.row -->
      </div><!--/. container-fluid -->
    </section>
    <!-- /.content -->
  </div>
  <style>

  </style>