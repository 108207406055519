import { Component, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { PermissionAssignedDialog } from '../action-buttons/action-buttons.component';
import { permission } from '../permissoin/permission';
import { PermissoinService } from '../permissoin/permissoin.service';

@Component({
  selector: 'app-permision-action-button',
  templateUrl: './permision-action-button.component.html',
  styleUrls: ['./permision-action-button.component.css']
})
export class PermisionActionButtonComponent implements OnInit {
  componentView: any;
  params: any;
  nextParam: any;
  userID:any;
 

  constructor(private _permissionService:PermissoinService, private _PermissionAssignedDialog:PermissionAssignedDialog) { }
  agInit(params:any){
    this.componentView=params.value;
    this.params=params;
    this.nextParam=params;
    this.userID=this._PermissionAssignedDialog.user()
  }

  ngOnInit(): void {
    
  }

  RemovePermission(event:any){
    this._permissionService.RemoveUserPermision(this.params.data.code,this.userID).subscribe(payload=>{
      Swal.fire({
        title: 'Permission Removed',
        icon:'success',
        width: 600,
        padding: '3em',
        background: '#fff',
        customClass: {
          container:'swalbackgroundMargin',
          // popup:'popupcustom'
        },
        focusConfirm: false,
        confirmButtonText:'<i class="fa fa-exclamation-circle" aria-hidden="true"></i> OK',
      })
    })
  }
}


