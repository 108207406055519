import { Action } from '@ngrx/store';
import { LoginAudit } from './login-audit';
import { LoginAuditActions,LoginAuditActionTypes } from "./login-audit.actions";


export const loginAuditFeatureKey = 'loginAudit';

export interface State {
  loginDetails: LoginAudit[],
  error: string
}

export const initialState: State = {
  loginDetails:[],
  error:""
};

export function reducer(state = initialState, action: LoginAuditActions): State {
  switch (action.type) {
    case LoginAuditActionTypes.LoadLoginAudits:
      return {
        ...state
      }

    case LoginAuditActionTypes.LoadLoginAuditsSuccess:
      //var tempEmpList=action.payload.data.filter(employee=>employee.isDeleted == false)
      return {
        ...state,
        loginDetails: action.payload.data,
        error:""
      }

    case LoginAuditActionTypes.LoadLoginAuditsFailure:
      return {
        ...state,
        loginDetails: [],
        error: action.payload.error
      }
    default:
      return state;
  }
}
