import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { DashboardPageComponent } from "./dashboard-page/dashboard-page.component";
import { HeaderComponent } from './header/header.component';
import { LeftMenuSectionComponent } from './left-menu-section/left-menu-section.component';
import { UsersComponent } from './users/users.component';
import { AppRoutingModule } from "./dashboard-routing.module";
import { FooterComponent } from './footer/footer.component';
import { UserCardComponent } from './user-card/user-card.component';
import { OrdersComponent } from './orders/orders.component';
import { ReportComponent } from './report/report.component';
import { MailboxComponent } from './mailbox/mailbox.component';
import { ProductComponent } from './product/product.component';
import { WidgetsComponent } from './widgets/widgets.component';
import { HelpComponent } from './help/help.component';
import { GallaryComponent } from './gallary/gallary.component';
import { CalendarComponent } from './calendar/calendar.component';
import { MatDialogModule } from '@angular/material/dialog';
import { WaitingImageComponent } from './waiting-image/waiting-image.component';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';
import { EditButtonComponent } from './edit-button/edit-button.component';
import { ActionButtonsComponent } from './action-buttons/action-buttons.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { SlideToggleComponent } from './slide-toggle/slide-toggle.component';
import { GenderRendererComponent } from './gender-renderer/gender-renderer.component';
import { EmployeeComponent } from './employee/employee.component';
import { EmployeeActionBUttonsComponent } from './employee-action-buttons/employee-action-buttons.component';
import { CourierComponent } from './courier/courier.component';
import { SettingComponent } from './setting/setting.component';
import { CourseComponent } from './course/course.component';
import { ShiftComponent } from './shift/shift.component';
import { DigitalClockComponent } from './digital-clock/digital-clock.component';
import { ReportActionButtonComponent } from './report-action-button/report-action-button.component';
import { DomainComponent } from './domain/domain.component';
import { OnlineUsersComponent } from './online-users/online-users.component';
import { ShippingDetailsComponent } from './shipping-details/shipping-details.component';
import { LeastOrderConfirmationAgentComponent } from './least-order-confirmation-agent/least-order-confirmation-agent.component';
import { PermisionActionButtonComponent } from './permision-action-button/permision-action-button.component';
import { DoughnutComponent } from './doughnut/doughnut.component';
import { OrderStatusComponent } from './order-status/order-status.component';
import { OrderStatusActionButtonComponent } from './order-status-action-button/order-status-action-button.component';
import { ShiftActionButtonComponent } from './shift-action-button/shift-action-button.component';


//import { CreateUserDialogComponent } from './create-user-dialog/create-user-dialog.component';

@NgModule({
  declarations: [
  // //DashboardPageComponent,
  //   HeaderComponent,
  //   LeftMenuSectionComponent,
  //   UsersComponent,
  //   FooterComponent
  // UserCardComponent,
  // OrdersComponent,
  // ReportComponent,
  // MailboxComponent,
  // ProductComponent,
  // WidgetsComponent,
  // HelpComponent,
  // GallaryComponent,
  // CalendarComponent,
  // CreateUserDialogComponent
  //WaitingImageComponent
  //LoadingBarComponent
  //EditButtonComponent
  //ActionButtonsComponent
  //TooltipComponent
  //EmployeeActionBUttonsComponent
  
  //   CourierComponent,
  // SettingComponent
  
    // CourseComponent
  
  //   ShiftComponent,
  // OrderActionButtonComponent
  
    //DigitalClockComponent
  
  
    //ReportactionbuttonComponent,
  
  
    //DomainComponent
  
    
  
    //OnlineUsersComponent
  
    //ShippingDetailsComponent
  
    //LeastOrderConfirmationAgentComponent,
  //PermisionActionButtonComponent,
  //DoughnutComponent
  
    //OrderStatusComponent
  
    //OrderStatusActionButtonComponent
  
    // ShiftActionButtonComponent
  ],
  imports: [
    // CommonModule,
    // AppRoutingModule

  ],
  exports:[
 
  ],
  bootstrap: []
})
export class DashboardModule { }
