<form class="registrationForm" (ngSubmit)="registerAction(formDirective)" [formGroup]="registerForm" #formDirective="ngForm">
<mat-dialog-content class="mat-typography"> 
  <h5 class="successHeader" *ngIf='successHeader' [@fade]="'out'">User Created Successfully</h5>
  <h5 class="errorHeader" *ngIf='errorHeader' [@fade]="'out'">Some Error</h5>
  <h2 mat-dialog-title style="text-align: center;">User Registration</h2>
  <div class="example-container">
    <mat-form-field appearance="fill">
      <mat-label>First Name</mat-label>
      <input matInput placeholder="Enter your first name" formControlName="fname" required>
      <mat-error *ngIf="registerForm.controls['fname'].hasError('required')">
        This is <strong>required</strong> field
      </mat-error>
      <mat-error *ngIf="registerForm.controls['fname'].hasError('pattern')">
        Only Alphbates can be accepted
      </mat-error> 
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Last Name</mat-label>
      <input matInput placeholder="Enter your last name" formControlName="lname" required>
      <mat-error *ngIf="registerForm.controls['lname'].hasError('required')">
        This is <strong>required</strong> field
      </mat-error>
      <mat-error *ngIf="registerForm.controls['lname'].hasError('pattern')">
        Only Alphbates can be accepted
      </mat-error> 
    </mat-form-field>      
    <mat-form-field appearance="fill">
      <mat-label>Father's Name</mat-label>
      <input matInput placeholder="Enter your father's name" formControlName="fathername" required>
      <mat-error *ngIf="registerForm.controls['fathername'].hasError('required')">
        This is <strong>required</strong> field
      </mat-error>
      <mat-error *ngIf="registerForm.controls['fathername'].hasError('pattern')">
        Only Alphbates can be accepted
      </mat-error>    
    </mat-form-field>
  </div>
  <mat-divider></mat-divider>
  <div class="example-container">
    <!-- <mat-form-field class="example-full-width">
      <mat-label>Department</mat-label>
      <input type="text"
             placeholder="Pick one"
             aria-label="Department"
             matInput
             formControlName="department"
             [matAutocomplete]="autoD" required>
      <mat-autocomplete #autoD="matAutocomplete">
        <mat-option *ngFor="let option of optionsDep index by id" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field> -->
    <mat-form-field class="example-full-width">
      <mat-label>Category</mat-label>
      <input type="text"
             placeholder="Pick one"
             aria-label="Category"
             matInput
             formControlName="category"
             [matAutocomplete]="autoC" required>
      <mat-autocomplete #autoC="matAutocomplete"  [displayWith]="getCategoryTitle.bind(this)">
        <mat-option *ngFor="let optione of optionsDep index by id" [value]="optione.id">
          {{optione.category}}-{{optione.department}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label>ID Proof Document</mat-label>
      <input type="text"
             placeholder="Pick one"
             aria-label="Document"
             matInput
             formControlName="idproofDocument"
             [matAutocomplete]="documentAuto" required>
      <mat-autocomplete #documentAuto="matAutocomplete">
        <mat-option *ngFor="let optionr of optionsDocument" [value]="optionr">
          {{optionr}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <mat-divider></mat-divider>
  <div class="example-container">
    <mat-form-field appearance="fill">
      <mat-label>ID proof Number</mat-label>
      <input matInput placeholder="ID proof number" formControlName="idproof" required>
      <mat-error *ngIf="registerForm.controls['idproof'].hasError('required')">
        This is <strong>required</strong> field
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Contact Number</mat-label>
      <input type='tel' matInput placeholder="Enter your mobile" formControlName="mobile" required>
      <mat-error *ngIf="registerForm.controls['mobile'].hasError('required')">
        This is <strong>required</strong> field
      </mat-error>
      <mat-error *ngIf="registerForm.controls['mobile'].hasError('maxlength')">
        Mobile contains more than <strong>10</strong> digits
      </mat-error>
      <mat-error *ngIf="registerForm.controls['mobile'].hasError('minlength')">
        Mobile contains less than <strong>10</strong> digits
      </mat-error>
      <mat-error *ngIf="registerForm.controls['mobile'].hasError('pattern')">
        Only Digits can be accepted
      </mat-error>
    </mat-form-field>  
    <mat-form-field appearance="fill">
      <mat-label>Basic Salary</mat-label>
      <input matInput placeholder="Enter basic salary" formControlName="bsalary" required>
      <mat-error *ngIf="registerForm.controls['bsalary'].hasError('required')">
        This is <strong>required</strong> field
      </mat-error>
      <mat-error *ngIf="registerForm.controls['bsalary'].hasError('pattern')">
        Only Digits can be accepted
      </mat-error>
    </mat-form-field>
  </div>
  <mat-divider></mat-divider>
  <div class="example-container row">
    <div class="col-sm-6">
      <mat-form-field appearance="fill" >
        <mat-label>Joining Date</mat-label>
        <input matInput [matDatepicker]="joiningDatePicker" formControlName='joiningDate' [max]="maxDate" [matDatepickerFilter]="dateFilter" required>
        <mat-error *ngIf="registerForm.controls['joiningDate'].hasError('required')">
          This is <strong>required</strong> field
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="joiningDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #joiningDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div  class="col-sm-6">   
      <div style="float: left;left: 37px;position: relative;">          
        <button mat-raised-button color="primary" (click)="onClick($event)" type="button">
          <mat-icon>add</mat-icon>{{chooseLabel}}
        </button>
        <mat-label class="UploadLable">Upload Picture</mat-label>
        <input #fileUpload
                type="file"
                class="input_fileupload_hidden"
                (input)="onInput($event)"
                (change)="onFileSelected($event)"
                formControlName="profilePic"
                [accept]="accept">
      </div>
      <div *ngFor="let f of files"  style="position: relative;float: right;right: 16px;">
        <span>{{f.name}}</span>
        <button mat-icon-button
                color="primary"
                [attr.title]="deleteButtonLabel?deleteButtonLabel:null"
                (click)="removeFile(f)" type="button"     >
          <mat-icon>{{deleteButtonIcon}}</mat-icon>
        </button>
      </div>
    </div> 
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="accent" type="reset">Reset</button>
  <button mat-raised-button color="primary" type="submit" [disabled]= "!registerForm.valid">Create</button>
</mat-dialog-actions>
</form>
<style>
  .example-container .mat-form-field + .mat-form-field {
      margin-left: 8px;
    }
  .example-container{
    text-align: center;
    padding: 5px;
  }
  .successHeader,.errorHeader{    
    top: 0;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;
    background: #0080009e;
    color: #fff;
  }
  .errorHeader{
    background: red;
  }
</style>
<app-waiting-image *ngIf="waitingImage"></app-waiting-image>

