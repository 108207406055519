import { Action } from '@ngrx/store';
import { Domain } from './domain';
import { DomainActions, DomainActionTypes } from './domain.actions';


export const domainFeatureKey = 'Domain';
export const postReducers='reducer';

export interface State {
  domain:Domain[],
  error:string
}

export const initialState: State = {
  domain:[],
  error:""
};

export function reducer(state = initialState, action: DomainActions): State {
  switch (action.type) {
    case DomainActionTypes.LoadDomains:
      return{
        ...state
      }
    case DomainActionTypes.LoadDomainsSuccess:
      return{
        ...state,
        domain:action.payload.data,
        error:""
      }
    case DomainActionTypes.LoadDomainsFailure:
      return{
        ...state,
        error:action.payload.error
      }
    default:
      return state;
  }
}
