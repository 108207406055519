import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { PermissoinService } from './permissoin.service';
import * as permisionAction from './permissoin.actions'
import { of } from 'rxjs';

@Injectable()
export class PermissionEffects {
  constructor(private actions$: Actions,private _permissionServices:PermissoinService) {}
  loadPermission$=createEffect(()=> {
    return this.actions$.pipe(
      ofType(permisionAction.PermissoinActionTypes.LoadPermissoins),
      mergeMap(() => this._permissionServices.fetchPermission().pipe(
          map((permission) => new permisionAction.LoadPermissoinsSuccess({data:permission})),
          catchError(err=> of(new permisionAction.LoadPermissoinsFailure({error:err})))
        )
      )
    )
  })
}
