import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { baseUrl } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PermissoinService } from '../dashboard/permissoin/permissoin.service';

interface month{  
  index:number,
  name:string
}

@Injectable({
  providedIn: 'root'
})

export class UtilService implements OnInit{

  months:month[] = [
    {index:1,name:"January"},
    {index:2,name:"February"},
    {index:3,name:"March"},
    {index:4,name:"April"},
    {index:5,name:"May"},
    {index:6,name:"June"},
    {index:7,name:"July"},
    {index:8,name:"August"},
    {index:9,name:"September"},
    {index:10,name:"October"},
    {index:11,name:"November"},
    {index:12,name:"December"}
  ];
  years:any=[];  
  loggenInUserId: any;
  userPermission:Array<any>=[];
  
  constructor(private _http:HttpClient,private _permissionService:PermissoinService) {
    this.initYears();
  }  
  ngOnInit(): void {
   
  }
  initYears() {
    //Max year, considered as current year. If you want future or past years you need write a logic to calculate that year.
    const now = new Date();
    const thisYear = now.getFullYear(); // this will give current years
    const maxYear = thisYear+100;
    const minYear = thisYear -100;

    // if you want year ahead - say 100 years
        // for(let i =thisYear ;i < maxYear; i++){
        //   this.years.push(i);
        // }
    // if you want year behind - say 100 years
        for(let i = thisYear; i>= minYear; i--){
          this.years.push(i)
        }
    
  }
  handleError(errorResponse: HttpErrorResponse) {
    const error= errorResponse.error;
    const keys=Object.keys(error);
    const key = keys[0];
    let message = error[key];
    console.log(errorResponse,keys);
    if(errorResponse.status == 0){
      // auth token delete
      //redirect login page
      message="no Server"
    }
    if(error[key] instanceof Array){
      message = error[key][0];
    }
    if(key === "isTrusted"){
      Swal.fire({
        position: 'bottom-end',
        icon: 'error',
        title:'Server not started!',
        showConfirmButton: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      })
    }else{
      message = key+ ':'+message;
    }
    return throwError({messages:message,error})
  }

  pincode(pincode:number){
    console.log('o=',pincode);
    const params = new HttpParams().set('pin', pincode.toString());
    return this._http.get<any>(baseUrl+'/getPincodes.php',{params}).pipe(catchError(this.handleError.bind(this)));
  }
  GenerateShipRocketToken():Observable<any>{
      return this._http.get<any>(baseUrl+'/spr_token.php').pipe(catchError(this.handleError.bind(this)));
  }
  validateToken():Observable<any>{
    return this._http.get<any>(baseUrl+'/validateToken.php').pipe(catchError(this.handleError.bind(this)))
  }
  GetUserPermissions(){
    const local1:any=localStorage.getItem('user');
    this.loggenInUserId=JSON.parse(local1);
    this._permissionService.getRollSetup().subscribe((payload:any)=>{
      if(payload){
        payload.filter((el:any,index:any)=>{
            if(this.loggenInUserId.id==el.user){
              this.userPermission= el.permission;
              console.log(this.userPermission);
            }
          })
        }
     })
     //return this.userPermission;
  }
  GetLoggedInUserDetails(){
    const loggedInUser:any=localStorage.getItem('user');
    return JSON.parse(loggedInUser);    
  }
}


