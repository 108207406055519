import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserService } from '../users/user.service';
import { Observable, of } from 'rxjs';
import { orders } from "./orders";
import * as orderActions from "./order.actions";
import { mergeMap,map,catchError, tap, concatMap, switchMap } from "rxjs/operators";
import { OrderService } from './order.service';
import * as fromShift from "../shift/shift.selectors";
import * as shiftAction from "../shift/shift.actions";
import { select, Store } from '@ngrx/store';
import { loadFilterOrders } from './order.actions';



@Injectable()
export class OrderEffects {
  loggenInUser: any;
  activeShift:string | undefined;
  constructor(private actions$: Actions,private _orderServices:OrderService,private store:Store) {
    const local1:any=localStorage.getItem('user');
    this.loggenInUser=JSON.parse(local1);
    this.store.dispatch(new shiftAction.LoadShifts)
    this.store.pipe(select(fromShift.getShift)).subscribe(payload => {
      payload.filter(el=>{
        if(el.status == true){
          this.activeShift=el.shiftName;
        }
      })
    })
  }
  loadOrders=createEffect(()=> {    
    return this.actions$.pipe(
     ofType(orderActions.OrderActionTypes.LoadOrders),
     switchMap(
       (action) => this._orderServices.fetchOrders(this.loggenInUser.id,this.loggenInUser.category).pipe(
         map((orders) => new orderActions.LoadOrdersSuccess({data:orders })),
         catchError(err=> of(new orderActions.LoadOrdersFailure({error:err})))
       )
     )
    //  ,
    //  ofType<loadFilterOrders>(orderActions.OrderActionTypes.loadFilterOrders),
    //  switchMap((action:any) => {
    //    console.log("action=",action)
    //    console.log("vd",this.actions$);       
    //      return this._orderServices.getOrdersP(action.payload.arg).pipe(
    //      map((orders) => new orderActions.loadFilterOrders({ data: orders })),
    //      catchError(err => of(new orderActions.LoadOrdersFailure({ error: err })))
    //    );}
    // )
   )
 })
//  CreateOrders=createEffect(()=>{
//    return this.actions$.pipe(
//      ofType(orderActions.OrderActionTypes.CreateOrder),
//      tap((order: any)=>console.log(order,"4")),
//      concatMap((order)=>
//       this._orderServices.CreateOrder(order,this.activeShift).pipe(
//         tap((response)=>{console.log("response=",response)
//           if(!response)
//             return 
//         }),          
//         map(()=>new orderActions.LoadOrders()),
//         catchError(err=>of(new orderActions.LoadOrdersFailure({error:err})))
//       )
//      )
//    )
//  })

//  UpdateOrders=createEffect(()=>{
//   return this.actions$.pipe(
//     ofType(orderActions.OrderActionTypes.EditOrderSuccess),
//     tap((order: any)=>console.log(order,"4")),
//     concatMap((order)=>
//      this._orderServices.UpdateOrder(order).pipe(
//        tap((response)=>{console.log("response=",response)
//          if(!response)
//            return 
//        }),          
//        map(()=>new orderActions.LoadOrders()),
//        catchError(err=>of(new orderActions.LoadOrdersFailure({error:err})))
//      )
//     )
//   )
// })

}
