<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-1">
          <div class="col-sm-6">
            <h2 class="m-0"><i class="fa fa-clock-o" aria-hidden="true"></i>{{pageTtitle | uppercase}}</h2>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right buttonList">                      
              <li class="" (click)="refreshShift()"><button mat-mini-fab color="accent" type="reset"> <i class="fas fa-sync-alt" aria-hidden="true"></i></button></li>
            </ol>
          </div>
          <!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content" id="renderDataSection">
        <div class="container-fluid">          
            <div class="row" >
                <ag-grid-angular 
                    #permissionList
                    style="width: 1470px; height: 500px;"
                    class="ag-theme-balham"
                    [sideBar]="sideBar"
                    [rowData]="rowData" 
                    [defaultColDef]="defaultColDef"
                    [columnDefs]="columnDefs"
                    [animateRows]='true'
                    [pagination]='true'
                    [paginationPageSize]="paginationPageSize"
                    (onColumnResized)="onResize($event)"
                    >
                </ag-grid-angular>            
            </div>
        </div>
    </section>
</div>