<div class="content-wrapper">
<!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-1">
                <h3 class="m-0"> <i class="nav-icon fas fa-wrench"></i> {{pageTitle | uppercase}}</h3>
            </div>
            <div class="seetingList">
                <ul class='breadbrumb' #breadcrumb>
                    <li (click)="step='step1'" [ngClass]="{'active':step==='step1'}"><i class="fas fa-users"></i>Users</li>
                    <li (click)="step='step5'" [ngClass]="{'active':step==='step5'}"><i class="fas fa-clock"></i>Shift</li>               
                    <li (click)="step='step6'" [ngClass]="{'active':step==='step6'}"><i class="fab fa-jedi-order"></i>OdrStatus</li>               
                    <li (click)="step='step2'" [ngClass]="{'active':step==='step2'}"><i class="far fa-copyright"></i>Course</li>
                    <li (click)="step='step3'" [ngClass]="{'active':step==='step3'}"><i class="fab fa-product-hunt"></i>Product</li>
                    <li (click)="step='step4'" [ngClass]="{'active':step==='step4'}"><i class="fas fa-truck"></i>Courier</li>               
                </ul>
            </div>
            <section class="employee" *ngIf="step==='step1'">
                <div class="row mb-1">
                    <h5 class="m-0">USER</h5>
                </div>
                <div class="">
                    <ol class="breadcrumb float-sm-right">                      
                        <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-plus"></i></button></li>
                        <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-globe-asia"></i></button></li>              
                        <li class="" ><button mat-mini-fab color="accent" type="reset"> <i class="fas fa-sync-alt" aria-hidden="true"></i></button></li>              
                        <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-filter"></i></button></li>              
                    </ol>
                </div>
                <div class="row createOrderStatus">
                    <section [@widthGrow]="state" style='height: 0;margin: 0 auto;' *ngIf='CreateOrderSectionSwitch'>       
                        <form autocomplete="off" class='addProduct' (ngSubmit)="submitOrderStatus(formDirective)" [formGroup]="addOrderStatus" #formDirective="ngForm">
                            <div>
                                <mat-form-field appearance="outline">
                                    <mat-label>Title</mat-label>
                                    <input formControlName='StatusName' matInput placeholder="Enter Title" required>
                                </mat-form-field>                             
                                <mat-form-field appearance="outline">
                                    <mat-label>Category</mat-label>
                                    <input type="text"
                                           placeholder="Pick one"
                                           aria-label="Choose Category"
                                           matInput
                                           formControlName="StatusCategory"
                                           [matAutocomplete]="StatusCategory" required>
                                    <mat-autocomplete #StatusCategory="matAutocomplete">
                                      <mat-option *ngFor="let category of StatusCategoryList" [value]="category">
                                        {{category}}
                                      </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <mat-dialog-actions>
                                    <button mat-raised-button color="accent" type="reset">Reset</button>
                                    <button mat-raised-button color="primary" type="submit" [disabled]= "!addOrderStatus.valid">Create</button>
                                    <a (click)='AddStatus()'>Close</a>
                                </mat-dialog-actions>
                            </div>                
                        </form>        
                    </section>
                    <ag-grid-angular 
                        #OrderSatusList
                        style="width: 1470px; height: 570px;"
                        class="ag-theme-balham"
                        [sideBar]="sideBar"
                        [rowData]="rowData" 
                        [defaultColDef]="defaultColDef"
                        [columnDefs]="columnDefs"
                        [animateRows]='true'
                        [pagination]='true'
                        [paginationPageSize]="paginationPageSize"
                        (onColumnResized)="onResize($event)"
                     >
                    </ag-grid-angular>
                </div>
            </section>
            <section class="shift" *ngIf="step==='step5'">
                <div class="row mb-1">
                    <h5 class="m-0">SHIFT</h5>
                </div>
                <div class="">
                    <ol class="breadcrumb float-sm-right">                      
                        <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-plus"></i></button></li>
                        <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-globe-asia"></i></button></li>              
                        <li class="" ><button mat-mini-fab color="accent" type="reset"> <i class="fas fa-sync-alt" aria-hidden="true"></i></button></li>              
                        <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-filter"></i></button></li>              
                    </ol>
                </div>    
                <div class="createOrderStatus">
                    <ag-grid-angular 
                    #shiftList
                    style="width: 1470px; height: 500px;"
                    class="ag-theme-balham"
                    [rowData]="shifts" 
                    [sideBar]="sideBar"
                    [defaultColDef]="defaultColDef"
                    [columnDefs]="shiftColumnDefs"
                    [animateRows]='true'
                    [pagination]='true'
                    [paginationPageSize]="paginationPageSize"
                    (onColumnResized)="onResize($event)"
                    >
                </ag-grid-angular>
                </div>            
            </section>
            <section class="course" *ngIf="step==='step2'">
                <div class="row mb-1">
                    <h5 class="col-sm-6">Course</h5>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">                      
                            <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-plus"></i></button></li>
                            <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-globe-asia"></i></button></li>              
                            <li class="" ><button mat-mini-fab color="accent" type="reset"> <i class="fas fa-sync-alt" aria-hidden="true"></i></button></li>              
                            <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-filter"></i></button></li>              
                        </ol>
                    </div>
                </div>                
                <!-- <div class="courseList">
                    <ag-grid-angular 
                    #courseList
                    style="width: 1470px; height: 500px;"
                    class="ag-theme-balham"
                    [rowData]="CourseRowData" 
                    [sideBar]="sideBar"
                    [defaultColDef]="defaultColDef"
                    [columnDefs]="CourseColumnDefs"
                    [animateRows]='true'
                    [pagination]='true'
                    [paginationPageSize]="paginationPageSize"
                    (onColumnResized)="onResize($event)"
                    >
                </ag-grid-angular>
                </div> -->
            </section>            
            <section class="product" *ngIf="step==='step3'">
                <div class="row mb-1">
                    <h5 class="m-0">Product</h5>
                </div>
                <div class="">
                    <ol class="breadcrumb float-sm-right">                      
                        <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-plus"></i></button></li>
                        <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-globe-asia"></i></button></li>              
                        <li class="" ><button mat-mini-fab color="accent" type="reset"> <i class="fas fa-sync-alt" aria-hidden="true"></i></button></li>              
                        <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-filter"></i></button></li>              
                    </ol>
                </div>
            </section>
            <section class="courier" *ngIf="step==='step4'">
                <div class="row mb-1">
                    <h5 class="m-0">Courier</h5>
                </div>
                <div class="">
                    <ol class="breadcrumb float-sm-right">                      
                        <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-plus"></i></button></li>
                        <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-globe-asia"></i></button></li>              
                        <li class="" ><button mat-mini-fab color="accent" type="reset"> <i class="fas fa-sync-alt" aria-hidden="true"></i></button></li>              
                        <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-filter"></i></button></li>              
                    </ol>
                </div>
            </section>
            <section class="orderStatus" *ngIf="step==='step6'">
                <div class="row mb-1">
                    <h5 class="m-0">Order Status</h5>
                </div>
                <div class="">
                    <ol class="breadcrumb float-sm-right">                      
                        <li class="" (click)='AddStatus()'><button mat-mini-fab color="accent"><i class="fas fa-plus"></i></button></li>
                        <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-globe-asia"></i></button></li>              
                        <li class="" ><button mat-mini-fab color="accent" type="reset"> <i class="fas fa-sync-alt" aria-hidden="true"></i></button></li>              
                        <li class="" ><button mat-mini-fab color="accent"><i class="fas fa-filter"></i></button></li>              
                    </ol>
                </div><br/>
                <div class="row createOrderStatus">
                    <section [@widthGrow]="state" style='height: 0;margin: 0 auto;' *ngIf='CreateOrderSectionSwitch'>       
                        <form autocomplete="off" class='addProduct' (ngSubmit)="submitOrderStatus(formDirective)" [formGroup]="addOrderStatus" #formDirective="ngForm">
                            <div>
                                <mat-form-field appearance="outline">
                                    <mat-label>Title</mat-label>
                                    <input formControlName='StatusName' matInput placeholder="Enter Title" required>
                                </mat-form-field>                             
                                <mat-form-field appearance="outline">
                                    <mat-label>Category</mat-label>
                                    <input type="text"
                                           placeholder="Pick one"
                                           aria-label="Choose Category"
                                           matInput
                                           formControlName="StatusCategory"
                                           [matAutocomplete]="StatusCategory" required>
                                    <mat-autocomplete #StatusCategory="matAutocomplete">
                                      <mat-option *ngFor="let category of StatusCategoryList" [value]="category">
                                        {{category}}
                                      </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <mat-dialog-actions>
                                    <button mat-raised-button color="accent" type="reset">Reset</button>
                                    <button mat-raised-button color="primary" type="submit" [disabled]= "!addOrderStatus.valid">Create</button>
                                    <a (click)='AddStatus()'>Close</a>
                                </mat-dialog-actions>
                            </div>                
                        </form>        
                    </section>
                    <ag-grid-angular 
                        #OrderSatusList
                        style="width: 1470px; height: 570px;"
                        class="ag-theme-balham"
                        [sideBar]="sideBar"
                        [rowData]="rowData" 
                        [defaultColDef]="defaultColDef"
                        [columnDefs]="columnDefs"
                        [animateRows]='true'
                        [pagination]='true'
                        [paginationPageSize]="paginationPageSize"
                        (onColumnResized)="onResize($event)"
                     >
                    </ag-grid-angular>
                </div>
            </section>
        </div>
    </div>
</div>