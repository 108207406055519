<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-1">
          <div class="col-sm-4">
            <h2 class="m-0">{{pageTtitle | uppercase}}</h2>
          </div><!-- /.col -->
          <div class="col-sm-4" style='text-align: center;'>
            
          </div><!-- /.col -->
          <div class="col-sm-4">
        </div>
    </div>
    <section class="content" id="renderDataSection">
        <div class="container-fluid">
          <!-- Info boxes -->        
          <div class="row" >
            <!-- <section class='filterSection'>
              <div class="filterWrapper row" style="height: 35px;">              
                <div class="userfilterBy col-sm-4">
                  <input type="text" #filterTextBox placeholder="Type Something..." (input)='onFilterTextBoxChanged()' id='typeSomethingFilter'/>
                </div>              
                <div class="userfilterBy col-sm-4">
                 
                </div>
                <div class="userfilterBy col-sm-4">
                 
                </div>
              </div>
            </section>     -->
            <ag-grid-angular 
                #loginAuditDetailsList
                style="width: 1470px; height: 605px;"
                class="ag-theme-balham"
                [sideBar]="sideBar"
                [rowData]="rowData" 
                [defaultColDef]="defaultColDef"
                [columnDefs]="columnDefs"
                [animateRows]='true'
                [pagination]='true'
                [paginationPageSize]="paginationPageSize"
                (onColumnResized)="onResize($event)"              
                >
            </ag-grid-angular>
            <!-- /.col -->
          </div>
          <!-- /.row -->
  
       
          <!-- /.row -->
  
          <!-- Main row -->
        
          <!-- /.row -->
        </div><!--/. container-fluid -->
      </section>
</div>