import { NgModule, ViewContainerRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardPageComponent } from './dashboard/dashboard-page/dashboard-page.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { HeaderComponent, QuickViewSearchOrder } from './dashboard/header/header.component';
import { LeftMenuSectionComponent } from './dashboard/left-menu-section/left-menu-section.component';
import { EditUserPermissiondDialogComponent, LoginSetupModal, UsersComponent } from './dashboard/users/users.component';
import { UserCardComponent } from "./dashboard/user-card/user-card.component";
import { AuthGuard } from './auth/auth.guard';
import { FooterComponent } from './dashboard/footer/footer.component';
import { AgGridModule } from 'ag-grid-angular';
import {Grid} from'ag-grid-community';
import {CreateUserDialog} from './dashboard/left-menu-section/left-menu-section.component';
import { MatInputModule } from "@angular/material/input";
import {MatCardModule} from '@angular/material/card';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDividerModule} from '@angular/material/divider';
import {MatBottomSheetModule, MatBottomSheetRef,MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import { QuickViewReport } from "./dashboard/header/header.component";
import { MatDialogModule } from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import { WaitingImageComponent } from './dashboard/waiting-image/waiting-image.component';
import { LoadingBarComponent } from "../app/dashboard/loading-bar/loading-bar.component";
import {LodingBarServiceService} from "../app/dashboard/loading-bar/loding-bar-service.service";
import { EditButtonComponent } from './dashboard/edit-button/edit-button.component';
import { TooltipComponent } from './dashboard/tooltip/tooltip.component';
import {NgbPopoverModule} from "@ng-bootstrap/ng-bootstrap";
import { StoreModule } from '@ngrx/store';
import {reducers, metaReducers } from './reducers';
//import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './user.effects'; 
import { SlideToggleComponent } from  "./dashboard/slide-toggle/slide-toggle.component";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import { ActionButtonsComponent, LoginHistoryDialog, PermissionAssignedDialog } from './dashboard/action-buttons/action-buttons.component';
import { GenderRendererComponent } from './dashboard/gender-renderer/gender-renderer.component';
import { EmployeeComponent } from './dashboard/employee/employee.component';
import { EmployeeEffects } from './dashboard/employee/employee.effects';
import { EmployeeActionBUttonsComponent } from './dashboard/employee-action-buttons/employee-action-buttons.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { AssignOrderDialog, CreateOrderDialog, OrdersComponent, ShippingLogOrderDialog, TransferOrderDialog } from './dashboard/orders/orders.component';
import { OrderEffects } from './dashboard/orders/order.effects';
import { ProductComponent } from './dashboard/product/product.component';
import { ProductEffects } from './dashboard/product/product.effects';
import { CourierComponent } from './dashboard/courier/courier.component';
import { SettingComponent } from './dashboard/setting/setting.component';
import { CourseComponent } from './dashboard/course/course.component';
import { CourseEffects } from './dashboard/course/course.effects';
import { EditOrderdDialogComponent, OrderActionButtonComponent, OrderHistorydDialogComponent, ReOrderdDialogComponent } from './dashboard/order-action-button/order-action-button.component';
import { DigitalClockComponent } from './dashboard/digital-clock/digital-clock.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { OrderStatusEffects } from './dashboard/order-status/order-status.effects';
import {MatChipsModule} from '@angular/material/chips';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import { PermissionEffects } from './dashboard/permissoin/permission.effects';
import { PermissionComponent } from './dashboard/permissoin/permission.component';
import { CommonModule } from '@angular/common'
import { ReportComponent } from './dashboard/report/report.component';
import { ReportEffects } from './dashboard/report/report.effects';
import { ReportActionButtonComponent } from './dashboard/report-action-button/report-action-button.component';
import { DomainEffects } from './dashboard/domain/domain.effects';
import {DomainComponent} from './dashboard/domain/domain.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { LoginAuditComponent } from './dashboard/login-audit/login-audit.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { LoginAuditEffects } from './dashboard/login-audit/login-audit.effects';
import { OnlineUsersEffects } from './dashboard/online-users/online-users.effects';
import { OnlineUsersComponent } from './dashboard/online-users/online-users.component';
import { DashboardPageEffects } from './dashboard/dashboard-page/dashboard-page.effects';
import {A11yModule} from '@angular/cdk/a11y';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import { ShippingDetailsComponent, TrackOrderDialog } from './dashboard/shipping-details/shipping-details.component';
import { SharedModule } from './shared/shared.module';
import { HideForRolesDirective } from './shared/directives/hide-for-roles.directive';
import { PermissionGaurdGuard } from './auth/permission-gaurd.guard';
import { PermissionRollSetupComponent } from './dashboard/permission-roll-setup/permission-roll-setup.component';
import { PermissionRollSetupEffects } from './dashboard/permission-roll-setup/permission-roll-setup.effects';
import { Page404Component } from './dashboard/page404/page404.component';
import { LeastOrderConfirmationAgentEffects } from './dashboard/least-order-confirmation-agent/least-order-confirmation-agent.effects';
import { DoughnutComponent } from './dashboard/doughnut/doughnut.component';
import { QuickViewSearchOrder1 } from "./dashboard/orders/orders.component";
import { OrderStatusComponent } from './dashboard/order-status/order-status.component';
import { OrderStatusActionButtonComponent } from './dashboard/order-status-action-button/order-status-action-button.component';
import { MatRadioModule } from '@angular/material/radio';
import { ShiftComponent } from './dashboard/shift/shift.component';
import {EditShiftDialog,ShiftActionButtonComponent} from './dashboard/shift-action-button/shift-action-button.component';
import { ShiftEffects } from './dashboard/shift/shift.effects';
import { OrderActionButtonEffects } from './dashboard/order-action-button/order-action-button.effects'
  
@NgModule({
  declarations: [
    AppComponent,
    ActionButtonsComponent,
    LoginComponent,
    DashboardPageComponent,
    HeaderComponent,
    LeftMenuSectionComponent,
    UsersComponent,
    UserCardComponent,
    FooterComponent,
    CreateUserDialog,
    QuickViewReport,
    WaitingImageComponent,
    LoadingBarComponent,
    LoginSetupModal,
    EditButtonComponent,
    TooltipComponent,
    SlideToggleComponent,
    GenderRendererComponent,
    EmployeeComponent,
    EmployeeActionBUttonsComponent,
    OrdersComponent,
    CreateOrderDialog ,
    ProductComponent,
    CourierComponent,
    SettingComponent,
    CourseComponent,
    OrderActionButtonComponent,
    DigitalClockComponent,
    EditOrderdDialogComponent,
    OrderHistorydDialogComponent,
    EditUserPermissiondDialogComponent,
    PermissionComponent,
    ReportComponent,
    ReportActionButtonComponent,
    DomainComponent,
    AssignOrderDialog,
    LoginAuditComponent,
    OnlineUsersComponent,
    LoginHistoryDialog,
    ShippingLogOrderDialog,
    ShippingDetailsComponent,
    TrackOrderDialog,
    PermissionRollSetupComponent,
    Page404Component,
    PermissionAssignedDialog,
    DoughnutComponent  ,
    QuickViewSearchOrder,
    QuickViewSearchOrder1  ,
    ReOrderdDialogComponent,
    OrderStatusComponent,
    OrderStatusActionButtonComponent,
    ShiftComponent,
    EditShiftDialog,
    ShiftActionButtonComponent,
    TransferOrderDialog    
       
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,    
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgbPopoverModule,
    BrowserAnimationsModule,
    DashboardModule,
    AgGridModule.withComponents([UsersComponent,ActionButtonsComponent,GenderRendererComponent,EmployeeActionBUttonsComponent,PermissionComponent,LoginHistoryDialog,ShippingLogOrderDialog,QuickViewSearchOrder]),
    MatInputModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatListModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatDatetimePickerModule,
    MatChipsModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSelectModule,
    MatRadioModule,
    NgxPaginationModule,
    A11yModule,
    DragDropModule,
    PortalModule,
    ScrollingModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    StoreModule.forRoot(reducers),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([UserEffects,EmployeeEffects,PermissionRollSetupEffects]),
    EffectsModule.forFeature([OrderEffects,PermissionRollSetupEffects, ProductEffects, CourseEffects, OrderStatusEffects, PermissionEffects, ReportEffects, DomainEffects, LoginAuditEffects, LoginAuditEffects, OnlineUsersEffects, DashboardPageEffects, LeastOrderConfirmationAgentEffects, ShiftEffects, OrderActionButtonEffects]),
    SharedModule,
    // StoreModule.forRoot(reducers, { metaReducers }),
    // !environment.production ? StoreDevtoolsModule.instrument() : []
  ],
  providers: [
    AuthGuard,PermissionGaurdGuard,HideForRolesDirective,LodingBarServiceService,ActionButtonsComponent,EmployeeActionBUttonsComponent,CreateUserDialog,CreateOrderDialog,OrdersComponent,LoginHistoryDialog,ShippingLogOrderDialog,ShippingDetailsComponent,PermissionRollSetupComponent,QuickViewSearchOrder,OrderActionButtonComponent,HeaderComponent,QuickViewSearchOrder1,ReOrderdDialogComponent,OrderStatusActionButtonComponent,EditShiftDialog,ShiftActionButtonComponent,ShiftComponent,DigitalClockComponent,
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
