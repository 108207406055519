import { Component, OnInit } from '@angular/core';
import * as fromRoleSetupPermission from './permission-roll-setup.selectors';
import * as RoleSetupPermissionAction from './permission-roll-setup.actions'
import { select, Store } from '@ngrx/store';
import { ComplexInnerSubscriber } from 'rxjs/internal/innerSubscribe';

@Component({
  selector: 'app-permission-roll-setup',
  templateUrl: './permission-roll-setup.component.html',
  styleUrls: ['./permission-roll-setup.component.css']
})
export class PermissionRollSetupComponent implements OnInit {

  constructor(private store:Store) { }

  userRolePermission!:Array<any>
  ngOnInit(): void {
    console.log("Calling role srtup");
    this.store.dispatch(new RoleSetupPermissionAction.LoadPermissionRollSetups)
    this.store.pipe(select(fromRoleSetupPermission.getPermission)).subscribe(permission=>this.userRolePermission=permission)
  }

}
