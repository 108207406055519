import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { DomainService } from './domain.service';
import * as DomainActions from './domain.actions'
import { of } from 'rxjs';



@Injectable()
export class DomainEffects {
  constructor(private actions$: Actions,private _domainServices:DomainService) {}
  loadCourses=createEffect(()=> {
    return this.actions$.pipe(
     ofType(DomainActions.DomainActionTypes.LoadDomains),
     mergeMap(
       action => this._domainServices.fetchDomain().pipe(
         map((domain) => new DomainActions.LoadDomainsSuccess({data:domain})),
         catchError(err=> of(new DomainActions.LoadDomainsFailure({error:err})))
       )
     )
   )
 })

}
