import { Action, createReducer, on } from '@ngrx/store';
import { orders } from '../orders/orders';
import { stat } from 'node:fs';
import { OrderActionButtonActions, OrderActionButtonActionTypes } from './order-action-button.actions';
export const orderActionButtonFeatureKey = 'orderActionButton';
export const postReducers='reducer';

export interface State {
  orders:orders[]
  error:string,
  editOrder:orders[]
  reOrder:orders[]
}

export const initialState: State = {
  orders:[],
  error:"",
  editOrder:[],
  reOrder:[]
};


export function reducer(state = initialState, action: OrderActionButtonActions): State {
  switch (action.type) {
    case OrderActionButtonActionTypes.LoadOrderActionButtons:
      return {
        ...state
      }
    case OrderActionButtonActionTypes.LoadOrderActionButtonsFailure:
      return{
        ...state,
        orders:state.orders,
        error:action.payload.error
      }
    case OrderActionButtonActionTypes.LoadOrderActionButtonsSuccess:
      console.log('calling');
      return{
        ...state,
        orders:action.payload.data,
        error:""
      }
    case OrderActionButtonActionTypes.loadFilterOrders:
        return{
          ...state,
          orders:action.payload.data,
          error:""
        }
    default:
        return state;
    }
  }

