import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './permission.reducer';

const getPermissionFeatureState = createFeatureSelector<State>('permission');

export const getPermission = createSelector(
    getPermissionFeatureState,
    state => state.permission
)

export const getError = createSelector(
    getPermissionFeatureState,
    state => state.error
)
export const createPermission= createSelector(
    getPermissionFeatureState,
    state=>state.permission
)
