import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { baseUrl } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ProductService } from '../product/product.service';
import { Course } from "./course";

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  private _refreshNeeded$= new Subject<void>();

  constructor( private _http:HttpClient) { }
  handleError(errorResponse: HttpErrorResponse) {
    const error= errorResponse.error;
    const keys=Object.keys(error);
    const key = keys[0];
    let message = error[key];
    console.log(keys);
    if(errorResponse.status == 0){
      // auth token delete
      //redirect login page
      message="no Server"
    }
    if(error[key] instanceof Array){
      message = error[key][0];
    }
    if(key === "isTrusted"){
      Swal.fire({
        position: 'bottom-end',
        icon: 'error',
        title:'Server not started!',
        showConfirmButton: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      })
    }else{
      message = key+ ':'+message;
    }
    return throwError({messages:message,error})
  }

  fetchCourse():Observable<Course[]>{
    return this._http.get<Course[]>(baseUrl+'/getCourses.php?ty').pipe(catchError(this.handleError.bind(this)));
  }
  addNewCourse(course:any):Observable<any>{  
    console.warn("Course=",course)
    return this._http.post<any>(baseUrl+'/addCourse.php?fpfh1l',course).pipe(catchError(this.handleError.bind(this)))
  }
}
