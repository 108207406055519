<div class="digiBlock">
    <div class="digitalClock shift">
        <div class="dayTitle">Shift</div>   
        <div class="DigitalTime">
            {{shiftTitle}}
        </div>
    </div>
    <div class="digitalClock">
        <div class="dayTitle">{{day}}</div>   
        <div class="DigitalTime">
            <div>{{hour}} : &nbsp;</div>
            <div>{{minute}} : &nbsp;</div>
            <div>{{second}} : &nbsp;</div>
            <div class='ampm'>{{ampm}}</div>
        </div>
    </div>
</div>