import { createFeatureSelector, createSelector } from '@ngrx/store';
import {State  } from "./dashboard-page.reducer";

const getDashboardPageFeatureState=createFeatureSelector<State>('DashboardPage');

export const getDashboardPage = createSelector(
    getDashboardPageFeatureState,
    state => state.dashboardPage
)

export const getError = createSelector(
    getDashboardPageFeatureState,
    state => state.error
)
export const createDashboardPage= createSelector(
    getDashboardPageFeatureState,
    state=>state.dashboardPage
)

