import { Action } from '@ngrx/store';
import { orders } from '../orders/orders';

export enum OrderActionButtonActionTypes {
  LoadOrderActionButtons = '[OrderActionButton] Load OrderActionButtons',
  LoadOrderActionButtonsSuccess = '[OrderActionButton] Load OrderActionButtons Success',
  LoadOrderActionButtonsFailure = '[OrderActionButton] Load OrderActionButtons Failure',
  loadFilterOrders= '[OrderActionButton] Load OrderActionButtons loadFilterOrders'

}

export class LoadOrderActionButtons implements Action {
  readonly type = OrderActionButtonActionTypes.LoadOrderActionButtons;
}

export class LoadOrderActionButtonsSuccess implements Action {
  readonly type = OrderActionButtonActionTypes.LoadOrderActionButtonsSuccess;
  constructor(public payload: { data: orders[] }) { }
}

export class LoadOrderActionButtonsFailure implements Action {
  readonly type = OrderActionButtonActionTypes.LoadOrderActionButtonsFailure;
  constructor(public payload: { error: "" }) { }
}
export class loadFilterOrders implements Action{
  readonly type=OrderActionButtonActionTypes.loadFilterOrders;
  constructor(public payload: any) {console.log("ff",payload); }
}

export type OrderActionButtonActions = LoadOrderActionButtons | LoadOrderActionButtonsSuccess | LoadOrderActionButtonsFailure | loadFilterOrders;

