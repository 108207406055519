import { Component, Inject, OnInit } from '@angular/core';
import { OrderService } from '../orders/order.service';
import * as OrderAction from '../orders/order.actions';
import * as fromOrder from "../orders/order.selectors";
import * as orderStatuList from "../order-status/order-status.actions"
import * as fromOrderStatus from "../order-status/order-status.selectors";
import { Store,select } from "@ngrx/store";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { orders } from "../orders/orders";
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { product } from '../product/product';
import { Course } from '../course/course';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateOrderDialog, OrdersComponent } from '../orders/orders.component';
import { DomSanitizer } from '@angular/platform-browser';
import { LodingBarServiceService } from '../loading-bar/loding-bar-service.service';
import { Observable } from 'rxjs';
import * as productAction from "../product/product.actions";
import * as fromProduct from "../product/product.selectors"
import * as courseAction from "../course/course.actions";
import * as fromCourse from "../course/course.selectors"
import * as environment from "../../../environments/environment";
import { UtilService } from "../../utilities/util.service";
import Swal from 'sweetalert2';
import { orderStatus } from './orderStatus';
import { orderHistory } from './orderHistory';
import { CourierComponent } from '../courier/courier.component';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import * as roleSetupAction from '../permission-roll-setup/permission-roll-setup.actions'
import * as fromRoleSetup from '../permission-roll-setup/permission-roll-setup.selectors'
import { ifStmt } from '@angular/compiler/src/output/output_ast';
import { LeastOrderConfirmationAgentService } from '../least-order-confirmation-agent/least-order-confirmation-agent.service';


@Component({
  selector: 'app-order-action-button',
  templateUrl: './order-action-button.component.html',
  styleUrls: ['./order-action-button.component.css']
})
export class OrderActionButtonComponent implements OnInit {
  paramsData: any;
  completeParamView: any;
  deactivateToggle:boolean=false;
  public isCreateOrderDialogOpned:boolean=false;
  public isOrderHistoryDialogOpned:boolean=false;
  productList!:product[];
  CourseList!:Course[];
  public OrderHistoryDetails!:orderHistory[];
  loggedInUserParse: any;
  //reorderFlag:boolean=false;
  actionBy!:string
  isReorderOrderDialogOpned:boolean=false;  

  constructor(private _util:UtilService,private _orderCom:OrdersComponent,private _orderService:OrderService,public store:Store,private dialog:MatDialog,private lbs:LodingBarServiceService,private _courier:CourierComponent){    
    this.loggedInUserParse=this._util.GetLoggedInUserDetails();
   }

  agInit(params:any){
    this.paramsData=params.data;
    this.completeParamView=params;
  }

  ngOnInit(): void {
    this.store.dispatch(new productAction.LoadProducts)
    this.store.dispatch(new courseAction.LoadCourses)
    this.store.dispatch(new orderStatuList.LoadOrderStatuss)
  }

  Reorder(event:any){
    this.actionBy=this.loggedInUserParse.username+'-'+this.loggedInUserParse.fname+' '+this.loggedInUserParse.lname;    
    // Begin.BLOCK COMMENTED AS THIS IF FOE CLONE THE ORDER ONLY
    
    // this._orderService.cloneOrder(this.paramsData,this.actionBy,this.loggedInUserParse.category,this.loggedInUserParse.username).subscribe(payload=>{
    //   if(payload){
    //     Swal.fire({
    //       title: 'Re-order Accepted',
    //       text: 'PLEASE EDIT IF REQUIRED',
    //       position: 'top-end',
    //       icon:'success',
    //       focusConfirm: false,
    //       confirmButtonText:'<i class="fa fa-thumbs-up"></i> Okay',
    //       showClass: {
    //         popup: 'animate__animated animate__fadeInDown'
    //       },
    //       hideClass: {
    //         popup: 'animate__animated animate__fadeOutUp'
    //       }
    //     })
    //     this._orderCom.alluser()

    //   }
    // })
    // End .BLOCK COMMENTED AS THIS IF FOE CLONE THE ORDER ONLY

   // var id=this.paramsData.id;  
    //this.store.dispatch(new OrderAction.EditOrder({id}));
    //this.store.dispatch(new OrderAction.OpenEditableOrder({}))
    if(!this.isReorderOrderDialogOpned){
      console.log("data in reorer=",this.paramsData.id);
      const dialogRef = this.dialog.open(ReOrderdDialogComponent,{
        data:{orderID:this.paramsData.id},
        width: '1000px',
        disableClose:true,
        
      });   
      this.isReorderOrderDialogOpned = !this.isReorderOrderDialogOpned;
      dialogRef.afterClosed().subscribe(result => {
        this.isReorderOrderDialogOpned = !this.isReorderOrderDialogOpned;
        console.log(`Dialog result: ${result}`);
      });
    }

  }

  EditUser(event:Event){
    var id=this.paramsData.id;  
    this.store.dispatch(new OrderAction.EditOrder({id}));
    //this.store.dispatch(new OrderAction.OpenEditableOrder({}))
    if(!this.isCreateOrderDialogOpned){
      const dialogRef = this.dialog.open(EditOrderdDialogComponent,{
        width: '1000px',
        disableClose:true,
      });   
      this.isCreateOrderDialogOpned = !this.isCreateOrderDialogOpned;
      dialogRef.afterClosed().subscribe(result => {
        this.isCreateOrderDialogOpned = !this.isCreateOrderDialogOpned;
        console.log(`Dialog result: ${result}`);
      });
    }
  }
  DeleteUser(){}
  DeactivateEmployeeToggle(){}
  ShowHistoryOforder(event:any){  
    this._orderService.orderHistory(this.paramsData.id).subscribe(orderHistory=>{
      if(orderHistory.length>0){
        if(!this.isOrderHistoryDialogOpned){
          const dialogRef = this.dialog.open(OrderHistorydDialogComponent,{
            width: '1200px',
            disableClose:false,
            data:orderHistory,
            panelClass: 'orderHistoryDialog'
          });   
          this.isOrderHistoryDialogOpned = !this.isOrderHistoryDialogOpned;
          dialogRef.afterClosed().subscribe(result => {
            this.isOrderHistoryDialogOpned = !this.isOrderHistoryDialogOpned;
            console.log(`Dialog result: ${result}`);
          });
        }
      }
    })
  }
  ShipOrder(){
    console.log(this.paramsData.id,"dfdf",this._courier.timePeriods);
    this._orderService.fetchSingleOrder(this.paramsData.id).subscribe(payload=>{
      this.lbs.showBar(true);
      if(payload){
        this._orderService.shipOrder(payload,payload.length,"fg").subscribe(payload=>{
          console.log("ok=",payload[0].status);
          if(payload[0].status==1){            
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              },
              customClass:{
                container:'shippingContainerSuccess',
                popup:'shippingSuccess'                
              }
            })            
            Toast.fire({
              icon: 'success',
              title: 'Order has shipped successfully'
            })
            this.lbs.clear();
          }else{
            console.log("dfd",payload);
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                this.lbs.clear();
              },
              customClass:{
                container:'shippingContainerError',
                popup:'shippingError'                
              }
            })            
            Toast.fire({
              icon: 'warning',
              title: 'Oops ! Something went wrong'
            })
            
          }
        })
      }
    })
  }
}

@Component({
  selector: 'order-history-dialog',
  templateUrl:'order-history-dialog.html',
  styleUrls:['../orders/orders.component.css'],
  animations:[
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [ style({ opacity: 0 }), animate(600) ]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})

export class OrderHistorydDialogComponent implements OnInit{

  OrderHistoryDetails!:orderHistory[];
  historyOrderID!:number
  constructor(@Inject(MAT_DIALOG_DATA) private data: orderHistory[]){
    this.OrderHistoryDetails=this.data;
    this.historyOrderID=this.OrderHistoryDetails[0].orderID;
    console.log("History=",this.OrderHistoryDetails);
  }
  ngOnInit(): void {}
}

@Component({
  selector: 'edit-order-dialog',
  templateUrl:'edit-order-dialog.html',
  styleUrls:['../orders/orders.component.css'],
  animations:[
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [ style({ opacity: 0 }), animate(600) ]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})

export class EditOrderdDialogComponent implements OnInit{
  public waitingImage:boolean=false; 
  EditOrderForm!:FormGroup; 
  successHeader:boolean=false;
  courierModeList=['Courier Mode 1','Courier Mode 2','Courier Mode 3','Courier Mode 4']
  statusList:orderStatus[]=[];
  CopystatusList:orderStatus[]=[];
  productList!:product[];
  CourseList!:Course[];
  postOfficeList!:any[];
  stateList!:any[];
  cityList!:any[];
  dummyEditOrder!:orders;
  loggedInUserParse:any
  disabled:boolean=true;
  userPermission:Array<any>=[];
  status1:boolean=false;
  previousPrice: any;
  constructor(public dialogRef: MatDialogRef<CreateOrderDialog>,private sanitizer: DomSanitizer,private lbs:LodingBarServiceService,private store:Store,private _orderService:OrderService,private pinapi:UtilService) {
    const loggedInUser:any=localStorage.getItem('user');
    this.loggedInUserParse=JSON.parse(loggedInUser);
    this.EditOrderForm = new FormGroup({ 
      id:new FormControl('',[Validators.required]),
      cname: new FormControl('',[Validators.required]),
      product: new FormControl('',[Validators.required]),     
      mobile : new FormControl('', [Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]),
      altmobile : new FormControl('', [Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]),
      course: new FormControl('',[Validators.required]),
      price: new FormControl('',[Validators.required,Validators.maxLength(4),Validators.pattern('^[0-9]*$')]),
      bprice: new FormControl('',[Validators.required]),
      pincode: new FormControl('',[Validators.required,Validators.maxLength(6),Validators.minLength(6),Validators.pattern('^[0-9]*$')]),
      postOffice: new FormControl('',[Validators.required]),
      state: new FormControl('',[Validators.required]),
      landmark: new FormControl('',[Validators.required]),
      city: new FormControl('',[Validators.required]),
      remark: new FormControl('',[Validators.required]),
      address: new FormControl('',[Validators.required]),
      orderStatusN:new FormControl('',[Validators.required]),
      time:new FormControl(''),
      date:new FormControl(''),
      AG_ID: new FormControl(''),
      TL_ID:new FormControl(''),
      week: new FormControl(environment.CurrentWeek,[Validators.required])      
    });     
    this.store.pipe(select(fromOrderStatus.getorderStatus)).subscribe(status=>{
      this.CopystatusList=status
      if(this.loggedInUserParse.category == '105'){
        this.store.pipe(select(fromRoleSetup.getPermission)).subscribe((roles:any) => {
          roles.filter((el:any,index:any)=>{
            if(this.loggedInUserParse.id==el.user){
              el.permission.map((el1:any)=>{
                if(el1.indexOf('14') !== -1 || el1.indexOf('15') !== -1 || el1.indexOf('16') !== -1 || el1.indexOf('17') !== -1){
                  this.status1=true;
                }                        
              })
            }
          })
          if(this.status1){
            status.map(el2=>{
              if(el2.name=="NOT PICK" || el2.name=='HOLD' || el2.name=="CANCEL" || el2.name=='FAKE' || el2.name=='CONFIRM'){
                this.statusList.push(el2);
              }
            })
          }
        }) 
      }
      if(this.loggedInUserParse.category == '108'){
        status.map(el2=>{
          if(el2.name=="NOT PICK" || el2.name=='HOLD' || el2.name=="CANCEL" || el2.name=='FAKE' || el2.name == 'FINAL'){
            this.statusList.push(el2);
          }
        })
      }
      if(this.loggedInUserParse.category=='104'){
        this.statusList=status;
      }
    });
    this.store.pipe(select(fromProduct.getProduct)).subscribe(products=>this.productList=products);   
    this.store.pipe(select(fromCourse.getCourse)).subscribe(course=>this.CourseList=course);
    console.log('1');    
  }  
  ngOnInit(): void {   
    console.log("ghgh");
    const order$:Observable<orders[]>= this.store.select(fromOrder.getEditOrders)   
    
    order$.subscribe((order:orders[]) => {
      console.log("ghghDDDD",order[0].id);
      if(order){        
        this.EditOrderForm.patchValue({
          id:order[0].id,
          cname:order[0].customerName,
          product:order[0].productID,
          mobile:order[0].customerMobile,
          altmobile : order[0].customerAlternateMobile,
          course: order[0].courseID,
          bprice:order[0].BasePrice,
          price: order[0].confirmedPrice,
          pincode: order[0].pincode,
          postOffice:order[0].postOffice,
          state:order[0].state,
          landmark:order[0].landmark, 
          city: order[0].city,
          remark:order[0].remark,
          address:order[0].addressLine,
          orderStatusN:order[0].orderStatus,
          time:this.formatTime(order[0].orderTime),
          date:order[0].orderDate,
          AG_ID:order[0].AG_ID,
          TL_ID:order[0].TL_ID          
        })
        console.log('2w',order[0]);    
        this.dummyEditOrder=order[0]
        this.pinapi.pincode(order[0].pincode).subscribe((payload)=>{
          this.postOfficeList=payload.postOffice;
        })       
      }
    })
  }
  get orderID(){return this.EditOrderForm.get('id')?.value}
  EditOrderAction(formDirective:FormGroupDirective){
    this.EditOrderForm.addControl('actionBy',new FormControl(this.loggedInUserParse.username+'-'+this.loggedInUserParse.fname+' '+this.loggedInUserParse.lname))
    this.EditOrderForm.addControl('productName',new FormControl(this.getProductTitle(this.EditOrderForm.controls['product'].value)));
    this.EditOrderForm.addControl('statusName',new FormControl(this.getOrderStatusTitle(this.EditOrderForm.controls['orderStatusN'].value)));
    this.EditOrderForm.addControl('courseName',new FormControl(this.getCourseTitle(this.EditOrderForm.controls['course'].value)));    
    if(this.loggedInUserParse.category=="104"){      
      this.EditOrderForm.controls['TL_ID'].setValue(this.loggedInUserParse.id);    
    }if(this.loggedInUserParse.category=="108"){
      this.EditOrderForm.controls['TL_ID'].setValue(this.loggedInUserParse.id);
    }if(this.loggedInUserParse.category=="105"){
      this.EditOrderForm.controls['AG_ID'].setValue(this.loggedInUserParse.id);
    }    
    this._orderService.UpdateOrder(formDirective.value).subscribe((payload)=>{
      if(payload){
        this.dialogRef.close();
        this.store.dispatch(new OrderAction.LoadOrders);
       }
    })
  }
  GetPinCodeDetails(event:any){
    if(event.target.value.length=='6'){
      this.pinapi.pincode(event.target.value).subscribe((payload)=>{
        if(payload !='Error'){
          this.EditOrderForm.controls['state'].setValue(payload.state);
          this.EditOrderForm.controls['city'].setValue(payload.city);
          this.postOfficeList=payload.postOffice;
        }else{
          Swal.fire({
            title: 'OOPS...WRONG PINCODE',
            text: 'Please Enter Correct Code!',
            icon:'error',
            width: 600,
            padding: '3em',
            background: '#fff',
            customClass: {
              container:'swalbackgroundMargin',
              // popup:'popupcustom'
            },
            focusConfirm: false,
            confirmButtonText:'<i class="fa fa-thumbs-down"></i> Cancel',
          })
          this.EditOrderForm.controls['state'].setValue('');
          this.EditOrderForm.controls['city'].setValue('');
          this.postOfficeList=[]
        }        
      });
    }
  }
  onFocusInEvenPrice(event:any){
    this.previousPrice=this.EditOrderForm.controls['price'].value;
  }
  onFocusOutEvenPrice(event:any){
    if(!this.EditOrderForm.controls['price'].hasError('required') && !this.EditOrderForm.controls['price'].hasError('pattern') && !this.EditOrderForm.controls['price'].hasError('minlength')  && !this.EditOrderForm.controls['price'].hasError('maxlength')){
      if(!(this.EditOrderForm.controls['price'].value == this.previousPrice)){
        this.EditOrderForm.controls['price'].setValue(this.EditOrderForm.controls['price'].value-290);
      }      
    }
  }
  getProductTitle(productID:number):any{
    return this.productList.find((product)=>product.id==productID)?.name
  }
  onSelectionChange(event:any){
    this.EditOrderForm.controls['bprice'].setValue(this.CourseList.find((course)=>course.id ===event.option.value)?.price)
  }
  getCourseTitle(courseID:number):any { 
    console.log(this.CourseList)  
    return this.CourseList.find((course) => course.id==courseID)?.name    
  }
  getPostOffice(name:string):any{
    return this.postOfficeList.find((postOffice)=>postOffice.Name == name)?.Name;
  }
  getOrderStatusTitle(statusId:number):any{
    console.log(statusId,this.CopystatusList,this.CopystatusList.find((status)=>status.id==statusId)?.name)
    return this.CopystatusList.find((status)=>status.id==statusId)?.name
  }
  formatTime(time:any):any {
    const dateArray=time.split(':');
    var hours= dateArray[0]<=12?dateArray[0]:dateArray[0]-12;
    hours=hours<10?'0'+hours:hours;
    var minutes= dateArray[1];
    minutes=minutes<10?'0'+minutes:minutes;
    var seconds= dateArray[2];
    var ampm = dateArray[0]>=12?'PM':'AM';
    return hours+':'+minutes+' '+ampm;
  }
}

@Component({
  selector: 're-order-dialog',
  templateUrl:'re-order-dialog.html',
  styleUrls:['../orders/orders.component.css'],
  animations:[
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [ style({ opacity: 0 }), animate(600) ]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})

export class ReOrderdDialogComponent implements OnInit{
  public waitingImage:boolean=false; 
  ReOrderForm!:FormGroup; 
  successHeader:boolean=false;
  courierModeList=['Courier Mode 1','Courier Mode 2','Courier Mode 3','Courier Mode 4']
  statusList!:orderStatus[];
  productList!:product[];
  CourseList!:Course[];
  postOfficeList!:any[];
  stateList!:any[];
  cityList!:any[];
  dummyReOrder!:orders;
  loggedInUserParse:any
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private _leaseOrderConfirmationAgent:LeastOrderConfirmationAgentService,public dialogRef: MatDialogRef<ReOrderdDialogComponent>,private sanitizer: DomSanitizer,private lbs:LodingBarServiceService,private store:Store,private _orderService:OrderService,private pinapi:UtilService) {
    const loggedInUser:any=localStorage.getItem('user');
    this.loggedInUserParse=JSON.parse(loggedInUser);
    this.ReOrderForm = new FormGroup({ 
      id:new FormControl('',[Validators.required]),
      cname: new FormControl('',[Validators.required]),
      product: new FormControl('',[Validators.required]),     
      mobile : new FormControl('', [Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]),
      altmobile : new FormControl('', [Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]),
      course: new FormControl('',[Validators.required]),
      price: new FormControl('',[Validators.required]),
      bprice: new FormControl('',[Validators.required]),
      pincode: new FormControl('',[Validators.required,Validators.maxLength(6),Validators.minLength(6),Validators.pattern('^[0-9]*$')]),
      postOffice: new FormControl('',[Validators.required]),
      state: new FormControl('',[Validators.required]),
      landmark: new FormControl('',[Validators.required]),
      city: new FormControl('',[Validators.required]),
      remark: new FormControl('',[Validators.required]),
      address: new FormControl('',[Validators.required]),
      orderStatusN:new FormControl('',[Validators.required]),
      time:new FormControl(''),
      date:new FormControl(''),
      AG_ID: new FormControl(''),
      TL_ID:new FormControl(''),
      week: new FormControl(environment.CurrentWeek,[Validators.required])      
    }); 
    // if(this.loggedInUserParse.category=="Admin"){
    //   this.EditOrderForm.addControl('TL_ID', new FormControl(this.loggedInUserParse.id, Validators.required));
    //   this.EditOrderForm.addControl('empCategory', new FormControl(this.loggedInUserParse.category, Validators.required));
    // }if(this.loggedInUserParse.category=="TL"){
    //   this.EditOrderForm.addControl('TL_ID', new FormControl(this.loggedInUserParse.id, Validators.required));
    //   this.EditOrderForm.addControl('empCategory', new FormControl(this.loggedInUserParse.category, Validators.required));
    // }if(this.loggedInUserParse.category=="SA"){
    //   this.EditOrderForm.addControl('SA_ID', new FormControl(this.loggedInUserParse.id, Validators.required));
    //   this.EditOrderForm.addControl('empCategory', new FormControl(this.loggedInUserParse.category, Validators.required));
    // }if(this.loggedInUserParse.category=="AGT"){
    //   this.EditOrderForm.addControl('AGT_ID', new FormControl(this.loggedInUserParse.id, Validators.required));
    //   this.EditOrderForm.addControl('empCategory', new FormControl(this.loggedInUserParse.category, Validators.required));
    // }
    this.store.pipe(select(fromOrderStatus.getorderStatus)).subscribe(status=> this.statusList=status);
    this.store.pipe(select(fromProduct.getProduct)).subscribe(products=>this.productList=products);   
    this.store.pipe(select(fromCourse.getCourse)).subscribe(course=>this.CourseList=course);
    console.log('1');    
  }  
  ngOnInit(): void {   
    //const order$:Observable<orders[]>= this.store.select(fromOrder.getEditOrders)    
    console.log("data=",this.data.orderID);
    this._orderService.fetchSingleOrder(this.data.orderID).subscribe((order:orders[])=>{
      console.log("orde66r=",order)
      if(order){        
        this.ReOrderForm.patchValue({
          id:order[0].id,
          cname:order[0].customerName,
          product:order[0].productID,
          mobile:order[0].customerMobile,
          altmobile : order[0].customerAlternateMobile,
          course: order[0].courseID,
          bprice:order[0].BasePrice,
          price: order[0].confirmedPrice,
          pincode: order[0].pincode,
          postOffice:order[0].postOffice,
          state:order[0].state,
          landmark:order[0].landmark, 
          city: order[0].city,
          remark:order[0].remark,
          address:order[0].addressLine,
          orderStatusN:order[0].orderStatus,
          time:this.formatTime(order[0].orderTime),
          date:order[0].orderDate,
          AG_ID:order[0].AG_ID,
          TL_ID:order[0].TL_ID
        })        
        this.dummyReOrder=order[0]
        console.log('2e',order[0],this.dummyReOrder);    
        this.pinapi.pincode(order[0].pincode).subscribe((payload)=>{
          this.postOfficeList=payload.postOffice;
        })       
      }
    })  
      
  }
  get orderID(){return this.ReOrderForm.get('id')?.value}
  ReOrderAction(formDirective:FormGroupDirective){
    this._leaseOrderConfirmationAgent.getLeastOrderConfirmationAgent().subscribe(leastOrderAgentPayload=>{
        this.ReOrderForm.addControl('actionBy',new FormControl(this.loggedInUserParse.username+'-'+this.loggedInUserParse.fname+' '+this.loggedInUserParse.lname))
        this.ReOrderForm.addControl('productName',new FormControl(this.getProductTitle(this.ReOrderForm.controls['product'].value)));
        this.ReOrderForm.addControl('statusName',new FormControl(this.getOrderStatusTitle(this.ReOrderForm.controls['orderStatusN'].value)));
        this.ReOrderForm.addControl('courseName',new FormControl(this.getCourseTitle(this.ReOrderForm.controls['course'].value)));    
        if(this.loggedInUserParse.category=="104"){      
          this.ReOrderForm.controls['TL_ID'].setValue(this.loggedInUserParse.id);   
          this.ReOrderForm.controls['AG_ID'].setValue(''); 
        }if(this.loggedInUserParse.category=="108"){
          this.ReOrderForm.controls['TL_ID'].setValue(this.loggedInUserParse.id);
          this.ReOrderForm.controls['AG_ID'].setValue('');
        }if(this.loggedInUserParse.category=="105"){
          this.ReOrderForm.controls['AG_ID'].setValue(this.loggedInUserParse.id);
          this.ReOrderForm.controls['TL_ID'].setValue(leastOrderAgentPayload);
        }    
        this._orderService.CreateReOrder(formDirective.value).subscribe((payload)=> {
          console.log("ererere",payload);
          if(payload){
            console.log("fg")
            this.dialogRef.close();
            this.store.dispatch(new OrderAction.LoadOrders);
          }
        })
  })
  }
  GetPinCodeDetails(event:any){
    if(event.target.value.length=='6'){
      this.pinapi.pincode(event.target.value).subscribe((payload)=>{
        if(payload !='Error'){
          this.ReOrderForm.controls['state'].setValue(payload.state);
          this.ReOrderForm.controls['city'].setValue(payload.city);
          this.postOfficeList=payload.postOffice;
        }else{
          Swal.fire({
            title: 'OOPS...WRONG PINCODE',
            text: 'Please Enter Correct Code!',
            icon:'error',
            width: 600,
            padding: '3em',
            background: '#fff',
            customClass: {
              container:'swalbackgroundMargin',
              // popup:'popupcustom'
            },
            focusConfirm: false,
            confirmButtonText:'<i class="fa fa-thumbs-down"></i> Cancel',
          })
          this.ReOrderForm.controls['state'].setValue('');
          this.ReOrderForm.controls['city'].setValue('');
          this.postOfficeList=[]
        }        
      });
    }
  }
  getProductTitle(productID:number):any{
    return this.productList.find((product)=>product.id==productID)?.name
  }
  onSelectionChange(event:any){
    this.ReOrderForm.controls['bprice'].setValue(this.CourseList.find((course)=>course.id ===event.option.value)?.price)
  }
  getCourseTitle(courseID:number):any { 
    console.log(this.CourseList)  
    return this.CourseList.find((course) => course.id==courseID)?.name    
  }
  getPostOffice(name:string):any{
    return this.postOfficeList.find((postOffice)=>postOffice.Name == name)?.Name;
  }
  getOrderStatusTitle(statusId:number):any{
    return this.statusList.find((status)=>status.id==statusId)?.name
  }
  formatTime(time:any):any {
    const dateArray=time.split(':');
    var hours= dateArray[0]<=12?dateArray[0]:dateArray[0]-12;
    hours=hours<10?'0'+hours:hours;
    var minutes= dateArray[1];
    minutes=minutes<10?'0'+minutes:minutes;
    var seconds= dateArray[2];
    var ampm = dateArray[0]>=12?'PM':'AM';
    return hours+':'+minutes+' '+ampm;
  }

}




