import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ShiftComponent } from '../shift/shift.component';
import * as fromShift from '../shift/shift.selectors'

@Component({
  selector: 'app-digital-clock',
  templateUrl: './digital-clock.component.html',
  styleUrls: ['./digital-clock.component.css']
})
export class DigitalClockComponent implements OnInit {
  private daysArray=['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  private date= new Date();
  public hour:any
  public minute!:string
  public second!:string
  public ampm!:string
  public day!:string
  public shiftTitle!:string
  constructor(private _shiftComp:ShiftComponent,private store:Store) { }

  ngOnInit(): void {
    setInterval(()=>{
      const date= new Date();
      this.updateDate(date);
    },1000)

    this.day= this.daysArray[this.date.getDay()]
    // getDay(), returns the day in interger format, from 0 to 6 then takes the correspondent day from the days araay 

    this._shiftComp.ngOnInit();
    this.store.pipe(select(fromShift.getShift)).subscribe(payload=>{     
      payload.map(el=>{
        console.log("text=",el.status,this.shiftTitle,typeof(el.status))
        if(el.status==true){
          //console.log(el.shiftName,'shiftName');
          this.shiftTitle=el.shiftName
        }
      })
    })

  }
  updateDate(date: Date) {
    const hours= date.getHours();// gets the hours from the date 
    this.ampm=hours>=12 ?'PM':'AM'
    this.hour= hours%12;
    this.hour= this.hour?this.hour:12;
    this.hour=this.hour<10?'0'+this.hour:this.hour
    const minutes= date.getMinutes();
    this.minute=minutes<10?'0'+minutes:minutes.toString();
    const seconds = date.getSeconds();
    this.second= seconds<10?'0'+seconds:seconds.toString()
  }



}
