import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LodingBarServiceService {
  private subject = new Subject();
  private keepAfterRouteChange = false;

  constructor(private router:Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart && this.keepAfterRouteChange) {
        this.keepAfterRouteChange = false;
        this.clear();
      }
    }); 
   }
   
  getLoadingBar(): Observable<any> {
    return this.subject.asObservable();
  }

  showBar(visible: boolean, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next(visible);
  }

  clear() {
    setTimeout(() => {
      this.subject.next();
      console.log("Loading Bar cleared...");
    }, 1000);
  }


}
