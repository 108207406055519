import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { orders } from "../orders/orders";
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { loadFilterOrders } from '../orders/order.actions';
import { OrderService } from '../orders/order.service';
import { LoadOrderActionButtons } from "../order-action-button/order-action-button.actions";
import * as orderActionbutton from "../order-action-button/order-action-button.actions" 



@Injectable()
export class OrderActionButtonEffects {
  constructor(private actions$: Actions,private _orderServices:OrderService) {
    console.log("action=");
  }
  loadOrders=createEffect(()=> {    
    return this.actions$.pipe(
     ofType(orderActionbutton.OrderActionButtonActionTypes.loadFilterOrders),
     mergeMap((action:any) => {
       console.log("action=",action)
       console.log("vd",this.actions$);       
         return this._orderServices.getOrdersP(action.payload.arg).pipe(
         map((orders) => new orderActionbutton.loadFilterOrders({ data: orders })),
         catchError(err => of(new orderActionbutton.LoadOrderActionButtonsFailure({ error: err })))
       );}
    )
   )
 })
}
