import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { OrderStatusService } from './order-status.service';
import * as orderStatusAction from "../order-status/order-status.actions"
import { of } from 'rxjs';
import { OrderStatus } from "./orderStatus";


@Injectable()
export class OrderStatusEffects {
  constructor(private actions$: Actions,private _orderStatusServices:OrderStatusService) {}
  loadorderStatus=createEffect(()=> {
    return this.actions$.pipe(
     ofType(orderStatusAction.OrderStatusActionTypes.LoadOrderStatuss),
     mergeMap(
       action => this._orderStatusServices.fetchOrderStatus().pipe(
         map((orderStatus) => new orderStatusAction.LoadOrderStatussSuccess({data:orderStatus})),
         catchError(err=> of(new orderStatusAction.LoadOrderStatussFailure({error:err})))
       )
     )
   )
 })

}