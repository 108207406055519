import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LoadingBarComponent } from '../loading-bar/loading-bar.component';
import { LodingBarServiceService } from '../loading-bar/loding-bar-service.service';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { LoginAudit } from './login-audit';
import * as LoginAuditDetails from './login-audit.actions'
import * as fromLoginAudit from './login-audit.selectors'

@Component({
  selector: 'app-login-audit',
  templateUrl: './login-audit.component.html',
  styleUrls: ['./login-audit.component.css']
})
export class LoginAuditComponent implements OnInit {

  pageTtitle:string='Login History'
  loginAuditDetails:LoginAudit[]=[];
  sideBar=''
  rowData:LoginAudit[]=[];
  paginationPageSize:number=19
  constructor(private lbs: LodingBarServiceService,private store:Store) { }

  ngOnInit(): void {
    this.lbs.showBar(true);
    // this.store.dispatch(new LoginAuditDetails.LoadLoginAudits);
    // this.store.pipe(select(fromLoginAudit.getLoginAuditDetails)).subscribe(payload=>this.loginAuditDetails=payload)
    this.lbs.clear()    
  }
  defaultColDef = {
    editable:false,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:90,
    sideBar:true
  };
  columnDefs = [
    {headerName: 'User Id', field: 'User Id',hide:false,cellRendererFramework:TooltipComponent},
    {headerName: 'Status', field: 'LoginStatus',hide:false,cellRendererFramework:TooltipComponent},
    {headerName: 'Last Login', field: 'lasLogin',hide:false,cellRendererFramework:TooltipComponent},
    {headerName: 'Logout Time', field: 'logoutTime',hide:false,cellRendererFramework:TooltipComponent},
    {headerName: 'Action', field: 'action',hide:false,cellRendererFramework:TooltipComponent}
  ]
  onResize(event:any){
    
  }


}
