import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { UtilService } from 'src/app/utilities/util.service';
import { ShippingDetailsService } from './shipping-details.service';

@Component({
  selector: 'app-shipping-details',
  templateUrl: './shipping-details.component.html',
  styleUrls: ['./shipping-details.component.css']
})
export class ShippingDetailsComponent implements OnInit {
  completeParamView: any;
  paramsData: any;
  courierName:any;
  awb_code:any
  isTrackOrderDialogOpned:boolean=false;
 
  
  constructor(private _shippingService:ShippingDetailsService,private _utils:UtilService,private dialog:MatDialog) { }
  agInit(params:any){
    this.paramsData=params.data;
    this.completeParamView=params;
    this.courierName=params.data.courierName;
    this.awb_code=params.data.awb_no;
    //console.log("awb no=",params)
  }

  ngOnInit(): void {

  }
  trackOrder(event:any){
    const courier_name=this.courierName;
    //    console.log("df",this.courierName)
    const orderID=this.paramsData.id;
    const orderDate=this.paramsData.orderDate;
    this._utils.validateToken().subscribe(tokenPayload=>{
      if(tokenPayload){
        this._shippingService.getTrackingDetails(this.paramsData.awb_no,tokenPayload).subscribe((Trackpayload:any)=>{
          console.log("tracking=",Trackpayload);
          if(!this.isTrackOrderDialogOpned){
            const dialogRef = this.dialog.open(TrackOrderDialog,{
              disableClose:false,
              data:{Trackpayload,tokenPayload,courier_name,orderID,orderDate},
              width: '600px'
              
            });   
            this.isTrackOrderDialogOpned = !this.isTrackOrderDialogOpned;
            dialogRef.afterClosed().subscribe((result:any) => {
              this.isTrackOrderDialogOpned = !this.isTrackOrderDialogOpned;
              //console.log(`Dialog result: ${result}`);
            });
          }
        })
      }
  })
}
}



@Component({
  selector: 'track-shipping-Order',
  templateUrl: './shipping-track-order.html',
  styleUrls: ['./shipping-details.component.css']
})
export class TrackOrderDialog implements OnInit{
  successHeader:any='Track Order';
  shipment_track_activities!:any[];
  shipment_id:any
  shipment_track:any;  
  courier_name:any
  trackingID:any;
  orderID:any;
  orderDate:any;
  constructor( @Inject(MAT_DIALOG_DATA) public TrackData: any,public dialogRef: MatDialogRef<TrackOrderDialog>,private _utils:UtilService,private _shippingTracking: ShippingDetailsService){        
    console.log("g=",this.TrackData,this.TrackData.Trackpayload.tracking_data.track_status);
    if(this.TrackData.Trackpayload.tracking_data.track_status){
    this.shipment_id=this.TrackData.Trackpayload.tracking_data.shipment_track[0].id;   
    this.shipment_track_activities=this.TrackData.Trackpayload.tracking_data.shipment_track_activities;     
    this.shipment_track=this.TrackData.Trackpayload.tracking_data.shipment_track[0];   
    this.courier_name=this.TrackData.courier_name;    
    this.trackingID=this.shipment_track.awb_code;    
    this.orderID=this.TrackData.orderID;    
    this.orderDate=this.TrackData.orderDate
    console.log("Track data",this.TrackData);
  }
}
  ngOnInit(): void {
    
  }
  cancelShipment(orderID:any){
    console.log("orderID",orderID);
    this._utils.validateToken().subscribe(tokenPayload=>{
      if(tokenPayload){
        this._shippingTracking.shipmentCancel(orderID,tokenPayload).subscribe(payload=>{
          console.log("payload=",payload);
        })
      }
    })
  }
}
