import { Component, Injectable, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Courier } from './corier';
import { CourierService } from './courier.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { LodingBarServiceService } from '../loading-bar/loding-bar-service.service';


interface courierPriority{
  id:number,
  name:string
}

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-courier',
  templateUrl: './courier.component.html',
  styleUrls: ['./courier.component.css'],
  animations: [
    trigger('widthGrow', [
        state('closed', style({
          height:  0,
        })),
        state('open', style({
          height: '80px'
        })),
        transition('* => *', animate(80))
    ]),
]
})

export class CourierComponent implements OnInit {

  
  state = "closed";
  CourierSectionSwitch:boolean=false
  addCourierForm!:FormGroup
  paginationPageSize:number=19
  rowData!:Courier[]
  pageTitle :string="Courier Partner"
  customPriority:boolean=false
  timePeriods:courierPriority[] = [];
  defaultColDef = {
    editable:true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:100,
    sideBar:true
  };
  columnDefs=[
    {headerName: 'Id', field: 'id',hide:false},
    {headerName: 'Name', field: 'name',hide:false},
    {headerName: 'Priority', field: 'priority',hide:false}
  ]
  
  constructor(private _courier:CourierService,private lbs:LodingBarServiceService) { 
    this.addCourierForm=new FormGroup({
      CourierName: new FormControl('',[Validators.required])
    })
  }

  onResize($event:any){

  }
  drop(event: CdkDragDrop<string[]>) {
    this.lbs.showBar(true);
    moveItemInArray(this.timePeriods, event.previousIndex, event.currentIndex)
    this._courier.updateCourierPriority(this.timePeriods).subscribe(payload=>{
      if(payload){
        this.LoadCourier();
        this.lbs.clear();
      }
    })    
  }

  ngOnInit(): void {
    this.lbs.showBar(true);
    this.LoadCourier();
    this.lbs.clear()
  }
  AddCourier(){
    (this.state == "closed") ? this.state = "open" : this.state = "closed";
    this.CourierSectionSwitch=!this.CourierSectionSwitch    
  }
  LoadCourier(){
    this.lbs.showBar(true)
    this._courier.fetchCourier().subscribe(payload=>{
      if(payload){
        this.rowData=payload;
        if(!this.timePeriods.length){
          payload.map((el,index)=>{
            this.timePeriods.push({id:el.id,name:el.name});
          })
        }
        this.lbs.clear() 
      }
    })
  }
  updateCourierPriority(){
    if(!this.customPriority){
      // this._courier.updateCourierPriority(this.timePeriods).subscribe(payload=>{
      //   console.log(payload);
      // })
    }
  }
  submitCourier(formDirective:FormGroupDirective){
    if(formDirective.valid){
      this._courier.addNewCourier(this.addCourierForm.value).subscribe(payload=>{
        console.warn("payload",payload)
        if(payload){
          formDirective.resetForm()
          this.LoadCourier()
        }
      })      
    }
  }


}
