import { Component, OnInit } from '@angular/core';
import { LodingBarServiceService } from "./loding-bar-service.service";

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.css']
})
export class LoadingBarComponent implements OnInit {

  loading = false;
  constructor(private lbs: LodingBarServiceService) { }

  ngOnInit(): void {
    this.lbs.getLoadingBar().subscribe(visible => {
      this.loading = visible
    });
  }

}
