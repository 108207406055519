import { createFeatureSelector, createSelector } from '@ngrx/store';
import {State  } from "./course.reducer";

const getCourseFeatureState=createFeatureSelector<State>('Course');

export const getCourse = createSelector(
    getCourseFeatureState,
    state => state.course
)

export const getError = createSelector(
    getCourseFeatureState,
    state => state.error
)
export const createProduct= createSelector(
    getCourseFeatureState,
    state=>state.course
)

