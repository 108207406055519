<form class="EditOrderForm" (ngSubmit)="EditOrderAction(formDirective)" [formGroup]="EditOrderForm" #formDirective="ngForm">
    <mat-dialog-content class="mat-typography">
        <h5 class="successHeader" *ngIf='successHeader' [@fade]="'out'">User Created Successfully</h5>
        <h2 mat-dialog-title style="text-align: center;" class="editOrderHeading">
            EDIT ORDER: {{orderID}}
            <span></span>
        </h2>
        <div class="EditorderSectionDateTime">
            <span>&nbsp;&nbsp;{{EditOrderForm.controls.date.value}},&nbsp;{{EditOrderForm.controls.time.value}}</span>
        </div>
        <div class="example-container">
            <mat-form-field appearance="legacy">
                <mat-label>Customer Name</mat-label>
                <input matInput placeholder="Enter customer name" formControlName="cname" required>
                <mat-error *ngIf="EditOrderForm.controls['cname'].hasError('required')">
                    This is <strong>required</strong> field
                </mat-error>
                <mat-error *ngIf="EditOrderForm.controls['cname'].hasError('pattern')">
                    Only Alphbates can be accepted
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="legacy">
                <mat-label>Contact Number</mat-label>
                <input type='tel' matInput placeholder="Enter your mobile" formControlName="mobile" required>
                <mat-error *ngIf="EditOrderForm.controls['mobile'].hasError('required')">
                    This is <strong>required</strong> field
                </mat-error>
                <mat-error *ngIf="EditOrderForm.controls['mobile'].hasError('maxlength')">
                    Mobile contains more than <strong>10</strong> digits
                </mat-error>
                <mat-error *ngIf="EditOrderForm.controls['mobile'].hasError('minlength')">
                    Mobile contains less than <strong>10</strong> digits
                </mat-error>
                <mat-error *ngIf="EditOrderForm.controls['mobile'].hasError('pattern')">
                    Only Digits can be accepted
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="legacy">
                <mat-label>Alt Contact Number</mat-label>
                <input type='tel' matInput placeholder="Enter your mobile" formControlName="altmobile" required>
                <mat-error *ngIf="EditOrderForm.controls['altmobile'].hasError('required')">
                    This is <strong>required</strong> field
                </mat-error>
                <mat-error *ngIf="EditOrderForm.controls['altmobile'].hasError('maxlength')">
                    Mobile contains more than <strong>10</strong> digits
                </mat-error>
                <mat-error *ngIf="EditOrderForm.controls['altmobile'].hasError('minlength')">
                    Mobile contains less than <strong>10</strong> digits
                </mat-error>
                <mat-error *ngIf="EditOrderForm.controls['altmobile'].hasError('pattern')">
                    Only Digits can be accepted
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Product</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Product" matInput formControlName="product" [matAutocomplete]="product" required>
                <mat-autocomplete #product="matAutocomplete" [displayWith]="getProductTitle.bind(this)">
                    <mat-option *ngFor="let product of productList index by id" [value]="product.id">
                        {{product.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="example-container">
            <mat-form-field class="example-full-width course">
                <mat-label>Course</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Course" matInput formControlName="course" [matAutocomplete]="course" required>
                <mat-autocomplete #course="matAutocomplete" (optionSelected)="onSelectionChange($event)" [displayWith]="getCourseTitle.bind(this)">
                    <mat-option *ngFor="let course of CourseList index by id" [value]="course.id">
                        {{course.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="example-full-width price">
                <mat-label>Base Price</mat-label>
                <input type="number" placeholder="Pick one" aria-label="Base Price" matInput formControlName="bprice" required readonly>
            </mat-form-field>
            <mat-form-field class="example-full-width price">
                <mat-label>Offered Price</mat-label>
                <input type="tel" placeholder="Pick one" aria-label="price" matInput formControlName="price" (focusin)="onFocusInEvenPrice($event)" (focusout)="onFocusOutEvenPrice($event)" required>
                <mat-error *ngIf="EditOrderForm.controls['price'].hasError('maxlength')">
                    Price can only be <strong>4</strong> digits Long
                </mat-error>
                <mat-error *ngIf="EditOrderForm.controls['price'].hasError('pattern')">
                    Only Digits can be accepted
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width pincode">
                <mat-label>Pin Code</mat-label>
                <input type="tel" placeholder="Pick one" aria-label="pincode" matInput formControlName="pincode" (keyup)='GetPinCodeDetails($event)' required>
                <mat-error *ngIf="EditOrderForm.controls['pincode'].hasError('maxlength')">
                    more than <strong>6</strong> digits
                </mat-error>
                <mat-error *ngIf="EditOrderForm.controls['pincode'].hasError('minlength')">
                    less than <strong>6</strong> digits
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Post Office</mat-label>
                <input type="text" placeholder="Pick one" aria-label="postOffice" matInput formControlName="postOffice" [matAutocomplete]="postOffice" required>
                <mat-autocomplete #postOffice="matAutocomplete">
                    <mat-option *ngFor="let postOffice of postOfficeList" [value]="postOffice.Name">
                        {{postOffice.Name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="example-full-width state">
                <mat-label>State</mat-label>
                <input type="text" placeholder="Pick one" aria-label="state" matInput formControlName="state" [matAutocomplete]="state" required>
                <mat-autocomplete #state="matAutocomplete">
                    <mat-option *ngFor="let state of stateList" [value]="state">
                        {{state}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="example-container">
            <mat-form-field class="example-full-width">
                <mat-label>City</mat-label>
                <input type="text" placeholder="Pick one" aria-label="city" matInput formControlName="city" [matAutocomplete]="city" required>
                <mat-autocomplete #city="matAutocomplete">
                    <mat-option *ngFor="let city of cityList" [value]="city">
                        {{city}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="example-full-width landmark" appearance="legacy">
                <mat-label>Landmark</mat-label>
                <input matInput #landmark maxlength="256" placeholder="Landmark" formControlName="landmark" required>
                <mat-hint align="start"><strong>Landmark should be around 100 to 500 meters</strong> </mat-hint>
                <mat-hint align="end">{{landmark.value.length}} / 256</mat-hint>
            </mat-form-field>
            <mat-form-field class="example-full-width remark" appearance="legacy">
                <mat-label>Remark</mat-label>
                <input matInput #message maxlength="256" placeholder="Remark" formControlName='remark' required>
                <mat-hint align="start"><strong>Comment about Order/Customer update </strong> </mat-hint>
                <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
            </mat-form-field>
        </div>
        <div class="example-container">
            <mat-form-field class="example-full-width address" appearance="legacy">
                <mat-label>Address</mat-label>
                <textarea matInput placeholder="Complete Address" formControlName='address' required></textarea>
            </mat-form-field>
            <!-- <mat-form-field class="example-full-width ostatus" *ngIf="dummyEditOrder.orderCategory =='MOBILE'"> -->
            <mat-form-field class="example-full-width ostatus">
                <mat-label>Order Status</mat-label>
                <input type="text" placeholder="Pick one" aria-label="orderStatusN" matInput formControlName="orderStatusN" [matAutocomplete]="orderStatusN" required>
                <mat-autocomplete #orderStatusN="matAutocomplete" [displayWith]="getOrderStatusTitle.bind(this)">
                    <mat-option *ngFor="let status of statusList index by id" [value]="status.id">
                        {{status.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="example-full-width ostatus" *ngIf="dummyEditOrder.orderCategory == 'ONLINE' && loggedInUserParse.category =='105' ">
                <mat-label>Order Status</mat-label>
                <input type="text" placeholder="Pick one" aria-label="orderStatusN" matInput formControlName="orderStatusN" [matAutocomplete]="orderStatusN" required>
                <mat-autocomplete #orderStatusN="matAutocomplete" [displayWith]="getOrderStatusTitle.bind(this)">
                    <mat-option [value]="10">NOT PICK</mat-option>
                    <mat-option [value]="11">CANCEL</mat-option>
                    <mat-option [value]="12">FAKE</mat-option>
                    <mat-option [value]="9">HOLD</mat-option>
                    <mat-option [value]="8">CONFIRM</mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <mat-dialog-actions align="end">
            <button mat-raised-button mat-dialog-close>Cancel</button>
            <button mat-raised-button color="accent" type="reset">Reset</button>
            <button mat-raised-button color="primary" type="submit" [disabled]="!EditOrderForm.valid">Update</button>
        </mat-dialog-actions>
        <div>
            <mat-chip-list aria-label="Fish selection">
                <mat-chip>Confirmation :{{dummyEditOrder.teamlead}}</mat-chip>
                <mat-chip>Agent: {{dummyEditOrder.agentName}} </mat-chip>
            </mat-chip-list>
            <!-- <mat-form-field appearance="legacy" class="example-full-width"> -->
            <!-- Team Lead: {{}} &nbsp; Super Agent:{{}} &nbsp; Agent: {{}} -->
            <!-- <input type='text' matInput placeholder="Agent Name" formControlName="AG_ID"> -->
            <!-- </mat-form-field> -->
            <!-- <mat-form-field appearance="legacy" class="example-full-width"> -->

            <!-- <input type='text' matInput placeholder="Super Agent" formControlName="SA_ID" >       -->
            <!-- </mat-form-field> -->
            <!-- <mat-form-field appearance="legacy" class="example-full-width"> -->
            <!-- <input type='text' matInput placeholder="Team Lead" formControlName="TL_ID" > -->
            <!-- </mat-form-field> -->
        </div>
    </mat-dialog-content>
</form>
<style>
    .example-container .mat-form-field+.mat-form-field {
        margin-left: 8px;
    }
    
    .example-container {
        text-align: center;
        padding: 5px;
    }
    
    .successHeader {
        top: 0;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        text-align: center;
        background: #0080009e;
        color: #fff;
    }
</style>
<app-waiting-image *ngIf="waitingImage"></app-waiting-image>