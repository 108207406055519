import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { ShiftActionButtonComponent } from '../shift-action-button/shift-action-button.component';
import { Shift } from './Shift';
import { ShiftService } from './shift.service';
import * as ShiftActions from './shift.actions';
import * as fromShift from "./shift.selectors";
import { LodingBarServiceService } from '../loading-bar/loding-bar-service.service';


@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.css']
})
export class ShiftComponent implements OnInit {

  pageTtitle:string="Shift Manager"
  sideBar:boolean = false;
  paginationPageSize:number=19;
  rowData:Shift[]=[]
  constructor(private _shitService:ShiftService,private store:Store,private lbs:LodingBarServiceService) { }

  defaultColDef = {
    editable:true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:100,
    sideBar:true
  };
  columnDefs=[
    {headerName: 'Action',cellRendererFramework:ShiftActionButtonComponent,checkboxselection:true,headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true, resizable: true,minWidth:150},
    {headerName: 'Id', field: 'id',rowDrag:true,hide:false},
    {headerName: 'Shift ', field: 'shiftName',hide:false},
    {headerName: 'Start ', field: 'startTime',hide:false},
    {headerName: 'End ', field: 'endDate',hide:false},
    {headerName: 'Hours ', field: 'duration',hide:false},
    {headerName: 'Status', field: 'status',hide:false}
  ]
  ngOnInit(): void {
    // this._shitService.fetchShift().subscribe(payload=>{
    //   this.rowData=payload;
    // })
    this.lbs.showBar(true);
    this.store.dispatch(new ShiftActions.LoadShifts)
    this.store.pipe(select(fromShift.getShift)).subscribe(payload=>{
      this.rowData=payload
      this.lbs.clear()
    })
  }
  CreateShift(){

  }
  onResize(event:any){
    console.log("d")
  }
  refreshShift(){
    this.store.dispatch(new ShiftActions.LoadShifts);
  }
}

