import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-users',
  templateUrl: './online-users.component.html',
  styleUrls: ['./online-users.component.css']
})
export class OnlineUsersComponent implements OnInit {

  pageTtitle:string='Online User'
  sideBar='';

  
  constructor() { }

  ngOnInit(): void {
  }

}
