import { Action } from '@ngrx/store';
import { product } from './product';
import { ProductActions,ProductActionTypes } from "./product.actions";


export const productFeatureKey = 'product';
export const postReducers='reducer';

export interface State {
  product:product[],
  error:string
}

export const initialState: State = {
  product:[],
  error:""
};

export function reducer(state = initialState, action: ProductActions): State {
  switch (action.type) {
    case ProductActionTypes.LoadProducts:
      return{
        ...state
      }
    case ProductActionTypes.LoadProductsSuccess:
      return {
        ...state,
        product:action.payload.data,
        error:""
      }
    case ProductActionTypes.LoadProductsFailure:
      return {
        ...state,
        error:action.payload.error
      }

    default:
      return state;
  }
}
