import { AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditor, ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'app-gender-renderer',
  templateUrl: './gender-renderer.component.html',
  styleUrls: ['./gender-renderer.component.css']
})
export class GenderRendererComponent implements ICellEditorAngularComp,AfterViewInit {

  CustomDepList = ["TELLECALLING","TRACKING","MANAGEMENT","ADMIN","SUPERUSER"];
  public params: any;
  public CustomDep: any;
  @ViewChild('input', {read:ViewContainerRef}) public input: any;
  constructor() { }
  ngAfterViewInit() {
    window.setTimeout(() => {
        console.log("2");
        this.input.element.nativeElement.focus();
    })
  }
  getValue() {
    console.log(this.CustomDep,"1");
    return this.CustomDep
  }
  agInit(params: ICellEditorParams): void {
    console.log("3");
    this.params=params;
    this.CustomDep=this.params.value
  }

  ngOnInit(): void {

  }

}
