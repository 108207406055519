 import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CourseService } from './course.service';
import * as CourseActions from './course.actions'
import { of } from 'rxjs';



@Injectable()
export class CourseEffects {
  constructor(private actions$: Actions,private _courseServices:CourseService) {}
  loadCourses=createEffect(()=> {
    return this.actions$.pipe(
     ofType(CourseActions.CourseActionTypes.LoadCourses),
     mergeMap(
       action => this._courseServices.fetchCourse().pipe(
         map((course) => new CourseActions.LoadCoursesSuccess({data:course})),
         catchError(err=> of(new CourseActions.LoadCoursesFailure({error:err})))
       )
     )
   )
 })

}
