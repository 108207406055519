
<app-loading-bar></app-loading-bar>
<form class="assignOrderForm" (ngSubmit)="assignOrderAction(formDirective)" [formGroup]="assignOrderForm" #formDirective="ngForm" autocomplete="off">
    <mat-dialog-content class="mat-typography">
        <h5 class="successHeader" *ngIf='successHeader' [@fade]="'out'">Orders assigned successfully</h5>
        <h2 mat-dialog-title style="text-align: center;">Assign Order</h2>
        <div class="example-container">
            <mat-form-field class="example-full-width course" appearance="legacy">
              <mat-label>Agents</mat-label>
              <input type="text"
                     placeholder="Pick one"
                     aria-label="Users"
                     matInput
                     formControlName="users"
                     [matAutocomplete]="users" required>
              <mat-autocomplete #users="matAutocomplete" (optionSelected)="onSelectionChange($event)"  [displayWith]="getUserTitle.bind(this)">
                <mat-option *ngFor="let user of userList index by id"  [value]="user.id">
                  {{user.id}} - {{user.fname}} {{user.lname}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>        </div>
        <mat-dialog-actions align="end">
            <button mat-raised-button mat-dialog-close>Cancel</button>
            <button mat-raised-button color="accent" type="reset">Reset</button>
            <button mat-raised-button color="primary" type="submit" [disabled]= "!assignOrderForm.valid">Assign</button>
        </mat-dialog-actions>
        <section class="content" id="renderDataSection">
            <div class="container-fluid">
              <!-- Info boxes -->    
              <div class="row" >
                    <ag-grid-angular 
                    #OrderList
                    id='OrderList'
                    style="width: 1470px; height: 235px;"
                    class="ag-theme-balham"
                    [sideBar]="sideBar"
                    [rowData]="selectedOrdersList" 
                    [defaultColDef]="defaultColDef"
                    [columnDefs]="columnDefs"
                    [animateRows]='false'
                    [pagination]='true'
                    [paginationPageSize]="paginationPageSize"
                    (onColumnResized)="onResize($event)"              
                    >
                    </ag-grid-angular>
                </div>
            </div>
        </section>
  </mat-dialog-content>
</form>
<style>
    mat-form-field{
        width: 214px !important;
    }
    #renderDataSection{
        margin-top: 17px;
    }
</style>

  