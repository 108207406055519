import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as RoleSetup from './permission-roll-setup.actions'
import { PermissionRollSetupService } from './permission-roll-setup.service';



@Injectable()
export class PermissionRollSetupEffects {

  constructor(private actions$: Actions, private _perimssionRollSetupService:PermissionRollSetupService) {}
  loadPermissionRollSetup$=createEffect(()=> {
    return this.actions$.pipe(
      ofType(RoleSetup.PermissionRollSetupActionTypes.LoadPermissionRollSetups),
      mergeMap(() => this._perimssionRollSetupService.getRollSetup().pipe(
          map((role) => new RoleSetup.LoadPermissionRollSetupsSuccess({data:role})),
          catchError(err=> of(new RoleSetup.LoadPermissionRollSetupsFailure({error:err})))
        )
      )
    )
  })

}
