import { Action } from '@ngrx/store';
import { Shift } from './Shift';

export enum ShiftActionTypes {
  LoadShifts = '[Shift] Load Shifts',
  LoadShiftsSuccess = '[Shift] Load Shifts Success',
  LoadShiftsFailure = '[Shift] Load Shifts Failure',
}

export class LoadShifts implements Action {
  readonly type = ShiftActionTypes.LoadShifts;
}

export class LoadShiftsSuccess implements Action {
  readonly type = ShiftActionTypes.LoadShiftsSuccess;
  constructor(public payload: { data: Shift[] }) { }
}

export class LoadShiftsFailure implements Action {
  readonly type = ShiftActionTypes.LoadShiftsFailure;
  constructor(public payload: { error: any }) { }
}

export type ShiftActions = LoadShifts | LoadShiftsSuccess | LoadShiftsFailure;

