import { Component, OnInit,ViewChild,ElementRef,Input, asNativeElements } from '@angular/core';
import { MatFormFieldModule,matFormFieldAnimations,MatFormFieldControl,MatLabel,MatError,MAT_ERROR,MatFormField } from "@angular/material/form-field";
import { MatDialog} from '@angular/material/dialog';
import { FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from "../users/user.service";
import { WaitingImageComponent } from "../waiting-image/waiting-image.component";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LodingBarServiceService } from "../loading-bar/loding-bar-service.service";
import { ProfilePic } from "../../../environments/environment";
import {LeftMenuServiceService}  from "./left-menu-service.service"
import { department } from './department';

@Component({
  selector: 'app-left-menu-section',
  templateUrl: './left-menu-section.component.html',
  styleUrls: ['./left-menu-section.component.css']
})
export class LeftMenuSectionComponent implements OnInit {

  HeaderTitle:string='Cosmic 2.0';
  pageTtitle:string='dashboard';
  recievedOrders:number=0;
  confirmOrders:number=0;
  finalOrders:number=0;
  newEmploye:number=0;
  username:any="";
  category=['Super User','Manager','Teamlead','Super Agent','Agent']
  showDialog:boolean = false;
  dialogTitle:string="";
  isDialogOpned:boolean=false;
  public waitingImage:boolean=false;  
 
  constructor(public dialog: MatDialog) { }

  // showNewUserDialogBox(){
  //   this.dialogTitle="User Registration";
  //   this.showDialog=!this.showDialog;
  //   console.warn(this.showDialog);
  // }

  openDialog() {
    if(!this.isDialogOpned){
      const dialogRef = this.dialog.open(CreateUserDialog,{
        width: '800px'
      });   
      this.isDialogOpned = !this.isDialogOpned;
      dialogRef.afterClosed().subscribe(result => {
        this.isDialogOpned = !this.isDialogOpned;
        console.log(`Dialog result: ${result}`);
      });
    }
  }
  
  ngOnInit(){
     
  }
  onSubmit(){
    console.log("DSf");
  }
}

/**
 * @title Simple autocomplete
 */


@Component({
  selector: 'create-user-dialog',
  templateUrl:'create-user-dialog.html',
  styleUrls:['left-menu-section.component.css'],
  animations:[
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [ style({ opacity: 0 }), animate(600) ]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})

export class CreateUserDialog implements OnInit{

  public waitingImage:boolean=false; 
  // optionsDep: string[] = ['Super Users','Management', 'Tracking','Telecalling','Packing','Domestic Help'];  
  // optionsCat: string[] = ['Director','Dupty Manager', 'Manager','Assistant Manager','Tracking Head','General Tracking','Team Lead','Super Agent','Agent','Training Agent','Packer','Domestic Help'];  
  optionsDep!:department[]
  optionsDocument: string[] = ['Adhar Card', 'Pan Card', 'Driving License','Bank Passbook'];  
  registerForm:FormGroup; 
  maxDate!:Date
  successHeader:boolean=false;
  ProfilePicUrl:string='/dashborad/profileImage/'
  errorHeader: boolean=false;
  constructor(private sanitizer: DomSanitizer, private _userService:UserService,private lbs:LodingBarServiceService,private _leftMenuService:LeftMenuServiceService) {    
    this.registerForm = new FormGroup({ 
      //department: new FormControl('',[Validators.required]),
      category: new FormControl('',[Validators.required]),
      bsalary : new FormControl('',[Validators.required,Validators.pattern('^[0-9]*$')]),
      fname :new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z \-\']+')]),
      lname : new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z \-\']+')]),
      fathername : new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z \-\']+')]),
      idproof :new FormControl('', [Validators.required]),
      idproofDocument:new FormControl('',Validators.required),
      mobile : new FormControl('', [Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]),
      joiningDate: new FormControl('',Validators.required),
      //profilePic : new FormControl('',[Validators.required])
    });
   this._leftMenuService.fetchDepartment().subscribe(payload => {
     this.optionsDep=payload
   })
  
  }

  ngOnInit(){
    this.lbs.showBar(true);
    this.maxDate=new Date();    
  }
  dateFilter(date: any){
    console.log(date,'ds');
    var day = date.getDay();
    return day != 0
  }
  clear(){
    this.lbs.clear();
  }
  ngAfterViewInit(){
    this.lbs.clear()
  }
  getCategoryTitle(depID:number):any{
    return this.optionsDep.find((dep)=>dep.id==depID)?.category
  }

  multiple:boolean=false;  

  // department = new FormControl('',[Validators.required]);
  // category = new FormControl('',[Validators.required]);
  // bsalary = new FormControl('',[Validators.required,Validators.pattern('^[0-9]*$')]);  
  // fname = new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z \-\']+')]);
  // lname = new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z \-\']+')]);
  // fathername = new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z \-\']+')]);
  // idproof = new FormControl('', [Validators.required]);
  // mobile = new FormControl('', [Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]);
  // profilePic = new FormControl('',[Validators.required]);
  
  @Input()
  deleteButtonLabel="x"
  @Input()
  deleteButtonIcon = 'close'
  @Input()
  showUploadInfo:any
  

  @Input()
  chooseLabel = 'Choose';
  @ViewChild('fileUpload')
  fileUpload: ElementRef= new ElementRef<any>(asNativeElements);

  @Input()
  files: File[] = []

  @Input()
  accept:any;

  onClick(event:any) {
    if (this.fileUpload)
      this.fileUpload.nativeElement.click()
  }
  
  onInput(event:any) {

  }
  validate(file: File) {
    for (const f of this.files) {
      if (f.name === file.name
        && f.lastModified === file.lastModified
        && f.size === f.size
        && f.type === f.type
      ) {
        return false
      }
    }
    return true
  }
  onFileSelected(event:any) {
    let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    console.log('event::::::', event.target)
    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      //if(!this.isFileSelected(file)){
      if (this.validate(file)) {
        //      if(this.isImage(file)) {
        file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));
        this.ProfilePicUrl+=file.name;
        //console.log(file.objectURL,'URL',this.ProfilePicUrl);
        //      }

        this.files.push(files[i]);
        //  }
      }
      //}
    }
  }
  clearInputElement() {
    this.fileUpload.nativeElement.value = ''
  }
  isMultiple(): boolean {
    return this.multiple
  }
  removeFile(file:any) {
    let ix
    if (this.files && -1 !== (ix = this.files.indexOf(file))) {
      this.files.splice(ix, 1)
      this.clearInputElement()
    }
  }
  GenerateCompleteEmploye(){   
    //this.registerForm.addControl('password',new FormControl(this.GeneratePassword(),Validators.required));
    this.registerForm.addControl('dateOfCreation', new FormControl(new Date().toLocaleDateString(), Validators.required));
    //this.registerForm.addControl('JoiningDate', new FormControl(new Date().toDateString(), Validators.required))
    this.registerForm.addControl('year', new FormControl(new Date().getFullYear(), Validators.required));
    this.registerForm.addControl('month', new FormControl( new Date().toLocaleDateString('en-us',{month:'short'}), Validators.required));
    this.registerForm.addControl('profilePic',new FormControl(this.ProfilePicUrl,Validators.required))
  }
  registerAction(formDirective:FormGroupDirective){
    this.waitingImage=true;
    this.GenerateCompleteEmploye();
    //Field needs to be added in the user registration form 
    //password,dateOfCreation, JoiningDate, Year, Month
    console.log(this.registerForm.controls,this.registerForm,"j",this.registerForm.valid);
    if(this.registerForm.valid){
      this._userService.createUser(this.registerForm.value).subscribe(payload =>{
        console.log(payload,"tttt");
        if(payload){
          this.errorHeader=false;
          this.successHeader=true;
          formDirective.resetForm();
          this.registerForm.reset();
          setTimeout(()=>{
            this.successHeader=false;
          },3000);
          this.waitingImage=false;          
        }else{
          this.errorHeader=true;
          this.waitingImage=false;   
        }
      });
    }    
  }

}

