import { Component, OnInit } from '@angular/core';
import { Report } from '../report/report';

@Component({
  selector: 'app-report-action-button',
  templateUrl: './report-action-button.component.html',
  styleUrls: ['./report-action-button.component.css']
})
export class ReportActionButtonComponent implements OnInit {

  paramsData!:Report;
  completeParamView!: Report;
  constructor() { }
  agInit(params:any){
    this.paramsData=params.data;
    this.completeParamView=params;
  }
  ngOnInit(): void {

  }
  loginHistory($event:any){}
  perticularReport(){}
}
