<section class="permissionAssignedSection">
    <h2 mat-dialog-title style="text-align: center;" class="editOrderHeading">PERMISSION ASSIGNED:{{userID}}</h2>
    <span class="" (click)="refresh()"><button mat-mini-fab color="accent" type="reset" #tooltip="matTooltip" matTooltip="Refresh List"> <i class="fas fa-sync-alt" aria-hidden="true"></i></button></span>      
</section>
<ag-grid-angular 
              #PemrissionList
              id='PermissionList'
              style="width: 740px; height: 400px;overflow: scroll;"
              class="ag-theme-balham"
              [sideBar]="sideBar"
              [rowData]="permissionAssignedDetails" 
              [defaultColDef]="defaultColDef"
              [columnDefs]="columnDefs"
              [animateRows]='false'
              [pagination]='true'
              [paginationPageSize]="paginationPageSize"
              (onColumnResized)="onResize($event)"
              (rowSelected)="onRowSelected($event)"
              [rowSelection]="rowSelection"     
              [rowMultiSelectWithClick]="true"           
              >
</ag-grid-angular>