import { Injectable, NgModule, OnInit } from '@angular/core';
import { Resolve, RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import {DashboardPageComponent  } from "./dashboard/dashboard-page/dashboard-page.component";
import { UsersComponent } from './dashboard/users/users.component';
import { AuthGuard } from './auth/auth.guard';
import { OrdersComponent } from './dashboard/orders/orders.component';
import { ReportComponent } from './dashboard/report/report.component';
import { MailboxComponent } from './dashboard/mailbox/mailbox.component';
import { ProductComponent } from './dashboard/product/product.component';
import { WidgetsComponent } from './dashboard/widgets/widgets.component';
import { HelpComponent } from './dashboard/help/help.component';
import { GallaryComponent } from './dashboard/gallary/gallary.component';
import { EmployeeComponent } from './dashboard/employee/employee.component';
import { CourierComponent } from './dashboard/courier/courier.component';
import { SettingComponent } from './dashboard/setting/setting.component';
import { CourseComponent } from './dashboard/course/course.component';
import { PermissionComponent } from './dashboard/permissoin/permission.component';
import { DomainComponent } from './dashboard/domain/domain.component';
import { LoginAuditComponent } from './dashboard/login-audit/login-audit.component';
import { PermissionGaurdGuard } from './auth/permission-gaurd.guard';
import { PermissoinService } from './dashboard/permissoin/permissoin.service';
import { getPermission } from './dashboard/permissoin/permission.selectors';
import * as roleSetupAction from './dashboard/permission-roll-setup/permission-roll-setup.actions'
import * as fromRoleSetup from './dashboard/permission-roll-setup/permission-roll-setup.selectors'
import { prototype } from 'events';
import { select, Store } from '@ngrx/store';
import { PermissionRollSetupComponent } from './dashboard/permission-roll-setup/permission-roll-setup.component';
import { Page404Component } from './dashboard/page404/page404.component';
import { OrderStatusComponent } from './dashboard/order-status/order-status.component';
import { ShiftComponent } from './dashboard/shift/shift.component';

let userPermission:Array<any>=[];

export const routes: Routes = [
    {path:'', canActivate:[AuthGuard], component:LoginComponent},
    {path:'login',component:LoginComponent},
    {path:'Dashboard',canActivate:[AuthGuard],component:DashboardPageComponent},
    {
      path:'Users',
      canActivate:[AuthGuard,PermissionGaurdGuard],
      component:UsersComponent,
      data:{
        Roles:['40']
      }
    },
    {path:'Orders',canActivate:[AuthGuard],component:OrdersComponent},
    {path:'Report',canActivate:[AuthGuard,PermissionGaurdGuard], data:{Roles:['42']},component:ReportComponent},
    {path:'Email',canActivate:[AuthGuard],component:MailboxComponent},
    {path:'Product',canActivate:[AuthGuard],component:ProductComponent},
    {path:'Widget',canActivate:[AuthGuard],component:WidgetsComponent},
    {path:'Help',canActivate:[AuthGuard],component:HelpComponent},
    {path:'Gallary',canActivate:[AuthGuard],component:GallaryComponent},
    {path:'Employee',canActivate:[AuthGuard],component:EmployeeComponent},
    {path:'Product',canActivate:[AuthGuard],component:ProductComponent},
    {path:'Courier',canActivate:[AuthGuard],component:CourierComponent},
    {path:'Setting',canActivate:[AuthGuard],component:SettingComponent},
    {path:'Course',canActivate:[AuthGuard],component:CourseComponent},
    {path:'Domain',canActivate:[AuthGuard],component:DomainComponent},
    {path:'Permission',canActivate:[AuthGuard],component:PermissionComponent},
    {path:'LoginHistory',canActivate:[AuthGuard],component:LoginAuditComponent},
    {path:'OrderStatus',canActivate:[AuthGuard],component:OrderStatusComponent},
    {path:'Shift',canActivate:[AuthGuard],component:ShiftComponent},
    {path: '**', redirectTo: '',canActivate:[AuthGuard] ,component:LoginComponent},
    {path:'Page404',canActivate:[AuthGuard],component:Page404Component}    
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})


export class AppRoutingModule{ 
  constructor(private store:Store){
    //console.log("efer");
    //this.roleSetCom.ngOnInit();    
    //console.log(this.roleSetCom.userRolePermission,"dfdf")
    // this.store.dispatch(new roleSetupAction.LoadPermissionRollSetups);
    // this.store.pipe(select(fromRoleSetup.getPermission)).subscribe(roles => {console.log(roles,"roles");userPermission=roles});
    //console.log("calling..111",userPermission);

  }
  
}


 
