import { Action } from '@ngrx/store';
import { stat } from 'node:fs';
import { Course } from './course';
import { CourseActionTypes,CourseActions } from "./course.actions";


export const courseFeatureKey = 'Course';
export const postReducers='reducer';

export interface State {
  course:Course[],
  error:string
}

export const initialState: State = {
  course:[],
  error:""
};

export function reducer(state = initialState, action: CourseActions): State {
  switch (action.type) {
    case CourseActionTypes.LoadCourses:
      return {
        ...state
      }
    case CourseActionTypes.LoadCoursesSuccess:
      return{
        ...state,
        course:action.payload.data,
        error:""
      }
    case CourseActionTypes.LoadCoursesFailure:
      return {
        ...state,
        error:action.payload.error
      }

    default:
      return state;
  }
}
