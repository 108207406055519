import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ShiftService } from './shift.service';
import * as ShiftAction from './shift.actions'
import { ShiftActionTypes } from './shift.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Shift } from "./Shift";
import { of } from 'rxjs';



@Injectable()
export class ShiftEffects {
  constructor(private actions$: Actions,private _shiftService:ShiftService) {}
  loadReport=createEffect(()=> {
    return this.actions$.pipe(
    ofType(ShiftAction.ShiftActionTypes.LoadShifts),
    mergeMap(
      action => this._shiftService.fetchShift().pipe(
        map((shift) => new ShiftAction.LoadShiftsSuccess({data:shift})),
        catchError(err=> of(new ShiftAction.LoadShiftsFailure({error:err})))
      )
    )
  )
})

}
