import { Action, createReducer, on } from '@ngrx/store';
import { LeastOrderConfirmationAgentActionTypes,LeastOrderConfirmationAgentActions } from "./least-order-confirmation-agent.actions";


export const leastOrderConfirmationAgentFeatureKey = 'leastOrderConfirmationAgent';

export interface State {
  confirmationAgent:any[],
  error:string
}

export const initialState: State = {
  confirmationAgent:[],
  error:""
};

export function reducer(state = initialState, action: LeastOrderConfirmationAgentActions): State {
  switch (action.type) {
    case LeastOrderConfirmationAgentActionTypes.LoadLeastOrderConfirmationAgents:
      return {
        ...state
      }
    case LeastOrderConfirmationAgentActionTypes.LoadLeastOrderConfirmationAgentsSuccess:
      return{
        ...state,
        confirmationAgent:action.payload.data,
        error:""
      }
    case LeastOrderConfirmationAgentActionTypes.LoadLeastOrderConfirmationAgentsFailure:
      return {
        ...state,
        error:action.payload.error
      }
    default:
      return state;
  }
}

