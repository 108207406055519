<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!------ Include the above in your HEAD tag ---------->
<div [ngClass]="{flexDisplay: isDisplayPwdResetBlock==false}" class="bg">
    <div class="wrapper fadeInDown">
        <div id="formContent">
          <!-- Tabs Titles -->
      
          <!-- Icon -->
          <div class="fadeIn first login-logo">
            <img src="../../assets/user-login/login-logo.png" id="icon" alt="User Icon" />
          </div>
      
          <!-- Login Form -->
          <form autoComplete="off" (ngSubmit)="signInUser()" [formGroup]='signInForm'> 
            <input type="text" id="login" class="fadeIn second" formControlName='username' placeholder="Username">
            <div *ngIf="signInForm.controls['username'].hasError('required') && signInForm.controls['username'].touched" class='errorColor'>Enter username</div>
            <input type="password" id="password" class="fadeIn third" formControlName='password' placeholder="********">
            <div *ngIf="signInForm.controls['password'].hasError('required') && signInForm.controls['password'].touched" class='errorColor'>Enter password</div>
            <input type="submit" class="fadeIn fourth" value="SIGN IN" data-widget="fullscreen">
            <div class='loginError'>
              <ngb-alert #selfClosingAlert *ngIf="successMessage" type="danger" (closed)="successMessage = ''">{{ successMessage }}
              </ngb-alert>
              <!-- <p>
                <button class="btn btn-primary" (click)="changeSuccessMessage()">Change message</button>
              </p> -->
            </div>
            <mat-progress-bar mode="buffer" *ngIf="progressBar"></mat-progress-bar>
          </form>
      
          <!-- Remind Passowrd -->
          <div id="formFooter" (click)='togglePwdResetBlock()'>
            <a class="underlineHover">Forgot Password/Username?</a>
          </div>
      
        </div>
      </div>
      <div class="wrapper fadeInLeft resetPwd" [hidden] = "isDisplayPwdResetBlock">
          <div id="formContent">
            <!-- Tabs Titles -->
            <div class="fadeIn first closeButton" (click)="togglePwdResetBlock()">
                <span>X</span>
            </div>            
            <!-- Icon -->
            <div class="fadeIn first login-logo">
              <img src="../../assets/reset-password/resetPass.png" id="icon" alt="User Icon" />
            </div>
            <div class="fadeIn first">
                <span>Reset Password here !</span>
            </div>  
            <!-- Login Form -->
            <form autoComplete="off" (submit)="pwdResetSubmit()">
              <input type="number" id="empID" class="fadeIn second" name="login" placeholder="Employe ID">
              <input type="submit" class="fadeIn fourth" value="RESET">
            </form>  
          </div>
      </div>
</div>
