<ag-grid-angular 
            #pendingUserList
            [animateRows]='true'
            [pagination]='true'
            [paginationPageSize]="paginationPageSize"
            [defaultColDef]="defaultColDef"
            style="width: 500px; height: 364px;"
            class="ag-theme-balham"
            [rowData]="pendingUsers" 
            [columnDefs]="columnDefs"
            [rowSelection]="'multiple'"
            [editType]="'fullRow'"
            (gridReady)="onGridReady($event)"
           >
</ag-grid-angular>
<button mat-stroked-button color="accent" (click)="GetSelectedRow()">Selected Rows</button>
