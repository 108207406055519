<section>
    <h2 mat-dialog-title style="text-align: center;" class="editOrderHeading">LOGIN HISTORY:{{historyLoginID}}</h2>
    <ul>
        <li class="header">
            <span class="action">Action</span>
            <span class="updatedOn">Action By</span>
            <span class="updatedOn">Login</span>
            <span class="updatedOn">Logout</span>
        </li>
        <li *ngFor="let item of loginHistoryDetails">
            <span class="action">{{item.action}}</span>
            <span class="updatedOn">{{item.name}}</span>
            <span class="updatedOn">{{item.lastLogin}}</span>
            <span class="updatedOn">{{item.logoutTime}}</span>
        </li>
    </ul>

    <style>
      
        

        ul{
            margin: 0;
            padding: 0;
        }
        .header span{
            font-weight: 900;
            border-bottom: 2px solid gray;
            text-transform: uppercase;
        }
        section{
            text-align: center;
        }
        li{
            border: 1px solid #ff4b8833;
            display: flex;
            background: #343a401a;
            color: black;
            font-family: monospace;
            font-size: 11px;
        }
        li span{
            
            display: block;
        }
        li span.action{
            text-transform: uppercase;
            width: 25%;
        }
        li span.updatedOn{
            width: 25%;
        }
        li span.auditString{
            width: 80%;
            display: inline-block;
          
        } 

    </style>
</section>