import { Action } from '@ngrx/store';

export enum LeastOrderConfirmationAgentActionTypes {
  LoadLeastOrderConfirmationAgents = '[LeastOrderConfirmationAgent] Load LeastOrderConfirmationAgents',
  LoadLeastOrderConfirmationAgentsSuccess = '[LeastOrderConfirmationAgent] Load LeastOrderConfirmationAgents Success',
  LoadLeastOrderConfirmationAgentsFailure = '[LeastOrderConfirmationAgent] Load LeastOrderConfirmationAgents Failure',
}

export class LoadLeastOrderConfirmationAgents implements Action {
  readonly type = LeastOrderConfirmationAgentActionTypes.LoadLeastOrderConfirmationAgents;
}

export class LoadLeastOrderConfirmationAgentsSuccess implements Action {
  readonly type = LeastOrderConfirmationAgentActionTypes.LoadLeastOrderConfirmationAgentsSuccess;
  constructor(public payload: { data: any }) { }
}

export class LoadLeastOrderConfirmationAgentsFailure implements Action {
  readonly type = LeastOrderConfirmationAgentActionTypes.LoadLeastOrderConfirmationAgentsFailure;
  constructor(public payload: { error: string }) { }
}

export type LeastOrderConfirmationAgentActions = LoadLeastOrderConfirmationAgents | LoadLeastOrderConfirmationAgentsSuccess | LoadLeastOrderConfirmationAgentsFailure;

