import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { baseUrl } from 'src/environments/environment';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loginStatus =JSON.parse(localStorage.getItem('loggedIn') || "false");
  constructor(private _http:HttpClient,private _loginService:LoginService,private _router:Router) { }
  
  setLoggedIn(value:boolean,user:any){
    this.loginStatus=value;
    localStorage.setItem('loggedIn','true');
    localStorage.setItem('user',JSON.stringify(user));
  }

  get whatIsLoginStatus(){
    return this.loginStatus;
  }

  setLoginStatus(userID:any){
    const params={params:{userID:userID}};
    return this._http.get(baseUrl+'/setLoginStatus.php',params);
  }
  setLogout(userID:any){
    const params={params:{userID:userID}};
    return this._http.get(baseUrl+'/logout.php',params);

  }
}
