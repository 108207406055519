import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './user.reducer';

const getUserFeatureState = createFeatureSelector<State>('userState');

export const getUsers = createSelector(
    getUserFeatureState,
    state => state.users
)

export const getError = createSelector(
    getUserFeatureState,
    state => state.error
)
export const createUser= createSelector(
    getUserFeatureState,
    state=>state.users
)
export const getAgentUsers=createSelector(
    getUserFeatureState,
    state=>state.users.filter(function(user,index){return (user.categoryID==105)})
)
export const getConfirmationUsers=createSelector(
    getUserFeatureState,
    state=>state.users.filter(function(user,index){return (user.categoryID==108)})
)

