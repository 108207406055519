import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { baseUrl } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { updateReturn } from 'typescript';
import { Report } from './report';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private _refreshNeeded$= new Subject<void>();

  constructor( private _http:HttpClient) { }
  handleError(errorResponse: HttpErrorResponse) {
    const error= errorResponse.error;
    const keys=Object.keys(error);
    const key = keys[0];
    let message = error[key];
    console.log(keys);
    if(errorResponse.status == 0){
      // auth token delete
      //redirect login page
      message="no Server"
    }
    if(error[key] instanceof Array){
      message = error[key][0];
    }
    if(key === "isTrusted"){
      Swal.fire({
        position: 'bottom-end',
        icon: 'error',
        title:'Server not started!',
        showConfirmButton: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      })
    }else{
      message = key+ ':'+message;
    }
    return throwError({messages:message,error})
  }
  fetchReport():Observable<Report[]>{
    return this._http.get<Report[]>(baseUrl+'/getReport.php?ty').pipe(catchError(this.handleError.bind(this)));
  }
  fetchSingleYearReport(year:any){
    const params={params:{year:year}}
    return this._http.get<Report[]>(baseUrl+'/getSingleYearReport.php',params).pipe(catchError(this.handleError.bind(this)))
  }
  fetchSingleMonthReport(month:any){
    const params={params:{month:month}}
    return this._http.get<Report[]>(baseUrl+'/getSingleMonthReport.php',params).pipe(catchError(this.handleError.bind(this)))
  }
  fetchSingleUserReport(user:any,category:any,department:any){
    const params={params:{user:user,category:category,department:department}}
    return this._http.get<Report[]>(baseUrl+'/getSingleUserReport.php',params).pipe(catchError(this.handleError.bind(this)))
  }
  fetchSingleCourseReport(month:any){
    const params={params:{month:month}}
    return this._http.get<Report[]>(baseUrl+'/getSingleCourseReport.php',params).pipe(catchError(this.handleError.bind(this)))
  }
  fetchSingleProductReport(month:any){
    const params={params:{month:month}}
    return this._http.get<Report[]>(baseUrl+'/getSingleProductReport.php',params).pipe(catchError(this.handleError.bind(this)))
  }

}
