import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatSelectionListChange } from '@angular/material/list';


@Component({
  selector: 'app-order-status-action-button',
  templateUrl: './order-status-action-button.component.html',
  styleUrls: ['./order-status-action-button.component.css']
})
export class OrderStatusActionButtonComponent implements OnInit {
  // techForm = this._formBuilder.group({
  //   statusAssigned: new FormControl('',[Validators.required]) 
  // });
  statusDetails: FormGroup;  
  constructor(private _formBuilder: FormBuilder) {
    this.statusDetails=new FormGroup({
      statusAssigned:new FormControl('',[Validators.required])      
    })
  }
  onListSelectionChange(ob: MatSelectionListChange) {
    console.log("Selected Item: " + ob.source.selectedOptions.selected.length);
  }
  color: ThemePalette = 'primary';
  agInit(params:any){
    // this.paramsData=params.data;
    // this.completeParamView=params;
  }
  ngOnInit(): void {
  }

}
