import { Action } from '@ngrx/store';
import { Domain } from './domain';

export enum DomainActionTypes {
  LoadDomains = '[Domain] Load Domains',
  LoadDomainsSuccess = '[Domain] Load Domains Success',
  LoadDomainsFailure = '[Domain] Load Domains Failure',
}

export class LoadDomains implements Action {
  readonly type = DomainActionTypes.LoadDomains;
}

export class LoadDomainsSuccess implements Action {
  readonly type = DomainActionTypes.LoadDomainsSuccess;
  constructor(public payload: { data: Domain[] }) { }
}

export class LoadDomainsFailure implements Action {
  readonly type = DomainActionTypes.LoadDomainsFailure;
  constructor(public payload: { error: string }) { }
}

export type DomainActions = LoadDomains | LoadDomainsSuccess | LoadDomainsFailure;

