import { createFeatureSelector, createSelector } from '@ngrx/store';
import {State } from "./domain.reducer";

const getDomainFeatureState=createFeatureSelector<State>('Domain');

export const getDomain = createSelector(
    getDomainFeatureState,
    state => state.domain
)

export const getError = createSelector(
    getDomainFeatureState,
    state => state.error
)
export const createDomain= createSelector(
    getDomainFeatureState,
    state=>state.domain
)


