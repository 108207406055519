import { animate, query, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit,ElementRef, ViewChild, AfterViewInit, NgModule, Inject } from '@angular/core';
import { MatFormFieldModule,matFormFieldAnimations,MatFormFieldControl,MatLabel,MatError,MAT_ERROR,MatFormField } from "@angular/material/form-field";
import { AgGridAngular, AngularFrameworkComponentWrapper, } from 'ag-grid-angular';
import { LodingBarServiceService } from "../loading-bar/loding-bar-service.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { AgGridAngular } from "ag-grid-angular";
import { UserService } from '../users/user.service';
import { orders } from './orders';
import { TooltipComponent } from "../tooltip/tooltip.component";
import { Store,select, StoreConfig } from "@ngrx/store";
import * as OrderAction from './order.actions';
import * as fromOrder from "./order.selectors"
import * as OrderActionbutton from '../order-action-button/order-action-button.actions';
import * as fromOrderActionButton from "../order-action-button/order-action-button.selectors"
import { FormControl, FormGroup, FormGroupDirective, ValidationErrors, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import * as productAction from "../product/product.actions";
import * as fromProduct from "../product/product.selectors"
import * as courseAction from "../course/course.actions";
import * as fromCourse from "../course/course.selectors"
import * as domainAction from '../domain/domain.actions';
import * as fromDoamin from '../domain/domain.selectors'
import * as userAction from "../../user.actions";
import * as fromUser from "../../user.selectors"
import * as fromShift from "../shift/shift.selectors";
import * as shiftAction from "../shift/shift.actions";
import { product } from '../product/product';
import { Course } from '../course/course';
import { OrderService } from './order.service';
import { threadId } from 'node:worker_threads';
import { OrderActionButtonComponent } from '../order-action-button/order-action-button.component';
import { getLocaleWeekEndRange, Time } from '@angular/common';
import { SettingService } from "../setting/setting.service";
import { OrderStatus } from '../setting/orderStatus';
import { CurrentWeek } from "../../../environments/environment";
import { UtilService } from "../../utilities/util.service";
import Swal from 'sweetalert2';
import { Domain } from '../domain/domain';
import { GridApi, QuerySelector } from 'ag-grid-community';
import { Key } from 'protractor';
import { IUser } from 'src/app/user';
import { UserActionTypes } from 'src/app/user.actions';
import { Orders } from 'src/app/models/orders.model';
import { CourierService } from '../courier/courier.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { ShippingDetailsComponent } from '../shipping-details/shipping-details.component';
import { LeastOrderConfirmationAgentService } from '../least-order-confirmation-agent/least-order-confirmation-agent.service';
import { CourseService } from '../course/course.service';
import { ProductService } from '../product/product.service';
import { HeaderComponent } from '../header/header.component';
import { MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';



export interface AgentListForFilter{
  id:number,
  username:string,
  fname:string,
  lname:string
}
export interface parameter {
  name:string,
  value:any
}


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css'],
  animations: [
    trigger('widthGrow', [
        state('closed', style({
          height:  '0px',
          opacity: 0          
        })),
        state('open', style({
          height: '80px',
          opacity: 1
        })),
        transition('* => *', animate(250))
    ]),
  ]
})

export class OrdersComponent implements OnInit {
  pageTtitle:string='Orders';
  sideBar:boolean = false;
  rowData!:orders[];
  OrderStatus!:OrderStatus[];
  AgentList!:AgentListForFilter[];
  DomainList!:Domain[]
  paginationPageSize:number=19;
  createOrderDialogTitle:string="";
  public isCreateOrderDialogOpned:boolean=false;
  public isAssignOrderDialogOpned:boolean=false;
  public isTransferOrderDialogOpned:boolean=false;
  public isShipLogsOrderDialogOpned:boolean=false;
  public waitingImage:boolean=false;  
  time!:any
  filterRowShow: boolean=false; 
  productList!:product[];
  CourseList!:Course[];
  state = "closed";
  p!:number;
  public rowSelection = 'multiple';
  public selectedRows!:orders[]
  selectedRowsCount:number=0
  eventApi!:GridApi
  courierList:any=[];
  present: boolean=false;
  //transferToggle:boolean=false;
  transferToggle:boolean=true;

  constructor(private _ElementRef:ElementRef,private lbs:LodingBarServiceService,private _userService:UserService,private store:Store,
    private dialog:MatDialog,private _orderService:OrderService,private _settingService:SettingService,
    private _courierService:CourierService,private _utils:UtilService,private _courseService:CourseService,private _productService:ProductService) { 
    // this.store.dispatch(new productAction.LoadProducts);
    // this.store.pipe(select(fromProduct.getProduct)).subscribe(productsPayload=>this.productList=productsPayload);
    // this.store.dispatch(new courseAction.LoadCourses);
    // this.store.pipe(select(fromCourse.getCourse)).subscribe(coursePayload=>this.CourseList=coursePayload);
    this.store.dispatch(new domainAction.LoadDomains);
    this.store.pipe(select(fromDoamin.getDomain)).subscribe(domainPayload=>this.DomainList=domainPayload)
  }

  @ViewChild('OrderList',{read:ElementRef}) 
  OrderList!:ElementRef
  
  ngOnInit(): void {
    this.lbs.showBar(true);
    this.alluser()
    this._courierService.fetchCourier().subscribe(payload=>this.courierList=payload);
    this._courseService.fetchCourse().subscribe(payload=>this.CourseList=payload);
    this._productService.fetchProducts().subscribe(payload=>this.productList=payload);
    this._settingService.fetchOrderStatus().subscribe(payload=>this.OrderStatus= payload);
    this._orderService.fetchAgentsForFilters().subscribe((payload:any)=>this.AgentList=payload);
    this._orderService.getListOfCourier().subscribe((payload:any)=>this.courierList=payload.courier_data);
  }
  clear(){
    this.lbs.clear();
  }
  ngAfterViewInit(){
    this.lbs.clear()
  }
  defaultColDef = {
    editable:true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:100,
    sideBar:true,
    columnHoverHighlight: true
  };

  filterParams={
    comparator:(filterLocalDateAtMidnight:any,cellValue:any)=>{
      //console.log(filterLocalDateAtMidnight,cellValue);
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split('-');
      console.log(dateParts,"HJ");
      var cellDate = new Date(
        Number(dateParts[0]),
        Number(dateParts[1])-1, 
        Number(dateParts[2])        
      )
      //console.log(cellDate,"DF",Number(dateParts[1])-1,Number(dateParts[2]),Number(dateParts[0]), new Date(2021,6,15));
  
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
  
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
  
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0
    },
    browserDatePicker: true,
  } 
  columnDefs = [
    {headerName: 'Action',cellRendererFramework:OrderActionButtonComponent,checkboxselection:true,headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true, resizable: true,minWidth:150},
    {headerName: 'Re-order', field: 're_order',hide:false,cellRenderer:(value:any)=>{if(value.value == 1){
     return "RE-ORDER" 
    }else{
      return "NEW"
    }}},
    {headerName: 'Id', field: 'id',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Time', field: 'orderTime',hide:false,cellRenderer:(date:any)=>{
      //console.log(date.value.split(':'));
      const dateArray=date.value.split(':');
      var hours= dateArray[0]<=12?dateArray[0]:dateArray[0]-12;
      hours=hours<10?'0'+hours:hours;
      var minutes= dateArray[1];
      minutes=minutes<10?'0'+minutes:minutes;
      var seconds= dateArray[2];
      var ampm = dateArray[0]>=12?'PM':'AM'
      return hours+':'+minutes+'&nbsp;'+ampm;
    }},
    {headerName: 'Date', field: 'orderDate',cellRendererFramework:TooltipComponent,hide:false,filter: 'agDateColumnFilter',filterParams:this.filterParams},
    {headerName: 'Agent', field: 'agentName',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Teamlead', field: 'teamlead',cellRendererFramework:TooltipComponent,hide:false},
    //{headerName: 'Super Agent', field: 'superAgent',cellRendererFramework:TooltipComponent,hide:false},
    // {headerName: 'confirmedBy', field: 'confirmedBy',cellRendererFramework:TooltipComponent,hide:false},
    // {headerName: 'Product', field: 'ProductName',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Status', field: 'ostatus',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Category', field: 'orderCategory',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Courier Status', field: 'courierStatus',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Courier Date', field: 'courierUpdateDate',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Shipping Details', cellRendererFramework:ShippingDetailsComponent,hide:false,minWidth:150},
    // {headerName: 'Shift', field: 'orderShift',cellRendererFramework:TooltipComponent,hide:false},
    // {headerName: 'Mobile', field: 'customerMobile',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Name', field: 'customerName',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Course', field: 'CourseName',cellRendererFramework:TooltipComponent,hide:false},
    // {headerName: 'B.Price', field: 'BasePrice',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'C.Price', field: 'confirmedPrice',cellRendererFramework:TooltipComponent,hide:false}
  ]
  public rowHeight = 70;

  getPage(page:number){
    //this._orderService.getOrdersP(page).subscribe(payload=>console.log(payload));
  }
  public alluser(){
    this.lbs.showBar(true);
    this.store.dispatch(new OrderAction.LoadOrders()) //Action dispatch
    this.store.pipe(select(fromOrder.getOrders)).subscribe(
      orders =>{
        this.rowData=orders;
        this.lbs.clear()
      }
    )    
  } 
  NotPickOrder(event:any){
    //this.transferToggle=true;
    this._orderService.fetchNotPickOrders().subscribe(payload=>this.rowData=payload)
  }
  HoldOrder(event:any){
    //this.transferToggle=true
    this._orderService.fetchHoldOrders().subscribe(payload=>this.rowData=payload)
  }
  checkTestPhp(){
    this._orderService.checkPhpTest().subscribe((payload)=>{
      console.log(payload,"fg");
      this.time=payload;
    })
  }
  EditOrder(){
    console.log("erere");
  }
  CreateOrder(){
    //this.transferToggle=false
    if(!this.isCreateOrderDialogOpned){
      const dialogRef = this.dialog.open(CreateOrderDialog,{
        width: '1000px',
        disableClose:true
      });   
      this.isCreateOrderDialogOpned = !this.isCreateOrderDialogOpned;
      dialogRef.afterClosed().subscribe(result => {
        this.isCreateOrderDialogOpned = !this.isCreateOrderDialogOpned;
        console.log(`Dialog result: ${result}`);
      });
    }
  }
  SearchedOrders(event:any){
    
  }
  TransferOrders(event:any){
    if(!this.isTransferOrderDialogOpned && this.selectedRowsCount){
      const dialogRef = this.dialog.open(TransferOrderDialog,{
        width: '1000px',
        disableClose:true,
        data:this.selectedRows
      });   
      this.isTransferOrderDialogOpned = !this.isTransferOrderDialogOpned;
      dialogRef.afterClosed().subscribe(result => {
        this.isTransferOrderDialogOpned = !this.isTransferOrderDialogOpned;
        console.log(`Dialog result: ${result}`);
      });
    }else{
      Swal.fire({
        title: 'No Rows Selected',
        text: 'Please select some rows!',
        icon:'info',
        width: 600,
        padding: '3em',
        background: '#fff',
        customClass: {
          container:'swalbackgroundMargin',
          // popup:'popupcustom'
        },
        focusConfirm: false,
        confirmButtonText:'<i class="fa fa-exclamation-circle" aria-hidden="true"></i> Cancel',
      })
    }
  }

  assignOrders(){
    //this.transferToggle=false
    if(!this.isAssignOrderDialogOpned && this.selectedRowsCount){
      const dialogRef = this.dialog.open(AssignOrderDialog,{
        width: '1000px',
        disableClose:true,
        data:this.selectedRows
      });   
      this.isAssignOrderDialogOpned = !this.isAssignOrderDialogOpned;
      dialogRef.afterClosed().subscribe(result => {
        this.isAssignOrderDialogOpned = !this.isAssignOrderDialogOpned;
        console.log(`Dialog result: ${result}`);
      });
    }else{
      Swal.fire({
        title: 'No Rows Selected',
        text: 'Please select some rows!',
        icon:'info',
        width: 600,
        padding: '3em',
        background: '#fff',
        customClass: {
          container:'swalbackgroundMargin',
          // popup:'popupcustom'
        },
        focusConfirm: false,
        confirmButtonText:'<i class="fa fa-exclamation-circle" aria-hidden="true"></i> Cancel',
      })
    }
  }
  @ViewChild('popupInterstitial',{read:ElementRef}) 
  popupInterstitial!:ElementRef
  popupInterstitialTest:boolean=false
  successOrders:any=[];
  shipSelectedOrders(event:any){   
    //this.transferToggle=false
    this.lbs.showBar(true);    
    if(this.selectedRowsCount){
      this.popupInterstitialTest=true
      this._utils.validateToken().subscribe(TokenPayload=>{
        if(TokenPayload){
          this._courierService.fetchCourier().subscribe(payload=>{
            if(payload){
              if(payload.filter(courier=>(courier.priority==0))[0].name=='Shiplyte'){console.log("Shiplyte Api");this.lbs.clear()}              
              if(payload.filter(courier=>(courier.priority==0))[0].name=='Picker'){console.log("Picker Api");this.lbs.clear()}              
              if(payload.filter(courier=>(courier.priority==0))[0].name=='Shiprocket'){
                this._orderService.shipOrder(this.selectedRows,this.selectedRows.length,TokenPayload/*passing TokenPayload as token*/).subscribe((CourierPayload:any)=>{                
                  this.successOrders=CourierPayload[0].log.filter((order:any)=>(order.status==1));
                  console.log(this.successOrders,"fgfd");
                  if(CourierPayload[0].status){
                    this.popupInterstitialTest=false
                    Swal.fire({
                      title: 'SHIPPING MESSAGE UPDATE',
                      text: 'SHIPPED ORDERS='+CourierPayload[0].successCount+" ,FAILED="+CourierPayload[0].failedCount,
                      icon:'success',
                      position: 'top-end',
                      width: 600,
                      padding: '3em',
                      background: '#fff',
                      customClass: {
                        container:'swalbackgroundMargin',
                        // popup:'popupcustom'
                        content:'contentClass'
                      },
                      focusConfirm: true,
                      confirmButtonText:'<i class="fa fa-thumbs-up"></i> OK',
                    }).then ((result)=>{     
                      console.log('1')
                      this._orderService.updateOrderCourierStatus(this.successOrders).subscribe((payload1:any)=>{                        
                        console.log('1.1',payload1);
                        this.deselectRows()
                        this.refresh();
                        this.lbs.clear();
                      })
                      console.log('1.2')
                      this.deselectRows()
                      //this.refresh();
                    })                
                    console.log('1.3')      
                    this.lbs.clear();
                  }
                })
              }
              if(payload.filter(courier=>(courier.priority==0))[0].name=='Bluedart'){console.log("Bluedart Api");this.lbs.clear()}              
              if(payload.filter(courier=>(courier.priority==0))[0].name=='Ecom'){console.log("Ecom Api");this.lbs.clear()}              
            }else{
              console.log('something went wrong on courier');this.lbs.clear()
            }
          })
        }
      }) 
    }else{
      Swal.fire({
        title: 'NO ORDER SELECTED',
        text: 'Please select minimum 1 order',
        icon:'error',
        width: 600,
        padding: '3em',
        background: '#fff',
        customClass: {
          container:'swalbackgroundMargin',
          // popup:'popupcustom'
        },
        focusConfirm: false,
        confirmButtonText:'<i class="fa fa-thumbs-down"></i> Cancel',
      });
      this.lbs.clear();
    }  
  }  
  LoadOrders(){
    
  }
  LoadOrdersAWB(){
    if(this.selectedRowsCount){
      this.popupInterstitialTest=true
      this._utils.validateToken().subscribe((TokenPayload:any)=>{
        if(TokenPayload){
          this._orderService.fetchShippedOrderStatus(this.selectedRows,this.selectedRowsCount,TokenPayload).subscribe((payload)=>{
            this._orderService.UpdateShipedOrderAWB(payload).subscribe(payload=>{
              Swal.fire({
                title: 'COURIER STATUS UPDATE MESSAGE',
                text: 'Courier Status Updated Successfully',
                icon:'success',
                position: 'top-end',
                width: 600,
                padding: '3em',
                background: '#fff',
                customClass: {
                  container:'swalbackgroundMargin',
                  // popup:'popupcustom'
                  content:'contentClass'
                },
                focusConfirm: true,
                confirmButtonText:'<i class="fa fa-thumbs-up"></i> OK',
              }).then ((result)=>{ 
                this.alluser();                
              })
              this.popupInterstitialTest=false
            });
          });
        }
      }) 
    }   
  }
  onResize(event:any){
    
  }  
  onRowSelected(event:any){
    this.selectedRows=event.api.getSelectedRows();
    console.log("Selected Rows=",this.selectedRows)
    this.selectedRowsCount=event.api.getSelectedRows().length;
    this.eventApi=event.api
  }
  deselectRows(){
    this.eventApi.deselectAll();
  }
  clearFilter(event:any){  
        this.selectedRowsCount=0;
        this._ElementRef.nativeElement.querySelector('#domain').value="";
        this._ElementRef.nativeElement.querySelector('#status').value="";
        this._ElementRef.nativeElement.querySelector('#agent').value="";
        this._ElementRef.nativeElement.querySelector('#product').value="";
        this._ElementRef.nativeElement.querySelector('#course2').value="";
        this._ElementRef.nativeElement.querySelector('#category').value="";
        this._ElementRef.nativeElement.querySelector('#startDate').value="";
        this._ElementRef.nativeElement.querySelector('#endDate').value="";
        this.refresh();  
        this.arg=[];
  }
  refresh(){
    //this.transferToggle=false
    this.alluser()
  }
  showFilter(){
    (this.state == "closed") ? this.state = "open" : this.state = "closed";
    //this.DomainSectionSwitch=!this.DomainSectionSwitch    
    this.filterRowShow=true;

    // if(this.filterRowShow){
    //   this._ElementRef.nativeElement.querySelector("#OrderList").classList.remove('filterExpand');
    // }else{     
    //     this._ElementRef.nativeElement.querySelector("#OrderList").classList.add('filterExpand');
    //   }
    // this.filterRowShow=!this.filterRowShow;
  }
  @ViewChild('OrderList')
  agGrid!:AgGridAngular

  @ViewChild('filterTextBox',{read:ElementRef})
  filterBox!:ElementRef

  onFilterTextBoxChanged(){
    //console.log("df",this.filterBox.nativeElement.value);
    this.agGrid.api.setQuickFilter(this.filterBox.nativeElement.value)
  }
  fetchDateRangeOrder(){
    this._orderService.getOrdersP(this.arg).subscribe((payload:any)=>{this.rowData=payload;this.lbs.clear()}) 
  }
  arg:parameter[]=[]
  

  //obj:parameter[] = []; 
  // blankURL:string="SELECT *, o.id,p.name as ProductName,os.name as ostatus,c.name as CourseName from orders o JOIN product p ON (o.productID=p.id) JOIN courses c ON (o.courseID=c.id) JOIN orderstatus os ON (o.orderStatus=os.id)";
  // url:string="SELECT *, o.id,p.name as ProductName,os.name as ostatus,c.name as CourseName from orders o JOIN product p ON (o.productID=p.id) JOIN courses c ON (o.courseID=c.id) JOIN orderstatus os ON (o.orderStatus=os.id) WHERE ";
  fetchOrderAsPerStatus(event:Event){
    this.selectedRowsCount=0;
    var name=(event.target as HTMLInputElement).name;
    var value=(event.target as HTMLInputElement).value;
    if(!this.arg.length){
      this.arg.push({name:name,value:value});
    }else{
      console.log("filter exist=",this.arg.filter((el,index)=> el.name == name).length)
      if(!this.arg.filter((el,index)=> el.name == name).length){
        this.arg.push({name:name,value:value});
      }else{        
        this.arg.filter((el,index)=>{
          console.log("value=",value,el.name,name);
          if(el.name==name){
            if(value !="")
              el.value=value;
            else
              this.arg= this.arg.filter((el1,index1) => el1.name != name);
          }
        })
      }
    }
    console.log("arg=",this.arg);
    if(!(name == "startDate" || name=='endDate')){
      console.log('fg');
      //this._orderService.getOrdersP(this.arg).subscribe((payload:any)=>{this.rowData=payload;this.lbs.clear()}) 
      this.store.dispatch(new OrderActionbutton.loadFilterOrders({arg:this.arg})) //Action dispatch
      this.store.pipe(select(fromOrderActionButton.getOrders)).subscribe(
        orders =>{
          console.log("ttt",orders);
          this.rowData=orders;
          this.lbs.clear()
        }
      )    
    }    
    
    //this.obj[name]=value;
   
    // var domain=this._ElementRef.nativeElement.querySelector('#domain').value;
    // var status=this._ElementRef.nativeElement.querySelector('#status').value;
    // var agent=this._ElementRef.nativeElement.querySelector('#agent').value;
    // var product=this._ElementRef.nativeElement.querySelector('#product').value;
    // var course=this._ElementRef.nativeElement.querySelector('#course').value;
    // var category=this._ElementRef.nativeElement.querySelector('#category').value;
    // if(domain!=""){
    //   if(tempURL=="")
    //     tempURL='o.domain='+domain;
    //   else{
    //     tempURL=tempURL+' AND o.domain='+domain;
    //   }
    // }
    // if(category!=""){
    //   if(tempURL=="")
    //     tempURL="o.orderCategory='"+category+"'"
    //   else
    //     tempURL=tempURL+" AND o.orderCategory='"+category+"'"
    // }
    // if(status!=""){
    //   if(tempURL=="")
    //     tempURL='o.orderStatus='+status
    //   else
    //     tempURL=tempURL+' AND o.orderStatus='+status
    // }
    // if(agent!=""){
    //   if(tempURL=="")
    //     tempURL='o.agent='+agent;
    //   else
    //     tempURL=tempURL+' AND o.agent='+agent;
    // }
    // if(product!=""){
    //   if(tempURL=="")
    //     tempURL='o.productID='+product;
    //   else
    //     tempURL=tempURL+' AND o.productID='+product
    // }
    // if(course!=""){
    //   if(tempURL=="")
    //     tempURL='o.courseID='+course;
    //   else
    //     tempURL=tempURL+' AND o.courseID='+course
    // }    
    // console.log("temp=",tempURL,"url=",this.url);     
    // if(domain =="" && status=="" && agent =="" && product=="" && course=="" && category == ""){
    //   this.lbs.showBar(true);
    //   this._orderService.getOrdersP(this.blankURL).subscribe(payload=>{this.rowData=payload; this.lbs.clear()})    
    // }else{
    //   this.lbs.showBar(true);
    //   this._orderService.getOrdersP(this.url+tempURL).subscribe(payload=>{this.rowData=payload;this.lbs.clear()})    
    // }
    // var orderStatusID=(event.target as HTMLInputElement).value;
    // var orderStatustext=(event.target as HTMLSelectElement).selectedOptions[0].innerText;
    // console.log(orderStatusID,orderStatustext,this._ElementRef.nativeElement.querySelector('#domain').value);
    //this._orderService.getOrdersP(this.obj).subscribe((payload:any)=>console.log(payload))
    // if(name == "status" && this.url.split('WHERE')[1].indexOf('o.orderStatus') == -1){
    //   console.log('1');
    //   if(this.url.split('WHERE')[1].indexOf('&') > -1){
    //     console.log('1.1');
    //     this.url=this.url+'o.orderStatus='+value+'&';
    //   }else{
    //     console.log('1.2');
    //     this.url=this.url+' o.orderStatus='+value+'&';
    //   }        
    // }else if(name == "status" && this.url.split('WHERE')[1].indexOf('o.orderStatus') != -1){
    //   console.log('1.1.1');
    //   if(this.url.split('WHERE')[1].indexOf('o.orderStatus') > -1){
    //     //console.log('1.1.2',this.url.split('WHERE')[1].replace(this.url.split('WHERE')[1].substring(0,this.url.split('WHERE')[1].indexOf('&')),'o.orderStatus='+value));
    //     //console.log(this.url.split('WHERE')[1].substring(0,this.url.split('WHERE')[1].indexOf('&')));
    //     //this.url=this.url.split('WHERE')[0]+'WHERE'+this.url.split('WHERE')[1].substring(0,this.url.split('WHERE')[1].indexOf('='))+'='+value+'&';
    //     this.url=this.url.split('WHERE')[0]+'WHERE '+this.url.split('WHERE')[1].replace(this.url.split('WHERE')[1].substring(0,this.url.split('WHERE')[1].indexOf('&')),'o.orderStatus='+value);
    //   }else{
    //     console.log('1.1.3');
    //     this.url=this.url+' o.orderStatus='+value+'&';
    //   }
    // }
    // if(name == "domain" && this.url.split('WHERE')[1].indexOf('o.domain') == -1){
    //   console.log('1');
    //   if(this.url.split('WHERE')[1].indexOf('&') > -1){
    //     console.log('1.1');
    //     this.url=this.url+'o.domain='+value+'&';
    //   }else{
    //     console.log('1.2');
    //     this.url=this.url+' o.domain='+value+'&';
    //   }        
    // }else if(name == "domain" && this.url.split('WHERE')[1].indexOf('o.domain') != -1){
    //   console.log('1.1.1');
    //   if(this.url.split('WHERE')[1].indexOf('o.domain') > -1){
    //     //console.log('1.1.2',this.url.split('WHERE')[1]);
    //     //console.log(this.url.split('WHERE')[1].substring(0,this.url.split('WHERE')[1].indexOf('=')));
    //     //this.url=this.url.split('WHERE')[0]+'WHERE'+this.url.split('WHERE')[1].substring(0,this.url.split('WHERE')[1].indexOf('='))+'='+value+'&';
    //     this.url=this.url.split('WHERE')[0]+'WHERE '+this.url.split('WHERE')[1].replace(this.url.split('WHERE')[1].substring(0,this.url.split('WHERE')[1].indexOf('&')),'o.domain='+value);
    //   }else{
    //     console.log('1.1.3');
    //     this.url=this.url+' o.domain='+value+'&';
    //   }
    // }
    // if(name == "domain" && this.url.indexOf('o.domain') == -1){
    //   if(this.url.indexOf('&') > -1){
    //     this.url=this.url+'o.domain='+value;
    //   }else{
    //     this.url=this.url+' o.domain='+value;
    //   }        
    // }
    // if(name == "agent" && this.url.indexOf('o.AG_ID') == -1){
    //   if(this.url.indexOf('&') > -1){
    //     this.url=this.url+'&o.AG_ID='+value;
    //   }else{
    //     this.url=this.url+' o.AG_ID='+value;
    //   }        
    // }    
    //console.log(this.url+tempURL);

  }
  
}

@Component({
  selector: 'QuickViewSearchOrder',
  templateUrl: 'quickViewSearchOrders.html',
  //styleUrls: ['../header/header.component.css']
})

export class QuickViewSearchOrder1 {
  orderSearchData!:Orders[];
  paginationPageSize:number=3;
  pagination:boolean=true;
  sideBar:boolean=false
  public rowSelection = 'multiple';
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,private _orderActionButtonCom:OrderActionButtonComponent,private _ElementRef:ElementRef, private _orderService:OrderService,private lbs:LodingBarServiceService){
    // console.log(this._orderActionButtonCom.reorderFlag,"before");
    // this._orderActionButtonCom.reorderFlag=true;
    // console.log(this._orderActionButtonCom.reorderFlag,"After");
  }  
  defaultColDef = {
    editable:true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:100,
    sideBar:true,
    columnHoverHighlight: true
  };
  onResize(e:any){}
  onRowSelected(e:any){}
  filterParams={
    comparator:(filterLocalDateAtMidnight:any,cellValue:any)=>{
      //console.log(filterLocalDateAtMidnight,cellValue);
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split('-');
      console.log(dateParts,"HJ");
      var cellDate = new Date(
        Number(dateParts[0]),
        Number(dateParts[1])-1, 
        Number(dateParts[2])        
      )
      //console.log(cellDate,"DF",Number(dateParts[1])-1,Number(dateParts[2]),Number(dateParts[0]), new Date(2021,6,15));
  
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
  
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
  
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0
    },
    browserDatePicker: true,
  } 
  columnDefs = [
    {headerName: 'Action',cellRendererFramework:OrderActionButtonComponent,checkboxselection:true,headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true, resizable: true,minWidth:150},
    {headerName: 'Id', field: 'id',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Time', field: 'orderTime',hide:false,cellRenderer:(date:any)=>{
      //console.log(date.value.split(':'));
      const dateArray=date.value.split(':');
      var hours= dateArray[0]<=12?dateArray[0]:dateArray[0]-12;
      hours=hours<10?'0'+hours:hours;
      var minutes= dateArray[1];
      minutes=minutes<10?'0'+minutes:minutes;
      var seconds= dateArray[2];
      var ampm = dateArray[0]>=12?'PM':'AM'
      return hours+':'+minutes+'&nbsp;'+ampm;
    }},
    {headerName: 'Date', field: 'orderDate',cellRendererFramework:TooltipComponent,hide:false,filter: 'agDateColumnFilter',filterParams:this.filterParams},
    {headerName: 'Agent', field: 'agentName',cellRendererFramework:TooltipComponent,hide:false},
    // {headerName: 'Teamlead', field: 'teamlead',cellRendererFramework:TooltipComponent,hide:false},
    //{headerName: 'Super Agent', field: 'superAgent',cellRendererFramework:TooltipComponent,hide:false},
    // {headerName: 'confirmedBy', field: 'confirmedBy',cellRendererFramework:TooltipComponent,hide:false},
    // {headerName: 'Product', field: 'ProductName',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Status', field: 'ostatus',cellRendererFramework:TooltipComponent,hide:false},
    // {headerName: 'Category', field: 'orderCategory',cellRendererFramework:TooltipComponent,hide:false},
    // {headerName: 'Courier Status', field: 'courierStatus',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Shipping Details', cellRendererFramework:ShippingDetailsComponent,hide:false,minWidth:150},
    // {headerName: 'Shift', field: 'orderShift',cellRendererFramework:TooltipComponent,hide:false},
    // {headerName: 'Mobile', field: 'customerMobile',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Name', field: 'customerName',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'customerMobile', field: 'Mobile',cellRendererFramework:TooltipComponent,hide:false},
    // {headerName: 'B.Price', field: 'BasePrice',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'C.Price', field: 'confirmedPrice',cellRendererFramework:TooltipComponent,hide:false}
  ]
  public rowHeight = 70;
  ngOnInit(){
    this.lbs.showBar(true);
    this._orderService.searchOrders(this.data).subscribe(payload=>{
      this.orderSearchData=payload
      this.lbs.clear();
    })
  }  
}

/**
 * @title Simple autocomplete
 */



@Component({
  selector: 'create-order-dialog',
  templateUrl:'create-order-dialog.html',
  styleUrls:['orders.component.css'],
  animations:[
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [ style({ opacity: 0 }), animate(600) ]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})

export class CreateOrderDialog implements OnInit{
  public waitingImage:boolean=false; 
  createForm!:FormGroup; 
  successHeader:boolean=false;
  courierModeList=['Courier Mode 1','Courier Mode 2','Courier Mode 3','Courier Mode 4']
  productList!:product[];
  CourseList!:Course[];
  postOfficeList!:any[];
  stateList!:any[];
  cityList!:any[]
  loggedInUserParse: any;
  isQuickViewSearchOrderOpened: any;
  activeShift!:string
  previousPrice:any;
 
  constructor(private _QuickView:MatBottomSheet,private _leaseOrderConfirmationAgent:LeastOrderConfirmationAgentService,private orderCom:OrdersComponent,public dialogRef: MatDialogRef<CreateOrderDialog>,private sanitizer: DomSanitizer, private _userService:UserService,private lbs:LodingBarServiceService,private store:Store,private _orderService:OrderService,private orderComp:OrdersComponent,private pinapi:UtilService) {    
    
    this.createForm = new FormGroup({ 
      cname: new FormControl('',[Validators.required]),
      product: new FormControl('',[Validators.required]),     
      mobile : new FormControl('', [Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]),
      altmobile : new FormControl('', [Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]),
      course: new FormControl('',[Validators.required]),
      price: new FormControl('',[Validators.required,Validators.maxLength(4),Validators.pattern('^[0-9]*$')]),
      bprice: new FormControl('',[Validators.required]),
      pincode: new FormControl('',[Validators.required,Validators.maxLength(6),Validators.minLength(6),Validators.pattern('^[0-9]*$')]),
      postOffice: new FormControl('',[Validators.required]),
      state: new FormControl('',[Validators.required]),
      landmark: new FormControl('',[Validators.required]),
      city: new FormControl('',[Validators.required]),
      remark: new FormControl('',[Validators.required]),
      address: new FormControl('',[Validators.required]),
      week:new FormControl(CurrentWeek,[Validators.required]),
      TL_ID:new FormControl(''),
      AG_ID:new FormControl(''),
      domain:new FormControl('16')
    });
    const loggedInUser:any=localStorage.getItem('user');
    this.loggedInUserParse=JSON.parse(loggedInUser);
    this.store.dispatch(new shiftAction.LoadShifts)
    this.store.pipe(select(fromShift.getShift)).subscribe(payload => {
      payload.filter(el=>{
        if(el.status == true){
          this.activeShift=el.shiftName;
        }
      })
    })
  }  
  ngOnInit(): void {
    this.store.dispatch(new productAction.LoadProducts)
    this.store.pipe(select(fromProduct.getProduct)).subscribe(products=>this.productList=products);
    this.store.dispatch(new courseAction.LoadCourses)
    this.store.pipe(select(fromCourse.getCourse)).subscribe(course=>this.CourseList=course)
    //console.warn(this.CourseList);
  }
  onFocusOutEvenMobile(event:any){
    console.log(event.target.value,"patter=",this.createForm.controls['mobile'].hasError('pattern'),"min=",this.createForm.controls['mobile'].hasError('minlength'),"required=",this.createForm.controls['mobile'].hasError('required'),"Max=",this.createForm.controls['mobile'].hasError('maxlength'))
    if(!this.createForm.controls['mobile'].hasError('required') && !this.createForm.controls['mobile'].hasError('pattern') && !this.createForm.controls['mobile'].hasError('minlength')  && !this.createForm.controls['mobile'].hasError('maxlength')){     
      if(!this.isQuickViewSearchOrderOpened){
        let searchOrder=this._QuickView.open(QuickViewSearchOrder1,{data:event.target.value});
        this.isQuickViewSearchOrderOpened=!this.isQuickViewSearchOrderOpened
        searchOrder.afterDismissed().subscribe(result => {
          this.isQuickViewSearchOrderOpened=!this.isQuickViewSearchOrderOpened;
        })
      }   
    }
  }
  onFocusOutEventAltMobile(event:any){    
    if(!this.createForm.controls['altmobile'].hasError('required') && !this.createForm.controls['altmobile'].hasError('pattern') && !this.createForm.controls['altmobile'].hasError('minlength')  && !this.createForm.controls['altmobile'].hasError('maxlength')){     
      if(!this.isQuickViewSearchOrderOpened){
        let searchOrder=this._QuickView.open(QuickViewSearchOrder1,{data:event.target.value});
        this.isQuickViewSearchOrderOpened=!this.isQuickViewSearchOrderOpened
        searchOrder.afterDismissed().subscribe(result => {
          this.isQuickViewSearchOrderOpened=!this.isQuickViewSearchOrderOpened;
        })
      }   
    }
  }
  onFocusInEvenPrice(event:any){
    this.previousPrice=this.createForm.controls['price'].value;
  }
  onFocusOutEvenPrice(event:any){
    if(!this.createForm.controls['price'].hasError('required') && !this.createForm.controls['price'].hasError('pattern') && !this.createForm.controls['price'].hasError('minlength')  && !this.createForm.controls['price'].hasError('maxlength')){
      if(!(this.createForm.controls['price'].value == this.previousPrice)){
        this.createForm.controls['price'].setValue(this.createForm.controls['price'].value-290);
      }      
    }
  }
  GetPinCodeDetails(event:any){
    if(event.target.value.length=='6'){
      this.pinapi.pincode(event.target.value).subscribe((payload)=>{
        if(payload !='Error'){
          this.createForm.controls['state'].setValue(payload.state);
          this.createForm.controls['city'].setValue(payload.city);
          this.postOfficeList=payload.postOffice;
        }else{
          Swal.fire({
            title: 'OOPS...WRONG PINCODE',
            text: 'Please Enter Correct Code!',
            icon:'error',
            width: 600,
            padding: '3em',
            background: '#fff',
            customClass: {
              container:'swalbackgroundMargin',
              // popup:'popupcustom'
            },
            focusConfirm: false,
            confirmButtonText:'<i class="fa fa-thumbs-down"></i> Cancel',
          })
          this.createForm.controls['state'].setValue('');
          this.createForm.controls['city'].setValue('');
          this.postOfficeList=[]
        }        
      });
    }
  }
  onSelectionChange(event:any){
    this.createForm.controls['bprice'].setValue(this.CourseList.find((course)=>course.id ===event.option.value)?.price)
  }
  getCourseTitle(courseID:number):any {   
    return this.CourseList.find((course) => course.id==courseID)?.name    
  }
  getProductTitle(productID:number):any{
    return this.productList.find((product)=>product.id==productID)?.name
  }
  getPostOffice(name:string):any{
    return this.postOfficeList.find((postOffice)=>postOffice.Name == name)?.Name;
  }
  createOrderAction(formDirective:FormGroupDirective){
    this._leaseOrderConfirmationAgent.getLeastOrderConfirmationAgent().subscribe(leastOrderAgentPayload=>{
      console.log("least=",leastOrderAgentPayload)
      //let x=this.store.dispatch(new OrderAction.CreateOrder(formDirective.value)) 
    this.createForm.addControl('actionBy',new FormControl(this.loggedInUserParse.username+'-'+this.loggedInUserParse.fname+' '+this.loggedInUserParse.lname))
    this.createForm.addControl('productName',new FormControl(this.getProductTitle(this.createForm.controls['product'].value)));
    this.createForm.addControl('statusName',new FormControl('CONFIRM'));
    this.createForm.addControl('courseName',new FormControl(this.getCourseTitle(this.createForm.controls['course'].value)));    
    if(this.loggedInUserParse.category=="104"){
      this.createForm.controls['TL_ID'].setValue(this.loggedInUserParse.id);
    }if(this.loggedInUserParse.category=="108"){
      this.createForm.controls['TL_ID'].setValue(this.loggedInUserParse.id);
    }if(this.loggedInUserParse.category=="105"){
      this.createForm.controls['AG_ID'].setValue(this.loggedInUserParse.id);
      this.createForm.controls['TL_ID'].setValue(leastOrderAgentPayload);
    }
    this._orderService.CreateOrder(formDirective.value,this.activeShift).subscribe((payload)=>{
      console.log(payload)
      if(payload){
       this.dialogRef.close();
        this.orderComp.alluser();        
      }
    })
    })
    
  }
}




/**
 * @title Simple autocomplete
 * 
 */



 @Component({
  selector: 'assign-order-dialog',
  templateUrl:'assign-order-dialog.html',
  styleUrls:['orders.component.css'],
  animations:[
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [ style({ opacity: 0 }), animate(600) ]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})


export class AssignOrderDialog implements OnInit{
  public waitingImage:boolean=false;
  assignOrderForm!:FormGroup; 
  successHeader:boolean=false;
  userList!:IUser[];
  selectedOrdersList!:orders[];
  sideBar:boolean=true;
  paginationPageSize:number=6;
  defaultColDef = {
    editable:true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:100,
    sideBar:true
  };

  columnDefs = [    
    {headerName: 'Id', field: 'id',cellRendererFramework:TooltipComponent,hide:false},  
    {headerName: 'Agent', field: 'agentName',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Status', field: 'ostatus',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Name', field: 'customerName',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Course', field: 'CourseName',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'C.Price', field: 'confirmedPrice',cellRendererFramework:TooltipComponent,hide:false}
  ]
   loggedInUserParse: any;
  constructor(private _orderService:OrderService,private lbs:LodingBarServiceService, private dialog:MatDialog, private store:Store,private orderCom:OrdersComponent, @Inject(MAT_DIALOG_DATA) public data: orders[],public dialogRef: MatDialogRef<AssignOrderDialog>){
    this.assignOrderForm = new FormGroup({ 
      users: new FormControl('',[Validators.required]),
      TL_ID:new FormControl('')
    })
    console.log(this.data)
    this.selectedOrdersList=this.data;
    const loggedInUser:any=localStorage.getItem('user');
    this.loggedInUserParse=JSON.parse(loggedInUser);
  }
  ngOnInit(): void {
    console.log("calling..")
    this.store.dispatch(new userAction.LoadUsers)
    this.store.pipe(select(fromUser.getAgentUsers)).subscribe(users=>this.userList=users);    
  }
  onResize(event:any){

  }
  onSelectionChange(event:any){

  }
  getUserTitle(id:number){
    let fname = this.userList.find((user)=>user.id==id)?.fname ;
    let lname = this.userList.find((user)=>user.id==id)?.lname;
    if(!fname && !lname){
      return "Agents"
    }else{
      return (fname+' '+lname);
    }
  }
  assignOrderAction(formDirective:FormGroupDirective){
    this.lbs.showBar(true);    
    this.assignOrderForm.controls['TL_ID'].setValue(this.loggedInUserParse.id);
    this._orderService.assignOrder(this.data,formDirective.value).subscribe(payload=>{
      if(payload){
        this.dialogRef.close();
        this.lbs.showBar(false);
        this.orderCom.deselectRows();
      }
    })
  }

  

}


@Component({
  selector: 'shipping-log-dialog',
  templateUrl:'ShippingLogOrderDialog.html',
  styleUrls:['orders.component.css'],
  animations:[
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [ style({ opacity: 0 }), animate(600) ]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})

export class ShippingLogOrderDialog implements OnInit{
  public waitingImage:boolean=false;
  LogList!:any[];  
  sideBar:boolean=true;
  successHeader:boolean=false;
  paginationPageSize:number=6;
  defaultColDef = {
    editable:true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:100,
    sideBar:true
  };

  columnDefs = [    
    {headerName: 'Order Id', field: 'order_id',cellRendererFramework:TooltipComponent,hide:false},  
    {headerName: 'Awb', field: 'awb_code',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Message', field: 'message',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Status', field: 'status',cellRendererFramework:TooltipComponent,hide:false},    
  ]
  constructor(private _orderService:OrderService,private lbs:LodingBarServiceService, private dialog:MatDialog, private store:Store,private orderCom:OrdersComponent, @Inject(MAT_DIALOG_DATA) public data: any[],public dialogRef: MatDialogRef<ShippingLogOrderDialog>){
    console.log(this.data)
    this.LogList=this.data
  }
  ngOnInit(): void {
     console.log(this.LogList);
     this.LogList=this.data
  }
  onResize(event:any){

  }
  onSelectionChange(event:any){

  }
}


@Component({
  selector: 'transfer-order-dialog',
  templateUrl:'transfer-order-dialog.html',
  styleUrls:['orders.component.css'],
  animations:[
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [ style({ opacity: 0 }), animate(600) ]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})


export class TransferOrderDialog implements OnInit{
  public waitingImage:boolean=false;
  transferOrderForm!:FormGroup; 
  successHeader:boolean=false;
  userList!:IUser[];
  selectedOrdersList!:orders[];
  sideBar:boolean=true;
  paginationPageSize:number=6;
  defaultColDef = {
    editable:true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:100,
    sideBar:true
  };

  columnDefs = [    
    {headerName: 'Id', field: 'id',cellRendererFramework:TooltipComponent,hide:false},  
    {headerName: 'Agent', field: 'agentName',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Status', field: 'ostatus',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Name', field: 'customerName',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Course', field: 'CourseName',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'C.Price', field: 'confirmedPrice',cellRendererFramework:TooltipComponent,hide:false}
  ]
   loggedInUserParse: any;
  constructor(private _orderService:OrderService,private lbs:LodingBarServiceService, private dialog:MatDialog, private store:Store,private orderCom:OrdersComponent, @Inject(MAT_DIALOG_DATA) public data: orders[],public dialogRef: MatDialogRef<AssignOrderDialog>){
    this.transferOrderForm = new FormGroup({ 
      users: new FormControl('',[Validators.required]),
      TL_ID:new FormControl('')
    })
    console.log(this.data)
    this.selectedOrdersList=this.data;
    const loggedInUser:any=localStorage.getItem('user');
    this.loggedInUserParse=JSON.parse(loggedInUser);
  }
  ngOnInit(): void {
    console.log("calling..")
    this.store.dispatch(new userAction.LoadUsers)
    this.store.pipe(select(fromUser.getConfirmationUsers)).subscribe(users=>this.userList=users);    
  }
  onResize(event:any){

  }
  onSelectionChange(event:any){

  }
  getUserTitle(id:number){
    let fname = this.userList.find((user)=>user.id==id)?.fname ;
    let lname = this.userList.find((user)=>user.id==id)?.lname;
    if(!fname && !lname){
      return "Agents"
    }else{
      return (fname+' '+lname);
    }
  }
  transferOrderAction(formDirective:FormGroupDirective){
    this.lbs.showBar(true);    
    this.transferOrderForm.controls['TL_ID'].setValue(this.loggedInUserParse.id);
    this._orderService.transferOrders(this.data,formDirective.value).subscribe(payload=>{
      if(payload){
        this.dialogRef.close();
        this.lbs.showBar(false);
        this.orderCom.deselectRows();
      }
    })
  }

  

}

