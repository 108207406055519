import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './employee.reducer';

const getEmployeeFeatureState = createFeatureSelector<State>('employeeState');

export const getEmployee = createSelector(
    getEmployeeFeatureState,
    state => state.employee
)

export const getError = createSelector(
    getEmployeeFeatureState,
    state => state.error
)
export const createEmployee= createSelector(
    getEmployeeFeatureState,
    state=>state.employee
)


