import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { LodingBarServiceService } from "../loading-bar/loding-bar-service.service";
import {IUser} from "../../user";
import { select, Store } from '@ngrx/store';
import * as UserActions from '../../user.actions';
import * as fromUser from "../../user.selectors";
import * as CourseActions from '../course/course.actions';
import * as fromCourse from "../course/course.selectors";
import * as ProductActions from '../product/product.actions';
import * as fromProduct from "../product/product.selectors";
import { ReportService } from './report.service';
import { Report } from './report';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { ReportActionButtonComponent } from '../report-action-button/report-action-button.component';
import {UtilService} from '../../utilities/util.service'
import { parseLazyRoute } from '@angular/compiler/src/aot/lazy_routes';
import { ThemePalette } from '@angular/material/core';
import { ValueFormatterParams,ValueFormatterService,ValueGetterParams  } from 'ag-grid-community';
import { Course } from '../course/course';
import { CourseActionTypes } from '../course/course.actions';
import { product } from '../product/product';



@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})

export class ReportComponent {

  pageTitle:string='report'
  paginationPageSize:number=19;
  sideBar:boolean=false
  UserReportForm!:FormGroup
  userList!:IUser[];
  ReportList!:Report[]
  UserReportList:Report[]=[];
  courseList:Course[]=[]
  productList:product[]=[];
  Months:any=[];
  Years:any=[]
  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;
  userReport:boolean=false;
  monthlyReport:boolean=true
  yearlyReport:boolean=false
  courseReport:boolean=false
  productReport:boolean=false
  manualReport: boolean=false;
  constructor(private lbs:LodingBarServiceService,private store:Store,private _reportService:ReportService, private _utils:UtilService) { 
    this.Months=this._utils.months
    this.Years=this._utils.years;
    this.UserReportForm= new FormGroup({
      users:new FormControl('',Validators.required),
      month:new FormControl('',Validators.required),
      year:new FormControl('',Validators.required),
      courseMonth:new FormControl('',Validators.required),
      productMonth:new FormControl('',Validators.required)
    })
   
  }
  defaultColDef = {
    editable:true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:100,
    sideBar:true
  };
  columnDefs = [
    {headerName: 'username', field: 'username',cellRendererFramework:TooltipComponent,hide:false},    
    {headerName: 'Name', field: 'name',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Final Orders', field: 'Final',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Unread Orders', field: 'Unread',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Fake Orders', field: 'Fake',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Not Pick', field: 'Notpick',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Hold Orders', field: 'Hold',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Cancel', field: 'Cancel',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Action',cellRendererFramework:ReportActionButtonComponent}
  ]
  columnsUserDefs=[
    {headerName: 'Month', field: 'Month',hide:false,valueFormatter:this.monthFormatter.bind(this)},
    {headerName: 'Final Orders', field: 'Final',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Unread Orders', field: 'Unread',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Fake Orders', field: 'Fake',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Not Pick', field: 'Notpick',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Hold Orders', field: 'Hold',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Cancel', field: 'Cancel',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Action',cellRendererFramework:ReportActionButtonComponent}
  ]
  columnsYearDefs=[
    {headerName: 'Year', field: 'year',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'username', field: 'username',cellRendererFramework:TooltipComponent,hide:false},    
    {headerName: 'Name', field: 'name',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Final Orders', field: 'Final',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Unread Orders', field: 'Unread',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Fake Orders', field: 'Fake',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Not Pick', field: 'Notpick',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Hold Orders', field: 'Hold',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Cancel', field: 'Cancel',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Action',cellRendererFramework:ReportActionButtonComponent}
  ]
  columnsCourseDefs=[
    {headerName: 'Course', field:'courseID',valueFormatter:this.courseFormatter.bind(this),hide:false},
    {headerName: 'Final Orders', field: 'Final',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Unread Orders', field: 'Unread',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Duplicate Orders', field: 'Duplicate',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'RTS Orders', field: 'RTS',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Fake Orders', field: 'Fake',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Not Pick', field: 'Notpick',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Hold Orders', field: 'Hold',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Cancel', field: 'Cancel',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Action',cellRendererFramework:ReportActionButtonComponent}
  ]
  columnsProductDefs=[
    {headerName: 'Product', field: 'productID',valueFormatter:this.productFormatter.bind(this),hide:false},
    {headerName: 'Final Orders', field: 'Final',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Unread Orders', field: 'Unread',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Duplicate Orders', field: 'Duplicate',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'RTS Orders', field: 'RTS',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Fake Orders', field: 'Fake',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Not Pick', field: 'Notpick',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Hold Orders', field: 'Hold',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Cancel', field: 'Cancel',cellRendererFramework:TooltipComponent,hide:false},
    {headerName: 'Action',cellRendererFramework:ReportActionButtonComponent}
  ]

  onResize($event:any){}
  @ViewChild('ReportList',{read:ElementRef}) 
  ReportsList!:ElementRef
  ngOnInit(): void {
    this.lbs.showBar(true);    
    //B. PULL USERS FROM STOER
    this.store.dispatch(new UserActions.LoadUsers);
    this.store.pipe(select(fromUser.getUsers)).subscribe(payload=>this.userList=payload)
    this.store.dispatch(new CourseActions.LoadCourses);
    this.store.pipe(select(fromCourse.getCourse)).subscribe(payload=>this.courseList=payload);
    this.store.dispatch(new ProductActions.LoadProducts);
    this.store.pipe(select(fromProduct.getProduct)).subscribe(payload=>this.productList=payload);
    //E. PULL USERS FROM STOER
   this._reportService.fetchSingleMonthReport(new Date().getMonth()+1).subscribe(payload=>this.ReportList=payload);
  }
  productFormatter(params:ValueFormatterParams){
    return this.productList.find((product:any)=>product.id==params.value)?.name;
  }
  courseFormatter(params:ValueFormatterParams){
    return this.courseList.find((course:any)=>course.id==params.value)?.name;
  }
  monthFormatter(params:ValueFormatterParams ){   
    return this.Months[params.value-1].name;
  }
  clear(){
    this.lbs.clear();
  }
  disableRedirection(){
    return false;
  }
  ngAfterViewInit(){
    this.lbs.clear()
  }
  getMonthTitle(index:number):any{
    return this.Months.find((month:any)=>month.index==index)?.name
  }
  getUserTitle(event:any):any{   
    if(event){
      let fname=this.userList.find(user=>(event==user.id))?.fname
      let lname=this.userList.find(user=>(event==user.id))?.lname
      return fname+' '+lname;
    }
  }
  changeYearReport(event:any){
    this.lbs.showBar(true);
    this._reportService.fetchSingleYearReport(event.option.value).subscribe(payload=>{this.ReportList=payload;this.lbs.clear()})
  }
  changeMonthReport(event:any){
    this.lbs.showBar(true);
    this._reportService.fetchSingleMonthReport(event.option.value).subscribe(payload=>{this.ReportList=payload;this.lbs.clear()});
  }
  changeUserReport(event:any){
    let usercat = this.userList.find((el)=>(el.id==event.option.value))?.category
    let userDep = this.userList.find((el)=>(el.id==event.option.value))?.department
    this.lbs.showBar(true);
    this._reportService.fetchSingleUserReport(event.option.value,usercat,userDep).subscribe(payload=>{this.UserReportList=payload;this.lbs.clear()});
  }
  changeCourseReport(event:any){
    this.lbs.showBar(true);
    this._reportService.fetchSingleCourseReport(event.option.value).subscribe(payload=>{this.ReportList=payload;this.lbs.clear()})
  }
  changeProductReport(event:any){
    this.lbs.showBar(true);
    this._reportService.fetchSingleProductReport(event.option.value).subscribe(payload=>{this.ReportList=payload;this.lbs.clear()})
  }
  UserReportAction(formDirective:FormGroupDirective){
    
  }
  switchReport(event:any){
    if(event.target.id=='user'){
      this.userReport=true;
      this.courseReport=false
      this.monthlyReport=false;
      this.yearlyReport=false;
      this.productReport=false;
    }else if(event.target.id=='monthly'){
      this.monthlyReport=true;
      this.userReport=false;
      this.courseReport=false
      this.yearlyReport=false;
      this.productReport=false;
      this.lbs.showBar(true);
      this.UserReportForm.controls.month.setValue(new Date().getMonth()+1);
      this._reportService.fetchSingleMonthReport(new Date().getMonth() + 1).subscribe(payload=>{this.ReportList=payload;this.lbs.clear()})
    }else if(event.target.id=='course'){
      this.courseReport=true;
      this.userReport=false;
      this.monthlyReport=false;
      this.yearlyReport=false;
      this.productReport=false
      this.lbs.showBar(true);
      this.ReportList=[];//removing old data as new course ID is not present in old data to course valueFormatter is not working
      this.UserReportForm.controls.courseMonth.setValue(new Date().getMonth()+1);
      this._reportService.fetchSingleCourseReport(new Date().getMonth()+1).subscribe(payload=>{
        this.ReportList=payload;
        this.lbs.clear();
      })
    }else if(event.target.id=='product'){
      this.productReport=true;
      this.courseReport=false;
      this.userReport=false;
      this.monthlyReport=false;
      this.yearlyReport=false;
      this.ReportList=[];//removing old data as new product ID is not present in old data to product valueFormatter is not working
      this.UserReportForm.controls.productMonth.setValue(new Date().getMonth()+1);
      this._reportService.fetchSingleProductReport(new Date().getMonth()+1).subscribe(payload=>{
        this.ReportList=payload;
        this.lbs.clear();
      })
    }else if(event.target.id=='manual'){
      this.productReport=false;
      this.manualReport=true;
      this.courseReport=false;
      this.userReport=false;
      this.monthlyReport=false;
      this.yearlyReport=false;
      this.ReportList=[];//removing old data as new product ID is not present in old data to product valueFormatter is not working
      this._reportService.fetchSingleProductReport(new Date().getMonth()+1).subscribe(payload=>{
        this.ReportList=payload;
        this.lbs.clear();
      })
    }else{
      this.yearlyReport=true;
      this.userReport=false;
      this.productReport=false;
      this.monthlyReport=false;
      this.courseReport=false;
      this.lbs.showBar(true);
      this.UserReportForm.controls.year.setValue(new Date().getFullYear());
      this._reportService.fetchSingleYearReport(new Date().getFullYear()).subscribe(payload=>{this.ReportList=payload;this.lbs.clear()})
    }
  }
}
