import { Action } from '@ngrx/store';
import { Report } from './report';

export enum ReportActionTypes {
  LoadReports = '[Report] Load Reports',
  LoadReportsSuccess = '[Report] Load Reports Success',
  LoadReportsFailure = '[Report] Load Reports Failure',
}

export class LoadReports implements Action {
  readonly type = ReportActionTypes.LoadReports;
}

export class LoadReportsSuccess implements Action {
  readonly type = ReportActionTypes.LoadReportsSuccess;
  constructor(public payload: { data: Report[] }) { }
}

export class LoadReportsFailure implements Action {
  readonly type = ReportActionTypes.LoadReportsFailure;
  constructor(public payload: { error: string }) { }
}

export type ReportActions = LoadReports | LoadReportsSuccess | LoadReportsFailure;

