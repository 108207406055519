import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store,select } from '@ngrx/store';
import { AgGridAngular } from 'ag-grid-angular';
import { EmployeeActionBUttonsComponent } from '../employee-action-buttons/employee-action-buttons.component';
import { LodingBarServiceService } from '../loading-bar/loding-bar-service.service';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { UserService } from '../users/user.service';
import { employee } from "./employee";
import * as EmployeeActions from "./employee.actions";
import * as fromEmployee from "./employee.selectors";


interface columnList {
  position: number;
  name:any,
  isActive:boolean;
};

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {

  constructor(private _userService:UserService,private lbs: LodingBarServiceService,private store:Store) { }

  pageTtitle:string='Employees';
  rowData!:employee[];
  ShowHideColumnList: columnList[] = [];
  paginationPageSize:number=19;
  sideBar:boolean = false;
  employeeFilter:any;
  defaultColDef = {
    editable:false,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth:90,
    sideBar:true
  };
  stringFormatter(params:any){
    //console.log(params,"params");
    var temp=params.value;
    var tempChar=temp.slice(0,1).toUpperCase();
    //console.log(tempChar+temp.slice(1),"df");
    return tempChar + temp.slice(1);
  }
  filterParams={
    comparator:(filterLocalDateAtMidnight:any,cellValue:any)=>{
      //console.log(filterLocalDateAtMidnight,cellValue);
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split('-');
      console.log(dateParts,"HJ");
      var cellDate = new Date(
        Number(dateParts[0]),
        Number(dateParts[1])-1, 
        Number(dateParts[2])        
      )
      //console.log(cellDate,"DF",Number(dateParts[1])-1,Number(dateParts[2]),Number(dateParts[0]), new Date(2021,6,15));
  
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
  
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
  
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0
    },
    browserDatePicker: true,
  }
  columnDefs = [
    {headerName: 'Id', field: 'id',hide:false,cellRendererFramework:TooltipComponent,maxWidth:50,getQuickFilterText:(params:any)=>{
      return params.value.name;
      }
    },
    {headerName: 'First Name', field: 'fname',hide:false,cellRendererFramework:TooltipComponent},
    {headerName: 'Last Name', field: 'lname',hide:false,cellRendererFramework:TooltipComponent
      // ,filter:'agSetColumnFilter',
      // valueFormatter:(params:any)=>{
      //   return "test"+ params.value;
      // },
      
    },
    {headerName: 'Father Name', field: 'fathername',hide:false,cellRendererFramework:TooltipComponent},
    {headerName: 'Category', field: 'category',hide:false,cellRendererFramework:TooltipComponent},
    // {headerName: 'Department', field: 'department',hide:false,cellRendererFramework:TooltipComponent},
    {headerName: 'Phone', field: 'mobile',hide:false,cellRendererFramework:TooltipComponent},
    {headerName: 'Created@', field: 'dateofcreation',hide:true,cellRendererFramework:TooltipComponent,filter: 'agDateColumnFilter',filterParams:this.filterParams},
    {headerName: 'Joined@', field: 'joiningdate',hide:false,cellRendererFramework:TooltipComponent,filter: 'agDateColumnFilter',filterParams:this.filterParams},
    {headerName: 'Updated@', field: 'updatedate',hide:true,cellRendererFramework:TooltipComponent,filter:'agDateColumnFilter',filterParams:this.filterParams},
    {headerName: 'Id Proof', field: 'idproofdocument',hide:false,cellRendererFramework:TooltipComponent},
    {headerName: 'proof Details', field: 'idproofdetails',hide:true,cellRendererFramework:TooltipComponent},
    {headerName: 'Action',cellRendererFramework:EmployeeActionBUttonsComponent}
  ]

  ngOnInit(): void {
    this.lbs.showBar(true);
    this.allEmp(); 
    this.columnDefs.forEach((clm,index)=>{
      if(clm.field !='dateofcreation' && clm.field !='updatedate' && clm.field !='idproofdetails'){
        this.ShowHideColumnList.push({ position:index, name: clm.field?.toLocaleLowerCase(), isActive: true });
      }else{
        this.ShowHideColumnList.push({ position:index, name: clm.field?.toLocaleLowerCase(), isActive: false });
      }
        
    })
   
  }
  @ViewChild('employeeList')
  agGrid!:AgGridAngular

  @ViewChild('filterTextBox',{read:ElementRef})
  filterBox!:ElementRef

  toggleColumn(column:columnList){
    if(column.isActive == false){
      this.agGrid.columnApi.setColumnVisible(column.name,false);  
    }else{      
      this.agGrid.columnApi.setColumnVisible(column.name,true);     
    }
  }

  refreshEmpList(){
    this.allEmp();
  }  
  onResize(event:any){
    console.log("onresize:",event)
  }

  private allEmp(){
    this.store.dispatch(new EmployeeActions.LoadEmployees) //Action dispatch
    this.store.pipe(select(fromEmployee.getEmployee)).subscribe(
      employee =>{
        this.rowData=employee.filter(emp=>emp.isDeleted == false)
        this.lbs.clear();
      }
    )    
  } 
  
  onFilterTextBoxChanged(){
    //console.log("df",this.filterBox.nativeElement.value);
    this.agGrid.api.setQuickFilter(this.filterBox.nativeElement.value)
  }
}


