<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-1">
                <div class="col-sm-6">
                    <h2 class="m-0">{{pageTtitle | uppercase}}</h2>
                </div>
                <!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right buttonList">
                        <!-- <li class="" (click)="checkTestPhp()"><button mat-mini-fab color="accent"><i class="fas fa-filter"></i></button></li>               -->
                        <li class="" *appHideForRoles="['55']" (click)="TransferOrders($event)" #tooltip="matTooltip" matTooltip="TRANSFER ORDERS"><button mat-mini-fab color="accent"><i class="fas fa-share-square" aria-hidden="true"></i></button></li>
                        <li class="" *appHideForRoles="['15']" (click)="NotPickOrder($event)" #tooltip="matTooltip" matTooltip="NOTPICK ORDERS"><button mat-mini-fab color="accent">NP</button></li>
                        <li class="" *appHideForRoles="['14']" (click)="HoldOrder($event)" #tooltip="matTooltip" matTooltip="HOLD ORDERS"><button mat-mini-fab color="accent">HLD</button></li>
                        <li class="" *appHideForRoles="['25']" (click)="shipSelectedOrders($event)" #tooltip="matTooltip" matTooltip="COMPLETE ORDERS"><button mat-mini-fab color="accent"><i class="fa fa-truck"></i></button></li>
                        <li class="" *appHideForRoles="['28']" (click)="assignOrders()" #tooltip="matTooltip" matTooltip="ASSIGN ORDERS"><button mat-mini-fab color="accent"><i class="fas fa-spin fa-exchange-alt"></i></button></li>
                        <li class="" (click)="CreateOrder()" #tooltip="matTooltip" matTooltip="NEW ORDER" *appHideForRoles="['8']"><button mat-mini-fab color="accent"><i class="fas fa-plus" ></i></button></li>
                        <li class="" *appHideForRoles="['56']" (click)="LoadOrdersAWB()"><button mat-mini-fab color="accent"><i class="fas fa-globe-asia" ></i></button></li>
                        <li class="" (click)="refresh()" #tooltip="matTooltip" matTooltip="REFRESH ORDERS"><button mat-mini-fab color="accent" type="reset"> <i class="fas fa-spin fa-sync-alt" aria-hidden="true"></i></button></li>
                        <li class="" *appHideForRoles="['54']" (click)="showFilter()" #tooltip="matTooltip" matTooltip="FILTERS"><button mat-mini-fab color="accent"><i class="fas fa-filter"></i></button></li>
                    </ol>
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content" id="renderDataSection">
        <div class="container-fluid">
            <!-- Info boxes -->
            <div class="row">
                <div class="filterSection" *ngIf='filterRowShow' [@widthGrow]="state" style='height: 0;'>
                    <div class="ag-header-container eachFilter" style='text-align: center;'>
                        <input type="text" #filterTextBox placeholder="Type Something..." (input)='onFilterTextBoxChanged()' id='typeSomethingFilter' />
                    </div>
                    <!-- /.col -->
                    <div class="ag-header-container eachFilter" style='text-align: center;'>
                        <select id="domain" (change)="fetchOrderAsPerStatus($event)" name="domain">
                            <option value="" selected="selected" class='blankOption'>Select Domain</option>
                            <option *ngFor="let domain of DomainList index by id" [value]="domain.id">{{domain.domain}}</option>                
                        </select>
                    </div>
                    <div class="ag-header-container eachFilter" style='text-align: center;'>
                        <select id="category" (change)="fetchOrderAsPerStatus($event)" name="orderCategory">
                            <option value="" selected="selected" class='blankOption'>Select Category</option>
                            <option value="MOBILE">MOBILE</option>
                            <option value="ONLINE">ONLINE</option>                                
                        </select>
                    </div>
                    <div class="ag-header-container eachFilter" style='text-align: center;'>
                        <select (change)="fetchOrderAsPerStatus($event)" id="status" name="orderStatus">
                            <option value="" selected="selected" class='blankOption'>Select Status</option>
                            <option *ngFor="let status of OrderStatus index by id" [value]='status.id'>{{status.name}}</option>                
                        </select>
                    </div>
                    <div class="ag-header-container eachFilter" style='text-align: center;'>
                        <select id="course2" (change)="fetchOrderAsPerStatus($event)" name="courseID">
                            <option value="" selected="selected" class='blankOption'>Select Course</option>
                            <option *ngFor="let course1 of CourseList index by id" [value]="course1.id">{{course1.name}}</option>                
                        </select>
                    </div>
                    <div class="ag-header-container eachFilter" style='text-align: center;'>
                        <select id="agent" (change)="fetchOrderAsPerStatus($event)" name="AG_ID">
                            <option value="" selected="selected" class='blankOption'>Select Agent</option>
                            <option *ngFor="let agent of AgentList index by id" [value]="agent.id">{{agent.username+' - '+agent.fname+' '+agent.lname }}</option>                
                        </select>
                    </div>
                    <div class="ag-header-container eachFilter" style='text-align: center;'>
                        <select id="product" (change)="fetchOrderAsPerStatus($event)" name="productID">
                            <option value="" selected="selected" class='blankOption'>Select Product</option>
                            <option *ngFor="let product of productList index by id" [value]="product.id">{{product.name}}</option>                
                        </select>
                    </div>
                    <div class="ag-header-container eachFilter" style='text-align: center;'>
                        <select id="courierName" name="courierName">
                            <option value="" selected="selected" class='blankOption'>Select Courier</option>
                            <option *ngFor="let courier of courierList" [value]='courier.name'>{{courier.name}}</option>                
                        </select>
                    </div>
                    <div class="ag-header-container eachFilter" style='text-align: center;'>
                        <input type="datetime-local" (change)="fetchOrderAsPerStatus($event)" id="startDate" name="startDate" class='typeSomethingFilter'>
                    </div>
                    <div class="ag-header-container eachFilter" style='text-align: center;'>
                        <input type="datetime-local" (change)="fetchOrderAsPerStatus($event)" id="endDate" name="endDate" class='typeSomethingFilter'>
                    </div>
                    <div class="ag-header-container eachFilter" style='text-align: center;'>
                        <button mat-raised-button color="accent" id="submitDate" class='dateRangeSubmit' (click)='fetchDateRangeOrder()'><i class="fas fa-sign-out-alt"> GENERATE</i></button>
                        <!-- <button mat-mini-fab color="accent" id="submitDate" class='typeSomethingFilter dateRangeSubmit'  (click)='fetchDateRangeOrder()'><i class="fa fa-sign-in"></i></button> -->
                    </div>
                </div>
                <div class="rowEventStyle">
                    <div>
                        <span>Selected Rows:{{selectedRowsCount}}</span>
                    </div>
                    <div class="rowEventStyleRightBox">
                        <button mat-stroked-button color="accent" (click)="deselectRows()"><i class="fa fa-times" aria-hidden="true"></i> Deselect Rows</button>
                        <button mat-stroked-button color="accent" (click)="clearFilter($event)"><i class="fa fa-times" aria-hidden="true"></i> Clear Filter</button>
                    </div>
                </div>
                <ag-grid-angular #OrderList id='OrderList' style="width: 1470px; height: 568px;overflow: scroll;" class="ag-theme-balham" [sideBar]="sideBar" [rowData]="rowData" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [animateRows]='false' [pagination]='true'
                    [paginationPageSize]="paginationPageSize" (onColumnResized)="onResize($event)" (rowSelected)="onRowSelected($event)" [rowSelection]="rowSelection" [rowMultiSelectWithClick]="true" [rowHeight]="rowHeight">
                </ag-grid-angular>
                <!-- <ul>
            <li *ngFor="let item of rowData | paginate: { itemsPerPage: 5, currentPage: p }">  </li>
          </ul>
      
          <pagination-controls (pageChange)="getPage(p = $event)"></pagination-controls> -->

                <!-- /.col -->
            </div>
            <!-- /.row -->


            <!-- /.row -->

            <!-- Main row -->

            <!-- /.row -->
        </div>
        <!--/. container-fluid -->
    </section>
    <!-- /.content -->
</div>
<style>
    .mat-stroked-button {
        line-height: 21px;
    }
</style>
<div class="popupInterstitial" *ngIf='popupInterstitialTest'>
    <img style='margin: 0 auto;
    width: 2%;
    top: 50%;
    /* align-items: center; */
    position: relative;
    display: block;' src="../../../assets/img/loader.gif">
    <p style="   color: #fff;
    margin: 0 auto;
    width: 15%;
    top: 50%;
    /* align-items: center; */
    position: relative;
    display: block;
">Don't close untill popup appear..</p>
</div>