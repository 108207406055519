import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ReportService } from './report.service';
import * as ReportAction from './report.actions'
import { ReportActionTypes } from './report.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Report } from "./report";
import { of } from 'rxjs';



@Injectable()
export class ReportEffects {
    constructor(private actions$: Actions,private _reportServices:ReportService){}
       loadReport=createEffect(()=> {
        return this.actions$.pipe(
        ofType(ReportAction.ReportActionTypes.LoadReports),
        mergeMap(
          action => this._reportServices.fetchReport().pipe(
            map((report) => new ReportAction.LoadReportsSuccess({data:report})),
            catchError(err=> of(new ReportAction.LoadReportsFailure({error:err})))
          )
        )
      )
    })
}
