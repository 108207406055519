import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from "@ngrx/store";
import { Observable, of } from 'rxjs';
import { UserService } from "./dashboard/users/user.service";
import * as userActions from "./user.actions";
import { mergeMap,map,catchError } from "rxjs/operators";
import { IUser } from "./user";

@Injectable()
export class UserEffects {

  constructor(private actions$: Actions,private _userServices:UserService) {}
  loadUser=createEffect(()=> {
   return this.actions$.pipe(
    ofType(userActions.UserActionTypes.LoadUsers),
    mergeMap(
      action => this._userServices.fetchUsers().pipe(
        map((users) => new userActions.LoadUsersSuccess({data:users })),
        catchError(err=> of(new userActions.LoadUsersFailure({error:err})))
      )
    )
  )
})
}


