import { Action } from '@ngrx/store';
import { OrderStatus } from './orderStatus';
import { OrderStatusActions,OrderStatusActionTypes } from "../order-status/order-status.actions";


export const orderStatusFeatureKey = 'orderStatus';
export const postReducers='reducer';

export interface State {
  orderStatus:OrderStatus[],
  error:string
}

export const initialState: State = {
  orderStatus:[],
  error:""
};

export function reducer(state = initialState, action: OrderStatusActions): State {
  switch (action.type) {
    case OrderStatusActionTypes.LoadOrderStatuss:
      return{
        ...state
      }
    case OrderStatusActionTypes.LoadOrderStatussSuccess:
      return {
        ...state,
        orderStatus:action.payload.data,
        error:""
      }
    case OrderStatusActionTypes.LoadOrderStatussFailure:
      return {
        ...state,
        error:action.payload.error
      }
    default:
      return state;
  }
}
